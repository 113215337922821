import { useNavigate } from 'react-router-dom';
import { AkButton, AkCard, AkCardHeader, AkLoading, AkNumberplate, AkStatus, ButtonType, ProvekjennemerkeStatus } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { MidlertidigBrukstillatelseInfoConnected } from '../../components';
import { AkConfirmationBox } from '../../components/ak-confirmation-box/ak-confirmation-box';
import type { IArsprovekjennemerke, IMidlertidigBrukstillatelseMapEntry, RootStateType } from '../../models/types';
import { stoppMidlertidigBrukstillatelseOgHentArsprovekjennemerker } from '../../state/actions';
import { finnesFremtidigeBrukstillatelserSelector } from '../../state/selectors';
import { Kjoreseddeltabell } from './arsprovekjennemerke-kjoresedler';
import { ArsprovekjennemerkeTypeClassNames } from './arsprovekjennemerker';
import type store from "../../state/store";
import { useAppSelector, useAppDispatch } from "../../state/store";
type AppDispatch = typeof store.dispatch;

interface IArsprovekjennemerkeProps {
    arsprovekjennemerke: IArsprovekjennemerke;
}

const Arsprovekjennemerke: React.FC<IArsprovekjennemerkeProps> = props => {

    const navigate = useNavigate();
    const dispatch: (param: any) => AppDispatch = useAppDispatch();

    const mbtEntry: IMidlertidigBrukstillatelseMapEntry = useAppSelector((state: RootStateType) =>
        state.midlertidigebrukstillatelser[props.arsprovekjennemerke.prkid]);

    const eksistererFremtidigeBrukstillatelser = useAppSelector(finnesFremtidigeBrukstillatelserSelector(props.arsprovekjennemerke.prkid));

    const [showConfirmationBox, setShowConfirmationBox] = React.useState<boolean>(false);

    const view = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        navigate(`/kjoreseddel/${event.currentTarget.value}`);
    };

    const stop = () => {
        setShowConfirmationBox(true);
    };

    const goToKjoreseddel = (event?: React.SyntheticEvent<HTMLButtonElement>) => {
        navigate(`/arsprovekjennemerker/${event.currentTarget.value}/kjoretoysok`);
    };

    const closeConfirmationBox = () => {
        setShowConfirmationBox(false);
    };

    const stoppMidlertidigBrukstillatelse = () => {
        dispatch(stoppMidlertidigBrukstillatelseOgHentArsprovekjennemerker(Number(mbtEntry?.innvarendeMbt.id)));
        setShowConfirmationBox(false);
    };

    const renderButtonRow = () => {

        if (mbtEntry?.isLoading) {
            return <AkLoading extraClassName="ak-spinner-small" />;
        }

        const prkid = props.arsprovekjennemerke.prkid;
        const mbt = mbtEntry?.innvarendeMbt;

        if (mbt) {
            return (
                <div className="ak-button-row">
                    <AkButton buttonIntlKey="arsprovekjennemerker.button.view" buttonType={ButtonType.MAIN} value={mbt.id} action={view} />
                    <AkButton buttonIntlKey="arsprovekjennemerker.button.stop" buttonType={ButtonType.CANCEL} value={mbt.id} action={stop} disabled={showConfirmationBox} />
                    <AkButton buttonIntlKey="arsprovekjennemerker.button.go-to-kjoreseddel-short" buttonType={ButtonType.SECONDARY} value={prkid} action={goToKjoreseddel} />
                </div>
            );
        }

        return <AkButton buttonIntlKey="arsprovekjennemerker.button.go-to-kjoreseddel" buttonType={ButtonType.MAIN} value={prkid} action={goToKjoreseddel} />;
    };

    const renderConfirmationBox = (midlertidigBrukstillatelseId: number) => {
        return (
            <div className="row mt-3">
                <AkConfirmationBox message="arsprovekjennemerker.confirmation-box.meldingstekst"
                    confirmButtonAction={stoppMidlertidigBrukstillatelse}
                    confirmButtonValue={midlertidigBrukstillatelseId}
                    confirmButtonIntlKey="arsprovekjennemerker.confirmation-box.stopp"
                    cancelButtonAction={closeConfirmationBox}
                    cancelButtonIntlKey="arsprovekjennemerker.confirmation-box.avbryt" />
            </div>);
    };

    return (
        <>
            <AkCard key={String(props.arsprovekjennemerke.prkid)} customClassNames="col-12 col-lg-4 mb-3 text-center arsprovekjennemerke-card">
                <div className="ak-kjoreseddel-content">
                    <AkCardHeader customClassNames="row justify-content-between align-items-center">
                        <p>
                            <i className={`ak-icon ${ArsprovekjennemerkeTypeClassNames[props.arsprovekjennemerke.arsprovekjennemerketype]}`} />
                            <FormattedMessage id={`arsprovekjennemerker.arsprovekjennemerketype.${props.arsprovekjennemerke.arsprovekjennemerketype}`} />
                        </p>
                        <AkStatus isLoading={mbtEntry?.isLoading}
                            status={(!!mbtEntry?.innvarendeMbt)
                                    ? ProvekjennemerkeStatus.OPPTATT
                                    : ProvekjennemerkeStatus.LEDIG} />
                    </AkCardHeader>
                    <AkNumberplate arsprovekjennemerketype={props.arsprovekjennemerke.arsprovekjennemerketype} kjennemerketype={props.arsprovekjennemerke.provekjennemerketype}
                        tegnkombinasjon={props.arsprovekjennemerke.kjennemerker[0].tegnkombinasjon.visning || ''} />
                    <MidlertidigBrukstillatelseInfoConnected prkid={props.arsprovekjennemerke.prkid} />
                    {renderButtonRow()}
                    {showConfirmationBox && renderConfirmationBox(mbtEntry.innvarendeMbt.id)}
                </div>
                {eksistererFremtidigeBrukstillatelser && <Kjoreseddeltabell prkid={props.arsprovekjennemerke.prkid} />}
            </AkCard>
        </>
    );
};

export { Arsprovekjennemerke };