import * as _ from 'lodash';

import { getArsprovekjennemerker } from '../';
import { MidlertidigBrukstillatelseActionKey, StoppMidlertidigBrukstillatelseActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import { MeldingType } from '../../../models/kodeverk/melding-bar-enums';
import type { IError, IMidlertidigBrukstillatelse, IResourceResponseStatus } from '../../../models/types';
import type { IMelding } from '../../../models/types/melding-bar';
import { createRestResource } from '../../store';
import { nyMelding } from '../globals/melding-bar';

export type IMidlertidigBrukstillatelseAction =
    IGetMidlertidigBrukstillatelserSuccessAction
    | IGetMidlertidigBrukstillatelserIsLoadingAction
    | IGetMidlertidigBrukstillatelserErrorAction
    | IFetchMidlertidigBrukstillatelseSuccessAction
    | IFetchMidlertidigBrukstillatelseIsLoadingAction
    | IFetchMidlertidigBrukstillatelseErrorAction
    | IPostMidlertidigBrukstillatelseSuccessAction
    | IPostMidlertidigBrukstillatelseIsLoadingAction
    | IPostMidlertidigBrukstillatelseErrorAction
    | ISendMidlertidigBrukstillatelseSuccessAction
    | ISendMidlertidigBrukstillatelseErrorAction
    | ISendMidlertidigBrukstillatelseIsLoadingAction
    | IPrintMidlertidigBrukstillatelseErrorAction
    | IPrintMidlertidigBrukstillatelseIsLoadingAction
    | IPrintMidlertidigBrukstillatelseIsSuccessAction
    | IKansellerMidlertidigBrukstillatelseErrorAction
    | IKansellerMidlertidigBrukstillatelseIsLoadingAction
    | IKansellerMidlertidigBrukstillatelseSuccessAction;

export type IStoppMidlertidigBrukstillatelseAction =
    IStoppMidlertidigBrukstillatelseSuccessAction
    | IStoppMidlertidigBrukstillatelseIsLoadingAction
    | IStoppMidlertidigBrukstillatelseErrorAction;

interface IGetMidlertidigBrukstillatelserSuccessAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_SUCCESS;
    key: number;
    midlertidigebrukstillatelser: IMidlertidigBrukstillatelse[];
}

interface IGetMidlertidigBrukstillatelserIsLoadingAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING;
    key: number;
    isLoading: boolean;
}

interface IGetMidlertidigBrukstillatelserErrorAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_ERROR;
    key: number;
    error: IError;
}

interface IStoppMidlertidigBrukstillatelseSuccessAction {
    type: StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS;
    midlertidigbrukstillatelse: IMidlertidigBrukstillatelse;
}

interface IStoppMidlertidigBrukstillatelseIsLoadingAction {
    type: StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING;
    isLoading: boolean;
}

interface IStoppMidlertidigBrukstillatelseErrorAction {
    type: StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_ERROR;
    error: IError;
}

interface IKansellerMidlertidigBrukstillatelseSuccessAction {
    type: MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS;
    isKansellert: boolean;
}

interface IKansellerMidlertidigBrukstillatelseIsLoadingAction {
    type: MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING;
    isLoading: boolean;
}

interface IKansellerMidlertidigBrukstillatelseErrorAction {
    type: MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_ERROR;
    error: IError;
}

interface IFetchMidlertidigBrukstillatelseIsLoadingAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_IS_LOADING;
    isLoading: boolean;
}

export interface IFetchMidlertidigBrukstillatelseSuccessAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_SUCCESS;
    midlertidigbrukstillatelse: IMidlertidigBrukstillatelse;
}

interface IFetchMidlertidigBrukstillatelseErrorAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_ERROR;
    error: IError;
}

const stoppMidlertidigBrukstillatelseSuccessAction = (midlertidigbrukstillatelse: IMidlertidigBrukstillatelse): IStoppMidlertidigBrukstillatelseSuccessAction => ({
    type: StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS,
    midlertidigbrukstillatelse
});

const stoppMidlertidigBrukstillatelseErrorAction = (error: IError): IStoppMidlertidigBrukstillatelseErrorAction => ({
    type: StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_ERROR,
    error
});

const stoppMidlertidigBrukstillatelseIsLoadingAction = (isLoading: boolean): IStoppMidlertidigBrukstillatelseIsLoadingAction => ({
    type: StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING,
    isLoading
});

const kansellerMidlertidigBrukstillatelseSuccessAction = (isKansellert: boolean): IKansellerMidlertidigBrukstillatelseSuccessAction => ({
    type: MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS,
    isKansellert
});

const kansellerMidlertidigBrukstillatelseErrorAction = (error: IError): IKansellerMidlertidigBrukstillatelseErrorAction => ({
    type: MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_ERROR,
    error
});

const kansellerMidlertidigBrukstillatelseIsLoadingAction = (isLoading: boolean): IKansellerMidlertidigBrukstillatelseIsLoadingAction => ({
    type: MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING,
    isLoading
});

interface IPostMidlertidigBrukstillatelseIsLoadingAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_IS_LOADING;
    isLoading: boolean;
}

interface IPostMidlertidigBrukstillatelseErrorAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_ERROR;
    response: IResourceResponseStatus;
}

interface IPostMidlertidigBrukstillatelseSuccessAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_SUCCESS;
    midlertidigBrukstillatelse: IMidlertidigBrukstillatelse;
}

interface ISendMidlertidigBrukstillatelseIsLoadingAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_IS_LOADING;
    isLoading: boolean;
}

interface ISendMidlertidigBrukstillatelseErrorAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_ERROR;
    error: IError;
}

interface ISendMidlertidigBrukstillatelseSuccessAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_SUCCESS;
}

interface IPrintMidlertidigBrukstillatelseIsLoadingAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_PRINT_IS_LOADING;
    isLoading;
}

interface IPrintMidlertidigBrukstillatelseErrorAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_PRINT_ERROR;
    error: IError;
}

interface IPrintMidlertidigBrukstillatelseIsSuccessAction {
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_PRINT_SUCCESS;
    byteArray: string;
}

const getMidlertidigBrukstillatelserSuccessAction = (midlertidigebrukstillatelser: IMidlertidigBrukstillatelse[], key: number): IGetMidlertidigBrukstillatelserSuccessAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_SUCCESS,
    midlertidigebrukstillatelser,
    key
});

const getMidlertidigBrukstillatelserIsLoadingAction = (isLoading: boolean, key: number): IGetMidlertidigBrukstillatelserIsLoadingAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING,
    isLoading,
    key
});

const getMidlertidigBrukstillatelserErrorAction = (error: IError, key: number): IGetMidlertidigBrukstillatelserErrorAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_ERROR,
    error,
    key
});

const fetchMidlertidigbrukstillatelseIsLoadingAction = (isLoading: boolean): IFetchMidlertidigBrukstillatelseIsLoadingAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_IS_LOADING,
    isLoading
});

const fetchMidlertidigBrukstillatelseSuccessAction = (midlertidigbrukstillatelse: IMidlertidigBrukstillatelse): IFetchMidlertidigBrukstillatelseSuccessAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_SUCCESS,
    midlertidigbrukstillatelse
});

const fetchMidlertidigBrukstillatelseErrorAction = (error: IError): IFetchMidlertidigBrukstillatelseErrorAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_ERROR,
    error
});

const newMidlertidigBrukstillatelseIsLoadingAction = (isLoading: boolean): IPostMidlertidigBrukstillatelseIsLoadingAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_IS_LOADING,
    isLoading
});

const newMidlerditigBrukstillatelseErrorAction = (response: IResourceResponseStatus): IPostMidlertidigBrukstillatelseErrorAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_ERROR,
    response
});

const newMidlertidigBrukstillatelseSuccessAction = (midlertidigBrukstillatelse: IMidlertidigBrukstillatelse): IPostMidlertidigBrukstillatelseSuccessAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_SUCCESS,
    midlertidigBrukstillatelse
});

const sendMidlertidigBrukstillatelseIsLoadingAction = (isLoading: boolean): ISendMidlertidigBrukstillatelseIsLoadingAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_IS_LOADING,
    isLoading
});

const sendMidlertidigBrukstillatelseErrorAction = (error: IError): ISendMidlertidigBrukstillatelseErrorAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_ERROR,
    error
});

const sendMidlertidigBrukstillatelseSuccesAction = (): ISendMidlertidigBrukstillatelseSuccessAction => ({
    type: MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_SUCCESS
});

const midlertidigBrukstillatelseApi = createRestResource(AkrConfig.MIDLERTIDIG_BRUKSTILLATELSER_RESOURCE_URL, PREFIX);

export function getMidlertidigeBrukstillatelser(prkid: number, historikk?: boolean) {
    return dispatch => {
        dispatch(getMidlertidigBrukstillatelserIsLoadingAction(true, prkid));
        return midlertidigBrukstillatelseApi.get(null, { prkid, historikk }).then(response => {
            if (!!response.errorId) {
                return dispatch(getMidlertidigBrukstillatelserErrorAction(response, prkid));
            }
            dispatch(getMidlertidigBrukstillatelserSuccessAction(response.content, prkid));
        });
    };
}

export function fetchMidlertidigBrukstillatelse(midlertidigbrukstillatelseId: string) {
    return dispatch => {
        dispatch(fetchMidlertidigbrukstillatelseIsLoadingAction(true));
        return midlertidigBrukstillatelseApi.get(midlertidigbrukstillatelseId).then(response => {

            if (!!response.errorId) {
                return dispatch(fetchMidlertidigBrukstillatelseErrorAction(response));
            }
            dispatch(fetchMidlertidigBrukstillatelseSuccessAction(response));
        });
    };
}

function stoppMidlertidigBrukstillatelse(midlertidigbrukstillatelseId: number, dispatch, tilTidspunkt?: string) {
    return new Promise<void>((resolve, reject) => {
            dispatch(stoppMidlertidigBrukstillatelseIsLoadingAction(true));
            midlertidigBrukstillatelseApi.put(tilTidspunkt, midlertidigbrukstillatelseId)
                .then((response: IMidlertidigBrukstillatelse & IError) => {
                    if (response.error) {
                        dispatch(stoppMidlertidigBrukstillatelseErrorAction(response));
                        reject();
                    }
                    dispatch(stoppMidlertidigBrukstillatelseSuccessAction(response));
                    resolve();
                });
        }
    );
}

export function stoppEksisterendeOgOpprettNyMidlertidigBrukstillatelse(eksisterendeMbtId: number, newMbt: IMidlertidigBrukstillatelse, isEtterregistreringsContext: boolean) {
    if (isEtterregistreringsContext) {
        const nyttTiltidspunkt = newMbt.periode.fomTidspunkt;
        return dispatch => (
            stoppMidlertidigBrukstillatelse(eksisterendeMbtId, dispatch, nyttTiltidspunkt).then(() => {
                dispatch(newMidlertidigbrukstillatelse(newMbt, isEtterregistreringsContext));
            })
        );
    } else {
        return dispatch => (
            stoppMidlertidigBrukstillatelse(eksisterendeMbtId, dispatch).then(() => {
                dispatch(newMidlertidigbrukstillatelse(newMbt, isEtterregistreringsContext));
            })
        );
    }
}

export function kansellerMidlertidigBrukstillatelse(midlertidigbrukstillatelseId: number) {
    return dispatch => {
        return new Promise(() => {
                dispatch(kansellerMidlertidigBrukstillatelseIsLoadingAction(true));
                midlertidigBrukstillatelseApi.put(null, `${midlertidigbrukstillatelseId}/kanseller`)
                    .then((response: IMidlertidigBrukstillatelse & IError) => {
                        if (response.error) {
                            dispatch(kansellerMidlertidigBrukstillatelseErrorAction(response));
                            return;
                        }
                        dispatch(kansellerMidlertidigBrukstillatelseSuccessAction(true));
                    });
            }
        );
    };
}

export function stoppMidlertidigBrukstillatelseOgHentArsprovekjennemerker(midlertidigbrukstillatelseId: number) {
    return dispatch => {
        stoppMidlertidigBrukstillatelse(midlertidigbrukstillatelseId, dispatch)
            .then(() => dispatch(getArsprovekjennemerker()));
    };
}

export function stoppMidlertidigBrukstillatelseOgHentOppdatertMidlertidigBrukstillatelse(midlertidigbrukstillatelseId: number) {
    return dispatch => {
        stoppMidlertidigBrukstillatelse(midlertidigbrukstillatelseId, dispatch)
            .then(() => dispatch(fetchMidlertidigBrukstillatelse(midlertidigbrukstillatelseId.toString())));
    };
}

export function newMidlertidigbrukstillatelse(midlertidigbrukstillatelse: IMidlertidigBrukstillatelse, isEtterregistreringContext: boolean) {
    const request = utledMidlertidigbrukstillatelseRequest(midlertidigbrukstillatelse, isEtterregistreringContext);

    return dispatch => {
        dispatch(newMidlertidigBrukstillatelseIsLoadingAction(true));
        return midlertidigBrukstillatelseApi.post(request).then(response => {
            if (!!response.errorId || !_.isEmpty(response.merknader)) {
                dispatch(newMidlerditigBrukstillatelseErrorAction(response));
                return dispatch(nyMelding({ meldingIntlId: 'kjoreseddel.meldinger.new-mbt.error', meldingType: MeldingType.ERROR }));
            }

            dispatch(newMidlertidigBrukstillatelseSuccessAction(response));
            return dispatch(nyMelding({ meldingIntlId: 'kjoreseddel.meldinger.new-mbt.success', meldingType: MeldingType.SUCCESS }));
        });
    };
}

function utledMidlertidigbrukstillatelseRequest(mbt: IMidlertidigBrukstillatelse, isEtterregistreringContext: boolean): IMidlertidigBrukstillatelse {
    return {
        ...mbt,
        etterregistrering: isEtterregistreringContext
    };
}

export function sendMidlertidigbrukstillatelse(midlertidigbrukstillatelseId: number, telefonnummer: string) {

    const sendingsinformasjon = {
        midlertidigBrukstillatelseId: midlertidigbrukstillatelseId.toString(),
        telefonnummer: telefonnummer,
        varslingstype: 'ARSPROVEKJENNEMERKE_KJORESEDDEL',
        meldingType: 'ARSPROVEKJORESEDDEL_SMS'
    };

    return dispatch => {
        dispatch(sendMidlertidigBrukstillatelseIsLoadingAction(true));
        return midlertidigBrukstillatelseApi.post(sendingsinformasjon, `${midlertidigbrukstillatelseId}/send`).then(response => {
            if (!!response.errorId) {
                dispatch(nyMelding({ meldingIntlId: 'kjoreseddel.readonly.error-melding', meldingType: MeldingType.ERROR }));
                return dispatch(sendMidlertidigBrukstillatelseErrorAction(response));
            }
            dispatch(nyMelding({ meldingIntlId: 'kjoreseddel.readonly.success-melding', meldingType: MeldingType.SUCCESS, meldingArgs: { mobilnummer: telefonnummer } } as IMelding));
            dispatch(sendMidlertidigBrukstillatelseSuccesAction());
        });
    };
}