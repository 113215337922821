import { KjoretoyActionKey } from '../../../constants';
import type { IFremmedKjoretoy, IKjoretoy } from '../../../models/types';
import type { IKjoretoyAction, IKjoretoylisteAction } from '../../actions';

const initialState = [];

export const kjoretoy = (state = initialState, action: IKjoretoyAction) => {
    switch (action.type) {
        case (KjoretoyActionKey.KJORETOY_IS_LOADING):
            return {
                ...state,
                [action.key]: {isLoading: action.isLoading}
            };
        case (KjoretoyActionKey.KJORETOY_GET_ERROR):
            return {
                ...state,
                [action.key]: {isLoading: false, error: action.error}
            };
        case (KjoretoyActionKey.KJORETOY_IS_FREMMED):
            return {
                ...state,
                [action.key]: {
                    isLoading: false,
                    ...action.fremmedKjoretoy
                }
            };
        case (KjoretoyActionKey.KJORETOY_GET_SUCCESS):
            return {
                ...state,
                [action.key]: {
                    isLoading: false,
                    ...action.kjoretoy
                }
            };
        case (KjoretoyActionKey.KJORETOY_RESET):
            return {
                ...state,
                [action.key]: null
            };
        default:
            return state;
    }
};

const singleKjoretoyIntialState: IKjoretoy | IFremmedKjoretoy = {
    isLoading: false,
    kuid: null,
    imid: null,
    kjennemerke: null,
    karosserifarge: null,
    understellsnummer: null,
    registreringsar: null,
    handelsbetegnelse: null,
    kjoretoyklassifisering: null,
    tekniskKode: null,
    kjennemerkeType: null,
    registreringsstatusKode: null,
    merke: null,
    kontaktVegvesenet: null,
    fremmedKjoretoyUnderstellsnr: null,
    fremmedKjoretoyRegistreringsar: null,
    fremmedKjoretoyHandelsbetegnelse: null,
    fremmedKjoretoyMerke: null,
    fremmedKjoretoyTekniskKodeBeskrivelse: null,
    fremmedKjoretoyTekniskKode: null,
    error: null
};

export const singleKjoretoy = (state = singleKjoretoyIntialState, action: IKjoretoyAction | IKjoretoylisteAction) => {
    switch (action.type) {
        case KjoretoyActionKey.KJORETOY_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case KjoretoyActionKey.KJORETOY_GET_ERROR:
            return {...state, isLoading: false, error: action.error};
        case KjoretoyActionKey.KJORETOY_IS_FREMMED:
            return {...state, isLoading: false, ...{...singleKjoretoyIntialState, ...action.fremmedKjoretoy}};
        case KjoretoyActionKey.KJORETOY_GET_SUCCESS:
            return {...singleKjoretoyIntialState, isLoading: false, ...action.kjoretoy};
        default:
            return state;
    }
};