import { MerkerActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError, IMerke } from '../../../models/types';
import { createRestResource } from '../../store';

export type IHentMerkerAction = IHentMerkerSuccessAction | IHentMerkerErrorAction;

interface IHentMerkerSuccessAction {
    type: MerkerActionKey.HENT_MERKER_SUCCESS;
    merker: IMerke[];
}

interface IHentMerkerErrorAction {
    type: MerkerActionKey.HENT_MERKER_ERROR;
    error: IError;
}

const hentMerkerSuccessAction = (merker: IMerke[]): IHentMerkerSuccessAction => ({
    type: MerkerActionKey.HENT_MERKER_SUCCESS,
    merker
});

const hentMerkerErrorAction = (error: IError): IHentMerkerErrorAction => ({
    type: MerkerActionKey.HENT_MERKER_ERROR,
    error
});

const merkerApi = createRestResource(AkrConfig.MERKER_RESOURCE_URL, PREFIX);

export function hentAlleMerker() {
    return dispatch => {
        merkerApi.get().then(response => {
            if (response.errorId) {
                dispatch(hentMerkerErrorAction(response));
            } else {
                dispatch(hentMerkerSuccessAction(response.merker));
            }
        });
    };
}