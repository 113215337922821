import type { AkSelectInputOption } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import { ArsprovekjennemerkeType } from 'svv-tk-akr-common-frontend';

import { createSelector } from 'reselect';
import type { IKjoretoygruppe, RootStateType } from '../../models/types';

const kjoretoygrupperSelector = (state: RootStateType) => state.kjoretoygrupper?.kjoretoygrupper;
const arsprovekjennemerkeTypeSelector = (state: RootStateType) => state.arsprovekjennemerke?.arsprovekjennemerke?.arsprovekjennemerketype;

export const validKjoretoyGrupperAsSelectOptionsSelector = createSelector(
    kjoretoygrupperSelector,
    arsprovekjennemerkeTypeSelector,
    (kjoretoygrupper, arsprovekjennemerkeType) => {
        const selectOptions: AkSelectInputOption[] = [];
        kjoretoygrupper.forEach(kjoretoygruppe => {
            selectOptions.push({
                description: kjoretoygruppe.navn,
                value: kjoretoygruppe.kode,
                disabled: !klassifiseringSkalVises(arsprovekjennemerkeType, kjoretoygruppe)
            });
        });
        return selectOptions;
    }
);

const klassifiseringSkalVises = (arsprovekjennemerkeType: ArsprovekjennemerkeType, kjoretoygruppe: IKjoretoygruppe) => {
    const kjoretoygruppeKjennemerke = ArsprovekjennemerkeType[kjoretoygruppe.kjennemerketype];
    if (arsprovekjennemerkeType === kjoretoygruppeKjennemerke) {
        return true;
    }
    if (arsprovekjennemerkeType === ArsprovekjennemerkeType.MOTORVOGN_1_SKILT) {
        return kjoretoygruppeKjennemerke === ArsprovekjennemerkeType.TILHENGER;
    }
    if (arsprovekjennemerkeType === ArsprovekjennemerkeType.MOTORVOGN_2_SKILT) {
        return true;
    }
};