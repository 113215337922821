export enum RegelmerknadKoder {
    KJORETOY_ER_PAREGISTRERT = 'KJORETOY_ER_PAREGISTRERT',
    KONTAKT_STATENS_VEGVESEN = 'KONTAKT_STATENS_VEGVESEN',
    KJORETOY_ER_VRAKET = 'KJORETOY_ER_VRAKET',
    KJORETOY_RALLY = 'KJORETOY_RALLY',
    KJORETOY_UTENFOR_OFFENTLIG_VEI = 'KJORETOY_UTENFOR_OFFENTLIG_VEI',
    IKKE_GYLDIG_TEKNISK_KODE_FOR_PROVEKJENNEMERKE = 'IKKE_GYLDIG_TEKNISK_KODE_FOR_PROVEKJENNEMERKE',
    MIDLERTIDIG_BRUKSTILLATELSE_AKTIV = 'MIDLERTIDIG_BRUKSTILLATELSE_AKTIV',
    ARSPROVEKJENNEMERKE_AVREGISTRERT = 'ARSPROVEKJENNEMERKE_AVREGISTRERT',
    ARSPROVEKJENNEMERKE_BRUKSFORBUD = 'ARSPROVEKJENNEMERKE_BRUKSFORBUD',
    MIDLERTIDIGBRUKSTILLATELSERAPPORT_NEDLASTNING_FEILET = 'MIDLERTIDIGBRUKSTILLATELSERAPPORT_NEDLASTNING_FEILET'
}

export enum RegelmerknadKontekst {
    PROVEKJENNEMERKE_VELG_KJORETOY = 'provekjennemerke.velg.kjoretoy',
    MIDLERTIDIG_BRUKSTILLATELSE_OPPRETT = 'midlertidig.brukstillatelse.opprett',
    MIDLERTIDIGBRUKSTILLATELSE_RAPPORT_LAST_NED = 'midlertidigbrukstillatelse.rapport.last.ned'
}

export enum RegelmerknaderType {
    INFORMASJON = 'INFORMASJON',
    KRITISK = 'KRITISK',
    IKKE_UTLEDET = 'IKKE_UTLEDET'
}