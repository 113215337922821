import * as React from 'react';
import { connect } from 'react-redux';

import { LoadingWithErrorHandler } from '../../components';
import type { IArsprovekjennemerke, IArsprovekjennemerker, IMidlertidigBrukstillatelseMap, RootStateType } from '../../models/types';
import { getArsprovekjennemerker } from '../../state/actions';
import { arsprovekjennemerkerSortedByMidlertidigbrukstillatelseSelector } from '../../state/selectors';
import { Arsprovekjennemerke } from './arsprovekjennemerke';

import './arsprovekjennemerker-style';

interface IArsprovekjennemerkerStateProps {
    arsprovekjennemerker: IArsprovekjennemerker;
    midlertidigbrukstillatelser: IMidlertidigBrukstillatelseMap;
    sortedArsprovekjennemerker: IArsprovekjennemerke[];
}

interface IArsprovekjennemerkerDispatchProps {
    getArsprovekjennemerker: () => Promise<any>;
}

interface IArsprovekjennemerkerState {
    showConfirmationBox: boolean;
    midlertidigBrukstillatelseId: number;
}

export type ArsprovekjennemerkerPropsType = IArsprovekjennemerkerStateProps & IArsprovekjennemerkerDispatchProps;

enum ArsprovekjennemerkeTypeClassNames {
    // TODO: Hente fra kodeverk, akr-platform_l44/.../register/kodeverk/provekjennemerke/ArsprovekjennemerkeType.java
    MOTORVOGN_2_SKILT = 'icon-car',
    TILHENGER = 'icon-trailer',
    MOTORVOGN_1_SKILT = 'icon-motorbike'
}

class Arsprovekjennemerker extends React.Component<ArsprovekjennemerkerPropsType, IArsprovekjennemerkerState> {

    public componentDidMount() {
        this.props.getArsprovekjennemerker();
    }

    public render() {
        return (
            <>
                <header className="ak-heading">
                    <h1>Oversikt årsprøvekjennemerker</h1>
                </header>
                <section className="ak-innhold">
                    <LoadingWithErrorHandler customClassNames="col-12 text-center" {...this.props.arsprovekjennemerker}
                        customMessageExpression={this.props.arsprovekjennemerker.arsprovekjennemerker.length === 0}
                        customMessageIntlKey="arsprovekjennemerker.merknader.ingen-apkj">
                        {this.props.sortedArsprovekjennemerker.map(arsprovekjennemerke => (<Arsprovekjennemerke key={arsprovekjennemerke.prkid} arsprovekjennemerke={arsprovekjennemerke} />))}
                    </LoadingWithErrorHandler>
                </section>
            </>
        );
    }
}

const mapStateToProps = (state: RootStateType): IArsprovekjennemerkerStateProps => ({
    arsprovekjennemerker: state.arsprovekjennemerker,
    midlertidigbrukstillatelser: state.midlertidigebrukstillatelser,
    sortedArsprovekjennemerker: arsprovekjennemerkerSortedByMidlertidigbrukstillatelseSelector(state)
});

const mapDispatchToProps = (dispatch): IArsprovekjennemerkerDispatchProps => ({
    getArsprovekjennemerker: () => dispatch(getArsprovekjennemerker())
});

const ArsprovekjennemerkerConnected = connect(mapStateToProps, mapDispatchToProps)(Arsprovekjennemerker);
export { Arsprovekjennemerker, ArsprovekjennemerkerConnected, ArsprovekjennemerkeTypeClassNames };
