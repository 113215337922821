import * as _ from 'lodash';
import type { IntlState} from '../intl';
import { UPDATE } from '../intl';
import { IntlActionKey } from '../../constants';
import type { IntlStateHelpers } from '../../models/types/intl';

import INITIAL_STATE_MESSAGES from '../store/initial-state-messages';

const intlIntitialState: IntlState & IntlStateHelpers = {
    locale: 'nb',
    messages: {...INITIAL_STATE_MESSAGES},
    isLoadedFromResource: false
};

export function intl(state = intlIntitialState, action) {
    if (action.type === UPDATE) {
        const newState = _.merge({}, state);
        return _.merge(newState, action.payload);
    } else if (action.type === IntlActionKey.INTL_FETCH_DATA_SUCCESS) {
        return {...state, isLoadedFromResource: true};
    }

    return {...state};
}