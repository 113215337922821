export const VALID_TLF_REGEX = /^(?:[/\s]?[0-9]){8}$/;
export const VALID_YEAR_REGEX = /^\d{4}$/;
export const DEFAULT_MELDING_VISNINGSTID_MS = 8000;
export const VALID_UNDERSTELLSNR_REGEX = /^[A-Za-z0-9\s]+$/;
export const VALID_KJENNEMERKE_REGEX =   /^[A-ZÆØÅa-zæøå0-9]+$/;
export const MAX_KJENNEMERKE_LENGDE = 7;
export const MIN_KJENNEMERKE_LENGDE = 2;
export const MAX_UNDERSTELLSNUMMER_LENGDE = 50;
export const MIN_UNDERSTELLSNUMMER_LENGDE = 1;
export const VALID_INPUT_TEXT_REGEX = /^[A-ZÆØÅa-zæøå0-9][A-ZÆØÅa-zæøå0-9\s/-]*$/;
