import { MidlertidigBrukstillatelseRapportActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError } from '../../../models/types';
import { createRestResource } from '../../store';

export type IMidlertidigBrukstillatelseRapportAction =
    IGetMidlertidigBrukstillatelserRapportSuccessAction
    | IGetMidlertidigBrukstillatelserRapportIsLoadingAction
    | IGetMidlertidigBrukstillatelserRapportErrorAction

interface IGetMidlertidigBrukstillatelserRapportSuccessAction {
    type: MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_SUCCESS;
}

interface IGetMidlertidigBrukstillatelserRapportIsLoadingAction {
    type: MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_IS_LOADING;
    isLoading: boolean;
}

interface IGetMidlertidigBrukstillatelserRapportErrorAction {
    type: MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_ERROR;
    error: IError;
}


const getMidlertidigBrukstillatelseRapportSuccessAction = (): IGetMidlertidigBrukstillatelserRapportSuccessAction => ({
    type: MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_SUCCESS
});

const getMidlertidigBrukstillatelseRapportIsLoadingAction = (isLoading: boolean): IGetMidlertidigBrukstillatelserRapportIsLoadingAction => ({
    type: MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_IS_LOADING,
    isLoading
});

const getMidlertidigBrukstillatelseRapportErrorAction = (error: IError): IGetMidlertidigBrukstillatelserRapportErrorAction => ({
    type: MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_ERROR,
    error
});


function handleDownload(blob: Blob, filename: string) {

    const data = URL.createObjectURL(blob);
    const link = document.createElement('a');
    const TIMEOUT = 100;

    link.href = data;
    link.download = filename;
    link.click();
    setTimeout(() => {
        // Firefox
        URL.revokeObjectURL(data);
    }, TIMEOUT);
}

const FILENAME_SEPARATOR = '\'\'';

export function hentMidlertidigeBrukstillatelseRapport(prkid: string, fomTidspunkt: string, tomTidspunkt: string) {

    const midlertidigBrukstillatelseRapportApi = createRestResource(AkrConfig.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORTER_RESOURCE_URL, PREFIX);
    const OK_RESPONSE = 200;

    return (dispatch) => {
        dispatch(getMidlertidigBrukstillatelseRapportIsLoadingAction(true));
        return midlertidigBrukstillatelseRapportApi.getFile( null, {prkid: prkid, fomTidspunkt: fomTidspunkt, tomTidspunkt: tomTidspunkt}).then(response => {

            try {
                const contentDispositionHeader = response.headers.get('content-disposition');
                if (response.status === OK_RESPONSE && contentDispositionHeader) {
                    response.blob().then((blob) => {
                        handleDownload(blob, contentDispositionHeader.split(FILENAME_SEPARATOR)[1]);
                    });
                } else {
                    response.json().then((json) => {
                        return dispatch(getMidlertidigBrukstillatelseRapportErrorAction(json.errorCode));
                    });
                }

            } catch (e) {
                return dispatch(getMidlertidigBrukstillatelseRapportErrorAction(e.errorCode));
            }
            dispatch(getMidlertidigBrukstillatelseRapportSuccessAction());
        });
    };
}


