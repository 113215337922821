export enum Motorvogn1SkiltTekniskeKoder {
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    T4 = 'T4',
    T5 = 'T5',
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    L1e = 'L1e',
    L2e = 'L2e',
    L3e = 'L3e',
    L4e = 'L4e',
    L5e = 'L5e',
    L6e = 'L6e',
    L7e = 'L7e',
    TR = 'TR',
    BM = 'BM',
    MCM = 'MCM',
    MCL = 'MCL',
    MCT = 'MCT',
    MR = 'MR',
    O1 = 'O1',
    O2 = 'O2',
    O3 = 'O3',
    O4 = 'O4',
    R1 = 'R1',
    R2 = 'R2',
    R3 = 'R3',
    R4 = 'R4',
    TT = 'TT'
}

export enum TilhengerSkilTekniskeKoder {
    O1 = 'O1',
    O2 = 'O2',
    O3 = 'O3',
    O4 = 'O4',
    R1 = 'R1',
    R2 = 'R2',
    R3 = 'R3',
    R4 = 'R4',
    TT = 'TT'
}

export const NorskTekniskUnderkode = 'U11';
