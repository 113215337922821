import { ProvekjennemerkerActionKey } from '../../../constants';
import type { IProvekjennemerkerAction } from '../../actions';

const initialState = {
    arsprovekjennemerker: [],
    isLoading: true,
    error: null
};

export const arsprovekjennemerker = (state = initialState, action: IProvekjennemerkerAction) => {
    switch (action.type) {
        case (ProvekjennemerkerActionKey.PROVEKJENNEMERKER_IS_LOADING):
            return {...initialState, isLoading: true};
        case (ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_SUCCESS):
            return {...state, arsprovekjennemerker: action.arsprovekjennemerker, isLoading: false};
        case (ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_EMPTY):
            return {...state, arsprovekjennemerker: [], isLoading: false};
        case (ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_ERROR):
            return {...state, error: action.error, isLoading: false};
        default:
            return state;
    }
};
