import { KjoretoygruppeActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError, IKjoretoygruppe } from '../../../models/types';
import { createRestResource } from '../../store';

export type KjoretoygruppeAction = IKjoretoygruppeErrorAction | IKjoretoygruppeSuccessAction;

interface IKjoretoygruppeSuccessAction {
    type: KjoretoygruppeActionKey.HENT_KJORETOYGRUPPE_SUCCESS;
    kjoretoygrupper: IKjoretoygruppe[];
}

interface IKjoretoygruppeErrorAction {
    type: KjoretoygruppeActionKey.HENT_KJORETOYGRUPPE_ERROR;
    error: IError;
}

const kjoretoygruppeSuccessAction = (kjoretoygrupper: IKjoretoygruppe[]): IKjoretoygruppeSuccessAction => ({
    type: KjoretoygruppeActionKey.HENT_KJORETOYGRUPPE_SUCCESS,
    kjoretoygrupper
});

const kjoretoygruppeErrorAction = (error: IError): IKjoretoygruppeErrorAction => ({
    type: KjoretoygruppeActionKey.HENT_KJORETOYGRUPPE_ERROR,
    error
});

const kjoretoygrupperApi = createRestResource(AkrConfig.KODEVERK_RESOURCE_URL, PREFIX);

export const getKjoretoygrupper = () => (dispatch, getState) => {
    if (!getState.kjoretoygrupper) {
        return kjoretoygrupperApi.get().then(response => {
            if (response.errorId) {
                dispatch(kjoretoygruppeErrorAction(response));
            } else {
                dispatch(kjoretoygruppeSuccessAction(response.content));
            }
        });
    }
};