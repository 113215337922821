require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-relativetimeformat/locale-data/nb');

if (!Intl.PluralRules) {
    require('@formatjs/intl-pluralrules/polyfill');
    require('@formatjs/intl-pluralrules/locale-data/nb');
}

import * as moment from 'moment';
import 'raf/polyfill';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createHashRouter, RouterProvider } from 'react-router-dom';

import { AppConnected } from './containers/app';
import IntlProvider from './state/intl';
import store from './state/store';

import './styles/styles';

// Moment Locale settings
moment.updateLocale('nb', {
    calendar: {
        nextDay: 'LT [i morgen]',
        sameDay: 'LT [i dag]'
    }
});

const renderApp = (RootComponent: any) => {
    const container = document.getElementById('root');
    const root = createRoot(container);

    const router = createHashRouter([
        { path: '*', Component: RootComponent }
    ]);

    root.render((
            <Provider store={store}>
                <IntlProvider locale="nb">
                    <RouterProvider router={router} />
                </IntlProvider>
            </Provider>
        )
    );
};

renderApp(AppConnected);
export default AppConnected;