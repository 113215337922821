import { MerknadActionKey } from '../../../constants';
import type { IMerknad } from '../../../models/types';

export type IMerknadAction = IUpdateMerknaderAction;

interface IUpdateMerknaderAction {
    type: MerknadActionKey.UPDATE_MERKNADER_ACTION;
    merknader: IMerknad[];
}

const updateMerknaderAction = (merknader: IMerknad[]): IUpdateMerknaderAction => ({
    type: MerknadActionKey.UPDATE_MERKNADER_ACTION,
    merknader
});

export function updateMerknader(merknader: IMerknad[]) {

    return dispatch => {
        dispatch(updateMerknaderAction(merknader));
    };
}