import { KjoretoyActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError, IFremmedKjoretoy, IKjoretoy, IMidlertidigBrukstillatelse } from '../../../models/types';
import { createRestResource } from '../../store';

export type IKjoretoyAction =
    IGetKjoretoySuccessAction |
    IKjoretoyIsLoadingAction |
    IGetKjoretoyErrorAction |
    IKjoretoyIsFremmed |
    IResetKjoretoyForMidlertidigBrukstillatelseAction;

interface IGetKjoretoySuccessAction {
    type: KjoretoyActionKey.KJORETOY_GET_SUCCESS;
    key: number;
    kjoretoy: IKjoretoy;
}

interface IKjoretoyIsLoadingAction {
    type: KjoretoyActionKey.KJORETOY_IS_LOADING;
    key: number;
    isLoading: boolean;
}

interface IGetKjoretoyErrorAction {
    type: KjoretoyActionKey.KJORETOY_GET_ERROR;
    key: number;
    error: IError;
}

interface IKjoretoyIsFremmed {
    type: KjoretoyActionKey.KJORETOY_IS_FREMMED;
    key: number;
    fremmedKjoretoy: IFremmedKjoretoy;
}

interface IResetKjoretoyForMidlertidigBrukstillatelseAction {
    type: KjoretoyActionKey.KJORETOY_RESET;
    key: number;
}

const getKjoretoySuccessAction = (kjoretoy: IKjoretoy, key: number): IGetKjoretoySuccessAction => ({
    type: KjoretoyActionKey.KJORETOY_GET_SUCCESS,
    key,
    kjoretoy
});

const kjoretoyIsLoadingAction = (isLoading: boolean, key: number): IKjoretoyIsLoadingAction => ({
    type: KjoretoyActionKey.KJORETOY_IS_LOADING,
    key,
    isLoading
});

const getKjoretoyErrorAction = (error: IError, key: number): IGetKjoretoyErrorAction => ({
    type: KjoretoyActionKey.KJORETOY_GET_ERROR,
    key,
    error
});

const kjoretoyIsFremmed = (fremmedKjoretoy: IFremmedKjoretoy, key: number): IKjoretoyIsFremmed => ({
    type: KjoretoyActionKey.KJORETOY_IS_FREMMED,
    key,
    fremmedKjoretoy
});

const resetKjoretoyForMidlertidigBrukstillatelseAction = (mbtId: number): IResetKjoretoyForMidlertidigBrukstillatelseAction => ({
    type: KjoretoyActionKey.KJORETOY_RESET,
    key: mbtId
});

const kjoretoyApi = createRestResource(AkrConfig.KJORETOY_RESOURCE_URL, PREFIX);

export function getKjoretoy(kuid: string, midlertidigbrukstillatelse: IMidlertidigBrukstillatelse) {
    return dispatch => {
        dispatch(kjoretoyIsLoadingAction(true, midlertidigbrukstillatelse.id));

        if (!kuid) {
            return dispatch(kjoretoyIsFremmed(midlertidigbrukstillatelse.fremmedKjoretoy, midlertidigbrukstillatelse.id));
        }

        return kjoretoyApi.get(kuid).then(response => {
            if (response.errorId) {
                return dispatch(getKjoretoyErrorAction(response, midlertidigbrukstillatelse.id));
            }

            if (response.totalCount === 0) {
                return null;
            }

            dispatch(getKjoretoySuccessAction(response, midlertidigbrukstillatelse.id));
        });
    };
}

export function resetKjoretoyForMidlertidigBrukstillatelse(prkid: number) {
    return dispatch => {dispatch(resetKjoretoyForMidlertidigBrukstillatelseAction(prkid));};
}
