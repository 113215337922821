import type { AkDateState} from 'svv-tk-akr-common-frontend/dist/mobile/index';
import { AkButton, AkCard, AkDate, AkLoading, AkMelding, AkMeldingboksType, AkSelectInput, ButtonType } from 'svv-tk-akr-common-frontend/dist/mobile/index';

import * as _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import type { IArsprovekjennemerke, IArsprovekjennemerker, IKjennemerke, IMerknadFilterCollection, RootStateType } from '../../models/types';
import type { IMidlertidigBrukstillatelseRapportRequest } from '../../models/types/midlertidig-brukstillatelse-rapport';
import { getArsprovekjennemerker, hentMidlertidigeBrukstillatelseRapport } from '../../state/actions';
import { arsprovekjennemerkerRegistrertDetSisteAretSelector, midlertidigBrukstillatelseRapportFilter } from '../../state/selectors';

import './arkiv-style.scss';

export interface IArkivStateProps {
    arsprovekjennemerker: IArsprovekjennemerker;
    usedArsprovekjennemerker: IArsprovekjennemerke[];
    midlertidigbrukstillatelserapport: IMidlertidigBrukstillatelseRapportRequest;
    merknader: IMerknadFilterCollection;
}

export interface IArkivDispatchProps {
    getArsprovekjennemerker: () => Promise<any>;
    hentMidlertidigeBrukstillatelseRapport: (prkid: string, fomTidspunkt: string, tomTidspunkt: string) => Promise<any>;
}

type PropsType = IArkivStateProps & IArkivDispatchProps;

interface IArkivState {
    arsprovekjennemerke: IArsprovekjennemerke;
    prkid: string;
    fom: string;
    tom: string;
    submitted: boolean;
    minDateFom: Moment;
    maxDateFom: Moment;
    minDateTom: Moment;
    maxDateTom: Moment;
    valideringsfeil: string[];
    nedlastingValidering: {
        arsprovekjennemerke: boolean;
        fom: boolean;
        tom: boolean;
    };
}

const INTL_KEY_SKJEMAFEIL = 'common.meldinger.skjemafeil';

class Arkiv extends React.Component<PropsType, IArkivState> {

    public state = {
        arsprovekjennemerke: null as IArsprovekjennemerke,
        prkid: '',
        fom: '',
        tom: '',
        submitted: false,
        // TODO: Bruk formater fra ACMF
        minDateFom: moment().subtract(1, 'years'),
        maxDateFom: moment(),
        minDateTom: moment().subtract(1, 'years'),
        maxDateTom: moment(),
        valideringsfeil: [
            INTL_KEY_SKJEMAFEIL
        ],
        nedlastingValidering: {
            arsprovekjennemerke: false,
            fom: false,
            tom: false
        }
    } as IArkivState;

    public componentDidMount() {
        this.props.getArsprovekjennemerker();
    }

    public render() {
        if (this.props.arsprovekjennemerker.isLoading) {
            return <AkLoading extraClassName="my-5" />;
        }

        return (
            <>
                <header className="ak-heading">
                    <h1><FormattedMessage id="arkiv.overskrift" /></h1>
                </header>
                <section className="ak-innhold">
                    <AkCard customClassNames="col-12 ak-kjoreseddel-arkiv">
                        <div className="col">
                            <h2><FormattedMessage id="arkiv.panel.tittel" /></h2>
                            {(!!this.state.valideringsfeil.length && this.state.submitted) &&
                            <AkMelding meldingType={AkMeldingboksType.ERROR} customClassName="mb-3" messageKey="common.meldinger.skjemafeil" />}
                        <div className="row no-gutters">
                            <FormattedMessage id="arkiv.panel.arsprovekjennemerker" tagName="label" />
                        </div>
                        <div>
                            {this.renderNedtrekk()}
                        </div>

                            <div className="row">
                                <div className="col-auto">
                                    <FormattedMessage id="arkiv.panel.fom" tagName="label" />
                                    <div className="ak-input-container">
                                        <AkDate onChangeFn={this.onFomDateChange} value={this.state.fom} minDate={this.state.minDateFom} maxDate={this.state.maxDateFom} required={true}
                                            submitted={this.state.submitted}/>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <FormattedMessage id="arkiv.panel.tom" tagName="label" />
                                    <div className="ak-input-container">
                                        <AkDate onChangeFn={this.onTomDateChange} value={this.state.tom} minDate={this.state.minDateTom} maxDate={this.state.maxDateTom} required={true}
                                            submitted={this.state.submitted}/>
                                    </div>
                                </div>
                            </div>

                            <div className="ak-button-row">
                                {(this.props.midlertidigbrukstillatelserapport && this.props.midlertidigbrukstillatelserapport.isLoading) ?
                                    <AkLoading /> :
                                    <AkButton buttonType={ButtonType.MAIN}
                                        buttonIntlKey="arkiv.knapp.last-ned"
                                        icon="fa fa-fw fa-download"
                                        iconPlacementEnd={true}
                                        action={this.lastNedRapport} />
                                }
                                {!(this.props.midlertidigbrukstillatelserapport && this.props.midlertidigbrukstillatelserapport.isLoading) &&
                                <AkButton buttonType={ButtonType.LINK} buttonIntlKey="arkiv.knapp.reset-felt" action={this.tomFelter} />}
                            </div>
                            <div>
                                {
                                    (this.sjekkForNedlastningsfeil() && <AkMelding meldingType={AkMeldingboksType.ERROR} messageKey={'arkiv.error.feil-ved-rapport-generering'} />)
                                }
                            </div>
                        </div>
                    </AkCard>
                </section>
            </>
        );
    }


    private handleNedtrekkEndring = (nedtrekkResultat) => {
        const nyPrkid = nedtrekkResultat.value;
        if (!this.state.arsprovekjennemerke || nyPrkid !== this.state.arsprovekjennemerke.prkid.toString()) {
            this.setState(prevState => ({
                arsprovekjennemerke: _.find(this.props.usedArsprovekjennemerker, (apkj: IArsprovekjennemerke) => {
                    return apkj.prkid.toString() === nyPrkid;
                }),
                prkid: nyPrkid,
                nedlastingValidering : {
                    ...prevState.nedlastingValidering,
                    arsprovekjennemerke: !!nyPrkid
                }
            }));
        }
    };

    private sjekkForNedlastningsfeil = () => (!!this.props.merknader.varselmeldinger.length || !!this.props.merknader.infomeldinger.length);

    private getCurrentKjennemerke = (arsprovekjennemerke: IArsprovekjennemerke): IKjennemerke => {
        return _.head(_.sortBy(arsprovekjennemerke.kjennemerker, 'periode.fomTidspunkt'));
    };

    private renderNedtrekk = () => {
        const {usedArsprovekjennemerker} = this.props;

        if (usedArsprovekjennemerker.length > 1) {
            const valgListe = usedArsprovekjennemerker.map((x => ({description: this.getCurrentKjennemerke(x).tegnkombinasjon.visning, value: x.prkid.toString()})));
            return (
                <AkSelectInput
                    handleSelectChange={this.handleNedtrekkEndring}
                    placeholder={'Velg'}
                    placeholderEnabled={true}
                    required={true}
                    submitted={this.state.submitted}
                    options={valgListe}
                    value={this.state.prkid} />);
        } else if (usedArsprovekjennemerker.length == 1) {
            const valg = usedArsprovekjennemerker.map((x => ({description: this.getCurrentKjennemerke(x).tegnkombinasjon.visning, value: x.prkid.toString()})));
            return (
                <AkSelectInput
                    handleSelectChange={this.handleNedtrekkEndring}
                    required={true}
                    submitted={this.state.submitted}
                    options={valg}
                    initialSelectedValue={valg[0].value}/>);
        } else {
            return (
                <AkSelectInput
                    handleSelectChange={this.handleNedtrekkEndring}
                    disabled={true}
                    required={true}
                    submitted={this.state.submitted}
                    options={[{value: 'Ingen alternativer'}]}/>);
            }
        };


    private lastNedRapport = () => {

        this.setState({submitted: true});

        const ingenValideringsfeil = _.every(this.state.nedlastingValidering, (field: boolean) => field);

        if (ingenValideringsfeil) {

            this.setState({
                valideringsfeil: []
            });
            this.props.hentMidlertidigeBrukstillatelseRapport(this.state.prkid, this.state.fom, this.state.tom);
        }
    };

    private onFomDateChange = (result: AkDateState) => {
        const fomInput = result.value;
        this.setState(prevState => ({
            fom: fomInput,
            nedlastingValidering: {
                ...prevState.nedlastingValidering,
                fom: !!result.value
            }
        }));
    };

    private onTomDateChange = (result: AkDateState) => {
        const tomInput = result.value;
        this.setState(prevState => ({
            tom: tomInput,
            nedlastingValidering: {
                ...prevState.nedlastingValidering,
                tom: !!result.value
            }
        }));
    };

    private tomFelter = () => {
        const skalResetteArsprovekjennemerke = this.props.usedArsprovekjennemerker.length > 1;
        this.setState({
            fom: '',
            tom: '',
            submitted: false,
            arsprovekjennemerke: skalResetteArsprovekjennemerke ? null : this.state.arsprovekjennemerke,
            prkid: skalResetteArsprovekjennemerke ? '' : this.state.prkid,
            valideringsfeil: [
                INTL_KEY_SKJEMAFEIL
            ],
            nedlastingValidering: {
                arsprovekjennemerke: false,
                fom: false,
                tom: false
            }
        });
    };
}

const mapStateToProps = (state: RootStateType): IArkivStateProps => ({
    arsprovekjennemerker: state.arsprovekjennemerker,
    usedArsprovekjennemerker: arsprovekjennemerkerRegistrertDetSisteAretSelector(state),
    midlertidigbrukstillatelserapport: state.midlertidigebrukstillatelserapport,
    merknader: midlertidigBrukstillatelseRapportFilter(state)
});

const mapDispatchToProps = (dispatch): IArkivDispatchProps => ({
    getArsprovekjennemerker: () => dispatch(getArsprovekjennemerker(true)),
    hentMidlertidigeBrukstillatelseRapport: (prkid: string, fomTidspunkt: string, tomTidspunkt: string) => dispatch(hentMidlertidigeBrukstillatelseRapport(prkid, fomTidspunkt, tomTidspunkt))
});

const ArkivConnected = connect(mapStateToProps, mapDispatchToProps)(Arkiv);
export { ArkivConnected, Arkiv };
