import type { Bruker, Brukerprofil, RootStateType } from '../../models/types';

const bruker: Brukerprofil = {
    bruker: {} as Bruker,
    valgtEnhet: null
};

const initialState: Partial<RootStateType> = {
    brukerprofil: bruker,
    globals: {
        globalErrors: []
    },
    klientkonfigurasjon: {},
    hovedmenyEkspandert: false
};

export { initialState };