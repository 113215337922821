import * as _ from 'lodash';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import type { IntlState } from '../../state/intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { AkButton, AkLoading, ButtonType, LoadingType } from 'svv-tk-akr-common-frontend/dist/mobile/index';

import { Footer, LoadingWithErrorHandler } from '../../components';
import { AkrConfig } from '../../constants/akr-config';
import type { Brukerprofil, IKlientkonfigurasjonState, IMerknad, IntlStateHelpers, RootStateType } from '../../models/types';
import { brukerprofilGetData, intlGetData, klientkonfigurasjonGetData, klientkonfigurasjonUsikretGetData, logError, redirectToLogin, updateMerknader } from '../../state/actions';
import { hovedmenySeksjonEkspandert } from '../../state/actions/globals/globals';
import { merknadSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils/router-util';
import { withRouter } from '../../utils/router-util';

import { ErrorHandler } from '../error';
import { MeldingBarConnected } from '../melding-bar/melding-bar';
import { AkRouter } from './';
import { Header } from './header';

export interface IAppProps {
    brukerprofil: Brukerprofil;
    getBrukerprofil: () => Promise<any>;
    redirectToLogin: (klientkonfigurasjon: IKlientkonfigurasjonState) => void;
    hovedmenyEkspandert: boolean;
    setHovedmenyEkspandert: (ekspandert: boolean) => Promise<unknown>;
    intl: IntlState & IntlStateHelpers;
    getTranslations: () => Promise<unknown>;
    klientkonfigurasjon?: IKlientkonfigurasjonState;
    getKlientkonfigurasjon: () => Promise<unknown>;
    getKlientkonfigurasjonUsikret: () => Promise<unknown>;
    merknader?: IMerknad[];
    nextMerknader?: IMerknad[];
    updateMerknader?: (merknader: IMerknad[]) => Promise<any>;
    logRuntimeError?: (error: string) => Promise<any>;
    globalErrors: any;
}

interface IAppState {
    tabKeyPressed: boolean;
}

const TAB_KEY_CODE = 9;

class App extends React.Component<IAppProps & WithRouterProps, IAppState> {


    public state: IAppState = {
        tabKeyPressed: false
    };

    public componentDidUpdate(prevProps: IAppProps) {
        const {brukerprofil, intl, klientkonfigurasjon, nextMerknader, merknader} = this.props;

        if (!_.isEqual(prevProps.brukerprofil.bruker, brukerprofil.bruker) && brukerprofil.bruker?.avdelinger?.length === 1 && _.isEmpty(brukerprofil.valgtEnhet)) {
            window.location.href = `${window.env.serviceUrl}${AkrConfig.BRUKERPROFIL_AVDELING_RESOURCE_URL}/${_.head(brukerprofil.bruker.avdelinger).enhetId}`;
            this.props.setHovedmenyEkspandert(false);
        }

        if (!_.isEqual(prevProps.brukerprofil.valgtEnhet, brukerprofil.valgtEnhet)) {
            if (!intl.isLoadedFromResource) {
                this.props.getTranslations();
            }

            if (_.isEmpty(klientkonfigurasjon)) {
                this.props.getKlientkonfigurasjon();
            }
        }

        if (!_.isEqual(nextMerknader, merknader)) {
            this.props.updateMerknader(nextMerknader);
        }
    }

    public componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeydown);
    }

    public componentDidMount() {
        this.props.getBrukerprofil();
        window.addEventListener('keydown', this.handleKeydown);
    }

    public render() {

        const {brukerprofil, intl, hovedmenyEkspandert, logRuntimeError, location} = this.props;

        return (
            <main className={`app-main ${this.state.tabKeyPressed ? '' : 'no-outline-on-focus'}`}>
                <Header/>
                <MeldingBarConnected />
                {!hovedmenyEkspandert &&
                    <ErrorHandler logError={logRuntimeError} location={location}>
                        <LoadingWithErrorHandler error={brukerprofil.error}
                            renderCustomErrorFn={brukerprofil.isUnauthorized ? this.renderLoginError : null} >
                            {!brukerprofil.isLoading && intl.isLoadedFromResource && !!brukerprofil.valgtEnhet ?
                             <AkRouter /> :
                             <AkLoading loadingType={LoadingType.CAR} extraClassName="mt-4" displayTextKey="generell.laster" />
                            }
                        </LoadingWithErrorHandler>
                    </ErrorHandler>
                }
                <Footer/>
            </main>
        );
    }

    private renderLoginError = (): React.ReactNode => {
        return <AkButton buttonIntlKey="feilhandtering.generell.link.redirectToLogin" action={this.handleRedirectToLogin} buttonType={ButtonType.LINK}/>;
    };

    private handleRedirectToLogin = () => {
        this.props.getKlientkonfigurasjonUsikret().then(() => this.props.redirectToLogin(this.props.klientkonfigurasjon));
    };

    private handleKeydown = (e) => {
        const isTabEvent = e.keyCode === TAB_KEY_CODE;

        if (isTabEvent) {
            this.setState({tabKeyPressed: true});
            window.removeEventListener('keydown', this.handleKeydown);
        }
    };
}

const mapDispatchToProps = (dispatch): Partial<IAppProps> => ({
    updateMerknader: (merknader: IMerknad[]) => dispatch(updateMerknader(merknader)),
    logRuntimeError: (error: string) => dispatch(logError(error)),
    getBrukerprofil: () => dispatch(brukerprofilGetData()),
    redirectToLogin: (klientkonfigurasjon: IKlientkonfigurasjonState) => redirectToLogin(klientkonfigurasjon),
    setHovedmenyEkspandert: (ekspandert: boolean) => dispatch(hovedmenySeksjonEkspandert(ekspandert)),
    getKlientkonfigurasjon: () => dispatch(klientkonfigurasjonGetData()),
    getKlientkonfigurasjonUsikret: () => dispatch(klientkonfigurasjonUsikretGetData()),
    getTranslations: () => dispatch(intlGetData('nb'))
});

const mapStateToProps = (state: RootStateType): Partial<IAppProps> => ({
    brukerprofil: state.brukerprofil,
    hovedmenyEkspandert: state.hovedmenyEkspandert,
    intl: state.intl,
    globalErrors: state.globals.globalErrors,
    nextMerknader: merknadSelector(state),
    merknader: state.merknader.merknader,
    klientkonfigurasjon: state.klientkonfigurasjon
});

const AppConnected = compose(
    injectIntl,
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(App);

export { App, AppConnected };