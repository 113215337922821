/* eslint-disable max-lines */
import type { AkDateState, AkDateTimeState, AkRadioButtonResult, AkTextInputResult} from 'svv-tk-akr-common-frontend/dist/mobile';
import {
    AkButton, AkCard, AkDate, AkDateTime, AkMelding, AkMeldingboksType, AkRadioButton, AkTextInput, ButtonType
} from 'svv-tk-akr-common-frontend/dist/mobile';
import { ISO_8601_DATO_TID_FORMAT, FULLT_DATO_TID_FORMAT } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import * as React from 'react';
import type { SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { ArsprovekjennemerkeKjoretoyKnytningConnected } from '../../components';
import { VALID_INPUT_TEXT_REGEX } from '../../constants';
import type {
    Brukerprofil, IFremmedKjoretoy, IKjoretoy, IMerknad, IMerknadFilterCollection, IMidlertidigBrukstillatelse, IMidlertidigBrukstillatelseMap, RootStateType, TidspunktAdvarsler
} from '../../models/types';
import { EtterregistreringRegler, MERKNADER_SOM_IKKE_SKAL_HINDRE_ETTERREGISTRERING, MERKNADER_SOM_IKKE_SKAL_VISES, MidlertidigBrukstillatelseRegler } from '../../regler';
import { getMidlertidigeBrukstillatelser, newMidlertidigbrukstillatelse, stoppEksisterendeOgOpprettNyMidlertidigBrukstillatelse } from '../../state/actions';
import { midlertidigBrukstillatelseOpprettFilter } from '../../state/selectors';
import { isFremmedKjoretoy } from '../../type-guards';
import type { WithRouterProps} from '../../utils';
import { withRouter } from '../../utils';
import { EtterregistreringPeriodeMeldinger } from '../etterregistrer';

import './kjoreseddel-style.scss';

// TODO: Hente fra backend kodeverk?
enum FormalEnum {
    PROVING = 'PROVING',
    FLYTTING = 'FLYTTING',
    DEMONSTRASJON = 'DEMONSTRASJON',
    REGISTRERING = 'REGISTRERING'
}
export interface IKjoreseddelOwnProps {
    params: {
        prkid: string;
        kuid: string;
    };
    location: Partial<Location> & { state: { fremmedKjoretoy: IFremmedKjoretoy }};
}
export interface IKjoreseddelStateProps {
    etterregistrerContext?: boolean;
    merknader: IMerknadFilterCollection;
    midlertidigbrukstillatelse: IMidlertidigBrukstillatelse;
    midlertidigebrukstillatelser: IMidlertidigBrukstillatelseMap;
    kjoretoy: IKjoretoy | IFremmedKjoretoy;
    brukerprofil: Brukerprofil;
}

export interface IKjoreseddelDispatchProps {
    newMidlertidigBrukstillatelse: (midlertidigbrukstillatelse: IMidlertidigBrukstillatelse, isEtterregistreringContext: boolean) => Promise<unknown>;
    getMidlertidigeBrukstillatelser: (prkid: number) => Promise<unknown>;
    stoppOgOpprettMidlertidigBrukstillatelse: (eksisterendeMbtId: number, newMbt: IMidlertidigBrukstillatelse, isEtterregistreringContext: boolean) => Promise<unknown>;
}

export interface IKjoreseddelState {
    submitted?: boolean;
    disableInput?: boolean;
    validationErrors: string[];
    formValidation: {
        formal: boolean;
        strekning: boolean;
        forersNavn: boolean;
        forersFodselsdato: boolean;
        fomTidspunkt: boolean;
    };
    fomTidspunktErrorMessage: string;
    tidspunktValidation: TidspunktAdvarsler;
    midlertidigbrukstillatelse: IMidlertidigBrukstillatelse;
}

type PropsType = IKjoreseddelOwnProps & IKjoreseddelStateProps & IKjoreseddelDispatchProps & Omit<WithRouterProps, 'location'>;

const INTL_KEY_SKJEMAFEIL = 'common.meldinger.skjemafeil';
const MAX_NUM_STREKNING = 100;
const MAX_NUM_FORERS_NAVN = 35;
const MIN_NUM_FORERS_NAVN = 2;

class Kjoreseddel extends React.Component<PropsType, IKjoreseddelState> {

    public state = {
        submitted: false,
        disableInput: false,
        validationErrors: [
            INTL_KEY_SKJEMAFEIL
        ],
        formValidation: {
            formal: false,
            strekning: true,
            forersNavn: false,
            forersFodselsdato: false,
            fomTidspunkt: false
        },
        tidspunktValidation: {
            eksisterendeKortereEnnTiMin: false,
            nyKortereEnnTiMin: false,
            aktivSeddelNedkortet: false,
            fremtidigSeddelNedkortet: false,
            varighetKortereEnn24Timer: false,
            seddelEksistererAllerede: false
        },
        midlertidigbrukstillatelse: {
            prkid: Number(this.props.params.prkid),
            kuid: this.props.params.kuid,
            formal: null,
            strekning: null,
            forersNavn: null,
            forersFodselsdato: '',
            fremmedKjoretoy: this.props.location.state?.fremmedKjoretoy || null,
            utstedtAvKundeId: this.props.brukerprofil?.bruker?.kundeId,
            periode: {
                fomTidspunkt: moment().format(),
                tilTidspunkt: moment().add(1, 'days').format()
            }
        }
    } as IKjoreseddelState;

    public componentDidMount() {
        this.props.getMidlertidigeBrukstillatelser(Number(this.props.params.prkid));
    }

    public componentDidUpdate(prevProps: IKjoreseddelStateProps) {
        if (!this.state.disableInput && !!this.filtrerBortVarselmerknaderSomIkkeErRelevante(this.props.merknader.varselmeldinger).length) {
            this.setState({
                disableInput: true
            });
        }

        if (!_.isEqual(prevProps.kjoretoy, this.props.kjoretoy) && !isFremmedKjoretoy(this.props.kjoretoy)) {
            this.setState(prevState => ({ midlertidigbrukstillatelse: { ...prevState.midlertidigbrukstillatelse, imid: (this.props.kjoretoy as IKjoretoy).imid } }));
        }

        if (!_.isEqual(prevProps.midlertidigebrukstillatelser[this.props.params.prkid], this.props.midlertidigebrukstillatelser[this.props.params.prkid])) {
            const tidspunktAdvarsler = MidlertidigBrukstillatelseRegler.kontrollerKjoreseddelTidspunkt(
                this.props.midlertidigebrukstillatelser[Number(this.props.params.prkid)]?.liste || [],
                moment(this.state.midlertidigbrukstillatelse.periode.fomTidspunkt),
                moment(this.state.midlertidigbrukstillatelse.periode.tilTidspunkt)
            );

            this.setState(prevState => ({
                midlertidigbrukstillatelse: {
                    ...prevState.midlertidigbrukstillatelse,
                    periode: {
                        fomTidspunkt: prevState.midlertidigbrukstillatelse.periode.fomTidspunkt,
                        tilTidspunkt: this.utledTilTidspunktKjoreseddel(prevState.midlertidigbrukstillatelse.periode.fomTidspunkt)
                    }
                },

                formValidation: {
                    ...this.state.formValidation,
                    fomTidspunkt: !tidspunktAdvarsler.eksisterendeKortereEnnTiMin && !tidspunktAdvarsler.nyKortereEnnTiMin && !tidspunktAdvarsler.seddelEksistererAllerede
                },
                tidspunktValidation: tidspunktAdvarsler
            }));
        }

        if (!_.isEqual(prevProps.midlertidigbrukstillatelse.error, this.props.midlertidigbrukstillatelse.error)) {
            this.props.getMidlertidigeBrukstillatelser(Number(this.props.params.prkid));
        }

        if (this.props.midlertidigbrukstillatelse?.redirectTrigger) {
            this.goToKjoreseddelKvittering();
        }
    }

    public render() {
        const historiskeMidlertidbrukstillatelser = this.props.midlertidigebrukstillatelser[this.props.params.prkid];
        const varselmeldinger = this.filtrerBortVarselmerknaderSomIkkeErRelevante(this.props.merknader.varselmeldinger);
        const varselmeldingerFraMidlertidigBrukstillatelse = this.props.midlertidigbrukstillatelse?.merknader || [];
        const infomeldinger = this.filtrerBortInfomerknaderSomIkkeErRelevante(this.props.merknader.infomeldinger) || [];

        return (
            <div className="ak-kjoreseddel">
                <header className="ak-heading">
                    {this.props.etterregistrerContext ?
                     <FormattedMessage id="kjoreseddel.etterregistrer.overskrift" tagName="h1" /> :
                     <FormattedMessage id="kjoreseddel.overskrift" tagName="h1" />
                    }
                </header>
                <section className="ak-innhold">
                    <AkCard customClassNames="col-12">
                        <section className="ak-kjoreseddel-apkj-kjoretoy-info col order-md-2 col-md-4">
                            <ArsprovekjennemerkeKjoretoyKnytningConnected {...this.state.midlertidigbrukstillatelse} />
                        </section>
                        <section className="ak-kjoreseddel-info col">
                            <div className="row">
                                <h2 className="col">
                                    <FormattedMessage id="kjoreseddel.underoverskrift" />
                                </h2>
                            </div>
                            <div className="ak-kjoreseddel-meldinger row no-gutters">
                                {/*TODO: merge validationErrors og props.merknader.varselmeldinger*/}
                                {this.props.etterregistrerContext &&
                                <EtterregistreringPeriodeMeldinger
                                    etterregMbt={this.state.midlertidigbrukstillatelse}
                                    historiskeMbter={historiskeMidlertidbrukstillatelser?.liste || []} />
                                }
                                {!!varselmeldingerFraMidlertidigBrukstillatelse.length &&
                                <AkMelding meldingType={AkMeldingboksType.ERROR} messageKey={varselmeldingerFraMidlertidigBrukstillatelse.map(m => `kjoreseddel.merknader.${m.kode}`)} />
                                }
                                {this.state.submitted && !!this.state.validationErrors.length &&
                                <AkMelding meldingType={AkMeldingboksType.ERROR} messageKey={this.state.validationErrors} />
                                }
                                {!!varselmeldinger.length &&
                                <AkMelding meldingType={AkMeldingboksType.ERROR} messageKey={varselmeldinger.map(m => `kjoreseddel.merknader.${m.kode}`)} />
                                }
                                {!!infomeldinger.length &&
                                <AkMelding meldingType={AkMeldingboksType.INFO} messageKey={infomeldinger.map(m => `kjoreseddel.merknader.${m.kode}`)} />
                                }
                                {this.renderInfoTidspunkt()}
                            </div>

                            <form className="ak-kjoreseddel-input-form row" onSubmit={this.formSubmitHandler}>
                                <FormattedMessage id="kjoreseddel.label.formal" tagName="label" />
                                <fieldset>
                                    {Object.keys(FormalEnum).map(formalKey => {
                                        return (
                                            <AkRadioButton customClassNames="px-0 d-flex d-md-inline-flex mr-5" disabled={this.state.disableInput} key={formalKey} groupName="kjoreseddel-formal"
                                                labelIntlKey={`kjoreseddel.input.formal.${formalKey}`} stateValue={this.state.midlertidigbrukstillatelse.formal} value={formalKey}
                                                onSelectFn={this.onFormalChange} />
                                        );
                                    })}
                                    {(!this.state.formValidation.formal && this.state.submitted) &&
                                    <div className="ak-input-error-text">
                                        <FormattedMessage id="kjoreseddel.merknader.formal" />
                                    </div>
                                    }
                                </fieldset>
                                <div className="ak-input-container row">
                                    <div className="ak-datofelt-wrapper">
                                        <FormattedMessage id="kjoreseddel.label.periode.start" tagName="label" />
                                        {this.renderStartDateTimeFelt()}
                                    </div>
                                    <div className="ak-datofelt-wrapper">
                                        <FormattedMessage id="kjoreseddel.label.periode.slutt" tagName="label" />
                                        {this.renderSluttDateTimeFelt()}
                                    </div>
                                </div>


                                {this.strekningPakrevd() &&
                                <>
                                    <FormattedMessage id="kjoreseddel.label.kjorestrekning" tagName="label" />
                                    <AkTextInput required={true} propertyKey="strekning" maxLength={MAX_NUM_STREKNING} validRegExp={VALID_INPUT_TEXT_REGEX}
                                        customInputClassName="w-100 p-1" disabled={this.state.disableInput}
                                        typeInput={this.state.midlertidigbrukstillatelse.strekning} onBlur={this.onStrekningChange} submitted={this.state.submitted} />
                                </>
                                }
                                <FormattedMessage id="kjoreseddel.label.forers-navn" tagName="label" />
                                <AkTextInput propertyKey="forersNavn" required={true} disabled={this.state.disableInput} maxLength={MAX_NUM_FORERS_NAVN} minLength={MIN_NUM_FORERS_NAVN}
                                    validRegExp={VALID_INPUT_TEXT_REGEX} customInputClassName="w-100 p-1 mb-0" initialValue={this.state.midlertidigbrukstillatelse.forersNavn || ''}
                                    onBlur={this.onForersNavnChange} submitted={this.state.submitted} />

                                <FormattedMessage id="kjoreseddel.label.forers-fodselsdato" tagName="label" />
                                {this.renderFodselsdatoDatoFelt()}

                            </form>
                            <div className="ak-button-row">
                                <AkButton isLoading={this.props.midlertidigbrukstillatelse.isLoading} disabled={this.isFullforknappDisabled()} buttonIntlKey="kjoreseddel.buttons.fullfor"
                                    action={this.submitKjoreseddel} buttonType={ButtonType.MAIN} />
                                <AkButton buttonIntlKey="kjoreseddel.buttons.go-back" action={this.goBack} buttonType={ButtonType.SECONDARY}
                                    disabled={this.props.midlertidigbrukstillatelse.isLoading || this.state.disableInput} customClassNames="mr-0" />
                            </div>
                        </section>
                    </AkCard>
                </section>
                <div className="ak-content-footer no-gutters">
                    <AkButton action={this.redirectToOversiktsbilde} buttonIntlKey="kjoreseddel.sok.avbryt" buttonType={ButtonType.LINK} />
                </div>
            </div>
        );
    }

    private renderInfoTidspunkt = () => {
        /* TODO: burde lage dette til en loop */
        const nyKjoreseddelBlokkert = this.state.tidspunktValidation.eksisterendeKortereEnnTiMin
            || this.state.tidspunktValidation.nyKortereEnnTiMin
            || this.state.tidspunktValidation.seddelEksistererAllerede;
        return (
            <>
                {(this.state.tidspunktValidation.aktivSeddelNedkortet && !nyKjoreseddelBlokkert) && this.renderTidspunktInfomelding('kjoreseddel.merknader.fomTidspunktAktivSeddelNedkortet',
                    AkMeldingboksType.INFO)}
                {(this.state.tidspunktValidation.fremtidigSeddelNedkortet && !nyKjoreseddelBlokkert) && this.renderTidspunktInfomelding('kjoreseddel.merknader.fomTidspunktFremtidigSeddelNedkortet',
                    AkMeldingboksType.INFO)}
                {(this.state.tidspunktValidation.varighetKortereEnn24Timer && !nyKjoreseddelBlokkert) && this.renderTidspunktInfomelding('kjoreseddel.merknader.fomTidspunktVarighetKortereEnn24',
                    AkMeldingboksType.INFO)}
                {this.state.tidspunktValidation.eksisterendeKortereEnnTiMin && this.renderTidspunktInfomelding('kjoreseddel.merknader.fomTidspunktEksisterendeVarighetForKort',
                    AkMeldingboksType.ERROR)}
                {this.state.tidspunktValidation.nyKortereEnnTiMin && this.renderTidspunktInfomelding('kjoreseddel.merknader.fomTidspunktNyVarighetForKort',
                    AkMeldingboksType.ERROR)}
                {this.state.tidspunktValidation.seddelEksistererAllerede && this.renderTidspunktInfomelding('kjoreseddel.merknader.fomTidspunktSeddelEksistererAllerede',
                    AkMeldingboksType.ERROR)}
            </>
        );
    };

    private renderTidspunktInfomelding = (id: string, meldingstype: AkMeldingboksType) => {
        return (

            <AkMelding
                meldingType={meldingstype}
                messageKey={{id:id,
                    values: {understellEllerKjennemerke: isFremmedKjoretoy(this.props.kjoretoy) ? this.props.kjoretoy.fremmedKjoretoyUnderstellsnr : this.props.kjoretoy.kjennemerke}}} />
        );
    };

    private renderStartDateTimeFelt = () => {
        const maxDate = moment();
        const minDate = moment();

        if (!this.props.etterregistrerContext) {
            maxDate.add(30, 'days');
        } else {
            minDate.subtract(1, 'years');
        }
        return (
            <div className="ak-input-container">
                <AkDateTime
                    value={this.state.midlertidigbrukstillatelse.periode.fomTidspunkt}
                    submitted={this.state.submitted}
                    maxDate={maxDate}
                    minDate={minDate}
                    maxTime={this.utledMaxTime()}
                    minTime={this.utledMinTime()}
                    required={true}
                    onChangeFn={this.onStartDateTimeChange}
                    error={this.state.fomTidspunktErrorMessage}
                />
            </div>
        );
    };

    private renderFodselsdatoDatoFelt = () => {
        const idag = moment();
        return (
            <div className="ak-input-container">

                <AkDate
                    propertyKey="forersFodselsdato"
                    maxDate={idag}
                    required={true}
                    value={this.state.midlertidigbrukstillatelse.forersFodselsdato}
                    submitted={this.state.submitted}
                    onChangeFn={this.onForersFodselsdatoChange} />
            </div>
        );
    };

    private utledMaxTime = (): Moment => {
        const isDagensDatoValgtSomStartDato = moment().isSame(this.state.midlertidigbrukstillatelse.periode?.fomTidspunkt, 'day');

        if (this.state.midlertidigbrukstillatelse.periode && isDagensDatoValgtSomStartDato && this.props.etterregistrerContext) {
            return moment().subtract(1, 'minutes');
        } else {
            return moment().add(1, 'minutes');
        }
    };

    private utledMinTime = (): Moment => {
        const isDagensDatoValgtSomStartDato = moment().isSame(this.state.midlertidigbrukstillatelse.periode?.fomTidspunkt, 'day');

        if (this.state.midlertidigbrukstillatelse.periode && isDagensDatoValgtSomStartDato && !this.props.etterregistrerContext) {
            return moment().add(1, 'minutes');
        } else {
            return moment().subtract(1, 'minutes');
        }
    };

    private renderSluttDateTimeFelt = () => {
        const slutt = moment(this.state.midlertidigbrukstillatelse.periode.tilTidspunkt).format(FULLT_DATO_TID_FORMAT);
        return <output className="ak-sluttidspunkt">{slutt}</output>;
    };

    private formSubmitHandler(event: SyntheticEvent<HTMLFormElement>) {
        return event.preventDefault();
    }

    private submitKjoreseddel = () => {
        this.setState({ submitted: true, validationErrors: [] });

        const isNoFormValidationErrors = _.every(this.state.formValidation, (field: boolean) => field);

        if (!isNoFormValidationErrors) {

            return this.setState({
                validationErrors: [
                    INTL_KEY_SKJEMAFEIL
                ]
            });
        }

        if (isNoFormValidationErrors && !this.kanIkkeFullfore()) {
            const historiskeMidlertidbrukstillatelser = this.props.midlertidigebrukstillatelser[this.props.params.prkid];
            const historiskMbt = (this.props.etterregistrerContext
                && historiskeMidlertidbrukstillatelser.liste.length) ?
                                 EtterregistreringRegler.finnHistoriskMtbSomFarEndretSluttDateTime(
                                     this.state.midlertidigbrukstillatelse.periode.fomTidspunkt,
                                     historiskeMidlertidbrukstillatelser.liste
                                 ) : null;
            if (!!historiskMbt) {
                return this.props.stoppOgOpprettMidlertidigBrukstillatelse(
                    Number(historiskMbt.id),
                    this.state.midlertidigbrukstillatelse,
                    this.props.etterregistrerContext
                );
            }

            this.props.newMidlertidigBrukstillatelse({ ...this.state.midlertidigbrukstillatelse }, this.props.etterregistrerContext);
        }
    };

    private goBack = () => {
        this.props.navigate(-1);
    };

    private goToKjoreseddelKvittering = () => {
        if (this.props.etterregistrerContext) {
            return this.props.navigate(`/etterregistrer/kjoreseddel/${this.props.midlertidigbrukstillatelse.id}`);
        }
        return this.props.navigate(`/kjoreseddel/${this.props.midlertidigbrukstillatelse.id}`);
    };

    private onFormalChange = (result: AkRadioButtonResult) => {
        this.setState(prevState => ({
            formValidation: {
                ...prevState.formValidation,
                formal: !!result.value, // TODO: Sjekk på result.error (må implementeres)
                strekning: !this.strekningPakrevd(result.value as FormalEnum)
            },
            midlertidigbrukstillatelse: {
                ...prevState.midlertidigbrukstillatelse,
                formal: result.value as FormalEnum,
                strekning: !this.strekningPakrevd(result.value as FormalEnum) ? null : prevState.midlertidigbrukstillatelse.strekning
            }
        }));
    };

    private onStrekningChange = (result: AkTextInputResult) => {
        this.setState(prevState => ({
            formValidation: {
                ...prevState.formValidation,
                strekning: _.isEmpty(result.errors)
            },
            midlertidigbrukstillatelse: {
                ...prevState.midlertidigbrukstillatelse,
                strekning: result.value
            }
        }));
    };

    private onForersNavnChange = (result: AkTextInputResult) => {
        this.setState(prevState => ({
            formValidation: {
                ...prevState.formValidation,
                forersNavn: _.isEmpty(result.errors)
            },
            midlertidigbrukstillatelse: {
                ...prevState.midlertidigbrukstillatelse,
                forersNavn: result.value
            }
        }));
    };

    private onForersFodselsdatoChange = (result: AkDateState) => {
        this.setState(prevState => ({
            formValidation: {
                ...prevState.formValidation,
                forersFodselsdato: !result.error
            },
            midlertidigbrukstillatelse: {
                ...prevState.midlertidigbrukstillatelse,
                forersFodselsdato: result.value
            }
        }));
    };

    private onStartDateTimeChange = (result: AkDateTimeState) => {
        let periode = { ...this.state.midlertidigbrukstillatelse.periode };
        let tidspunktAdvarsler = { ...this.state.tidspunktValidation };

        if (result.valueAsMoment.isValid()) {
            const mbtliste = this.props.midlertidigebrukstillatelser[Number(this.props.params.prkid)]?.liste || [];
            const nyFomTidspunkt = moment(result.value);
            const nyTomTidspunkt = moment(result.value).add(1, 'days').subtract(1, 'minutes');
            tidspunktAdvarsler = MidlertidigBrukstillatelseRegler.kontrollerKjoreseddelTidspunkt(mbtliste, nyFomTidspunkt, nyTomTidspunkt);
            periode = {
                fomTidspunkt: nyFomTidspunkt.format(ISO_8601_DATO_TID_FORMAT),
                tilTidspunkt: this.utledTilTidspunktKjoreseddel(result.value)
            };
        }

        this.setState(prevState => ({
            formValidation: {
                ...prevState.formValidation,
                fomTidspunkt: !result.error && !tidspunktAdvarsler.eksisterendeKortereEnnTiMin && !tidspunktAdvarsler.nyKortereEnnTiMin && !tidspunktAdvarsler.seddelEksistererAllerede
            },
            fomTidspunktErrorMessage: result.error,
            tidspunktValidation: tidspunktAdvarsler,
            midlertidigbrukstillatelse: {
                ...prevState.midlertidigbrukstillatelse,
                periode
            }
        }));

    };

    private strekningPakrevd = (formal: FormalEnum = undefined) => (
        (formal || this.state.midlertidigbrukstillatelse.formal) === FormalEnum.FLYTTING.valueOf() ||
        (formal || this.state.midlertidigbrukstillatelse.formal) === FormalEnum.REGISTRERING.valueOf()
    );

    private redirectToOversiktsbilde = () => {
        if (this.props.etterregistrerContext) {
            return this.props.navigate('/etterregistrer');
        }
        return this.props.navigate('/arsprovekjennemerker');
    };

    private isFullforknappDisabled = (): boolean => {
        const mbt = this.state.midlertidigbrukstillatelse;
        const historiskMbtMapEntry = this.props.midlertidigebrukstillatelser[mbt.prkid];

        return this.props.midlertidigbrukstillatelse.isLoading
            || (!!mbt.periode &&
                (EtterregistreringRegler.isStartDateTimeIdentiskMedStartDateTimeEksisterendeKjoreseddel(mbt, historiskMbtMapEntry?.liste || [])
                    || this.state.tidspunktValidation.eksisterendeKortereEnnTiMin)
                    || this.state.tidspunktValidation.nyKortereEnnTiMin);
    };

    private kanIkkeFullfore = (): boolean => {
        const mbt = this.state.midlertidigbrukstillatelse;
        const historiskMbtMapEntry = this.props.midlertidigebrukstillatelser[mbt.prkid];

        return this.state.disableInput
            || this.props.midlertidigbrukstillatelse.isLoading
            || (!!mbt.periode && EtterregistreringRegler.isStartDateTimeIdentiskMedStartDateTimeEksisterendeKjoreseddel(mbt, historiskMbtMapEntry?.liste || []));
    };

    private filtrerBortVarselmerknaderSomIkkeErRelevante(merknader: IMerknad[]): IMerknad[] {
        if (!this.props.etterregistrerContext) {
            return merknader;
        }

        return _.remove(merknader, merknad => _.some(MERKNADER_SOM_IKKE_SKAL_HINDRE_ETTERREGISTRERING, merknad.kode));
    }

    private filtrerBortInfomerknaderSomIkkeErRelevante(merknader: IMerknad[]): IMerknad[] {

        return _.remove(merknader, merknad => _.some(MERKNADER_SOM_IKKE_SKAL_VISES, merknad.kode));
    }

    private utledTilTidspunktKjoreseddel(fomTidspunkt: string): string {
        return this.props.etterregistrerContext
            ? EtterregistreringRegler.utledSluttidspunktForKjoreseddel(fomTidspunkt,
                this.props.midlertidigebrukstillatelser[this.props.params.prkid]?.liste)
            : MidlertidigBrukstillatelseRegler.utledSluttidspunktForKjoreseddel(fomTidspunkt,
                this.props.midlertidigebrukstillatelser[this.props.params.prkid]?.liste);
    }
}

const mapStateToProps = (state: RootStateType, ownProps): IKjoreseddelStateProps => ({
    // TODO: Lag eget komponent for etterregistrer
    etterregistrerContext: ownProps.etterregistrerContext,
    midlertidigbrukstillatelse: state.midlertidigbrukstillatelse,
    midlertidigebrukstillatelser: state.midlertidigebrukstillatelser,
    kjoretoy: state.singleKjoretoy,
    brukerprofil: state.brukerprofil,
    merknader: midlertidigBrukstillatelseOpprettFilter(state)
});

const mapDispatchToProps = (dispatch): IKjoreseddelDispatchProps => ({
    newMidlertidigBrukstillatelse: (midlertidigbrukstillatelse: IMidlertidigBrukstillatelse, isEtterregistreringContext: boolean) =>
        dispatch(newMidlertidigbrukstillatelse(midlertidigbrukstillatelse, isEtterregistreringContext)),
    getMidlertidigeBrukstillatelser: (prkid: number) => dispatch(getMidlertidigeBrukstillatelser(prkid, true)),
    stoppOgOpprettMidlertidigBrukstillatelse: (eksisterendeMbtId: number, newMbt: IMidlertidigBrukstillatelse, isEtterregistreringsContext: boolean) =>
        dispatch(stoppEksisterendeOgOpprettNyMidlertidigBrukstillatelse(eksisterendeMbtId, newMbt, isEtterregistreringsContext))
});

const KjoreseddelConnected = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Kjoreseddel);

export { Kjoreseddel, KjoreseddelConnected, FormalEnum };