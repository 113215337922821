import { MerkerActionKey } from '../../../constants';
import type { IHentMerkerAction } from '../../actions';

const initialState = {
    merker: []
};

export const merker = (state = initialState, action: IHentMerkerAction) => {
    switch (action.type) {
        case MerkerActionKey.HENT_MERKER_SUCCESS:
            return {
                ...state,
                merker: action.merker
            };

        case MerkerActionKey.HENT_MERKER_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};