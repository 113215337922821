import { KjoretoylisteActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError, IKjoretoy, RootStateType } from '../../../models/types';
import { createRestResource } from '../../store';

export type IKjoretoylisteAction =
    IFinnKjoretoySuccessAction |
    IKjoretoyFinnIsLoadingAction |
    IFinnKjoretoyErrorAction |
    IClearKjoretoylisteAction;


interface IFinnKjoretoySuccessAction {
    type: KjoretoylisteActionKey.KJORETOY_FIND_SUCCESS;
    kjoretoyliste: IKjoretoy[];
    state: RootStateType;
}

interface IClearKjoretoylisteAction {
    type: KjoretoylisteActionKey.KJORETOYLISTE_CLEAR;
    state: RootStateType;
}

interface IKjoretoyFinnIsLoadingAction {
    type: KjoretoylisteActionKey.KJORETOY_FIND_IS_LOADING;
    isLoading: boolean;
    state?: RootStateType;
}

interface IFinnKjoretoyErrorAction {
    type: KjoretoylisteActionKey.KJORETOY_FIND_ERROR;
    error: IError;
    state?: RootStateType;
}

const finnKjoretoySuccessAction = (kjoretoyliste: IKjoretoy[], state: RootStateType): IFinnKjoretoySuccessAction => ({
    type: KjoretoylisteActionKey.KJORETOY_FIND_SUCCESS,
    kjoretoyliste,
    state
});

const clearKjoretoylisteAction = (state: RootStateType): IClearKjoretoylisteAction => ({
    type: KjoretoylisteActionKey.KJORETOYLISTE_CLEAR,
    state
});

const kjoretoyFinnIsLoadingAction = (isLoading: boolean): IKjoretoyFinnIsLoadingAction => ({
    type: KjoretoylisteActionKey.KJORETOY_FIND_IS_LOADING,
    isLoading
});

const finnKjoretoyErrorAction = (error: IError): IFinnKjoretoyErrorAction => ({
    type: KjoretoylisteActionKey.KJORETOY_FIND_ERROR,
    error
});

const kjoretoyApi = createRestResource(AkrConfig.KJORETOY_RESOURCE_URL, PREFIX);

export function findKjoretoy(kjennemerke: string, understellsnummer: string) {
    return (dispatch, getState) => {
        dispatch(kjoretoyFinnIsLoadingAction(true));

        let requestObject = {};

        if (!!kjennemerke) {
            requestObject = {kjennemerke};
        } else if (!!understellsnummer) {
            requestObject = {understellsnummer};
        }

        return kjoretoyApi.get(null, requestObject).then(response => {

            if (response.errorId) {
                return dispatch(finnKjoretoyErrorAction(response));
            }

            dispatch(finnKjoretoySuccessAction(response.content, getState()));
        });
    };
}

export function clearKjoretoyliste() {
    return (dispatch, getState) => {
        dispatch(clearKjoretoylisteAction(getState()));
    };
}