import { KlientkonfigurasjonActionKey } from '../../constants';
import type { IKlientkonfigurasjonState, KlientkonfigurasjonActionType } from '../../models/types';

export function klientkonfigurasjon(state: IKlientkonfigurasjonState = {}, action: KlientkonfigurasjonActionType): IKlientkonfigurasjonState {
    switch (action.type) {
        case KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS:
            return {...state, isLoading: false, ...action.konfigurasjon};
        case KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_USIKRET_FETCH_DATA_SUCCESS:
            return {...state, isLoading: false, ...action.konfigurasjon};
        case KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        default:
            return state;
    }
}