import { KjoretoygruppeActionKey } from '../../../constants';
import type { KjoretoygruppeAction } from '../../actions';

const initialState = {
    kjoretoygrupper: [],
    isLoading: true,
    error: null
};

export const kjoretoygrupper = (state = initialState, action: KjoretoygruppeAction) => {
    switch (action.type) {
        case KjoretoygruppeActionKey.HENT_KJORETOYGRUPPE_SUCCESS:
            return {
                ...state,
                kjoretoygrupper: action.kjoretoygrupper
            };
        case KjoretoygruppeActionKey.HENT_KJORETOYGRUPPE_ERROR:
            return {
                ...state,
                error: action.error
            };
        default:
            return state;
    }
};