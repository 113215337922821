export enum Kjennemerkeserie {
    ARSPROVE = 'ARSPROVE'
}

export enum Registreringsstatus {
    TILDELT = 'TILDELT',
    REGISTRERT = 'REGISTRERT',
    AVREGISTRERT = 'AVREGISTRERT',
    VRAKET = 'VRAKET'
}

export enum KjennemerkeKategori {
    PROVE = 'PROVE'
}
