import { AkButton, AkMelding, AkMeldingboksType, ButtonType } from 'svv-tk-akr-common-frontend/dist/mobile/index';

import * as React from 'react';

import { focusOnMount } from '../../utils/focus-on-mount';

import './ak-confirmation-box-style';

interface IAkConfirmationBoxProps {
    message: string;
    confirmButtonIntlKey: string;
    cancelButtonIntlKey: string;
    confirmButtonAction: (event?: React.SyntheticEvent) => void;
    cancelButtonAction: () => void;
    confirmButtonValue?: any;
}

const AkConfirmationBoxComponent: React.FC<IAkConfirmationBoxProps> = props => {
    return (
        <div className="ak-confirmation-box col">
            <AkMelding meldingType={AkMeldingboksType.INFO} messageKey={props.message} />
            <div className="ak-button-row">
                <AkButton buttonIntlKey={props.confirmButtonIntlKey} action={props.confirmButtonAction} buttonType={ButtonType.MAIN} value={props.confirmButtonValue} />
                <AkButton buttonIntlKey={props.cancelButtonIntlKey} action={props.cancelButtonAction} buttonType={ButtonType.SECONDARY} customClassNames="mr-0" />
            </div>
        </div>
    );
};

const AkConfirmationBox = focusOnMount(AkConfirmationBoxComponent);
export { AkConfirmationBox, AkConfirmationBoxComponent };