import { ArsprovekjennemerkeType, KjennemerkeTypeEnum } from 'svv-tk-akr-common-frontend';

import { createSelector } from 'reselect';

import {
    Motorvogn1SkiltTekniskeKoder, NorskTekniskUnderkode, RegelmerknaderType, RegelmerknadKoder, RegelmerknadKontekst, Registreringsstatus, TilhengerSkilTekniskeKoder
} from '../../models/kodeverk';
import type { IArsprovekjennemerke, IKjoretoy, IMerknad, IMerknadFilterCollection, IMidlertidigBrukstillatelseMap, ISingleArsprovekjennemerke, RootStateType } from '../../models/types';
import { arsprovekjennemerkeSelector, midlertidigebrukstillatelserRapportSelector, midlertidigebrukstillatelserSelector } from './arsprovekjennemerke-selector';
import { kjoretoylisteSelector, singleKjoretoyAsKjoretoySelector } from './kjoretoy-selectors';

const merknaderSelector = (state: RootStateType) => state.merknader;

export function merknadSelector(state: RootStateType): IMerknad[] {
    let merknader = [];

    merknader = merknader.concat(NO_IMPORT_validateKjoretoylisteCompatibleWithProvekjennemerke(state));
    merknader = merknader.concat(NO_IMPORT_validateNewMidlertidigBrukstillatelse(state));
    merknader = merknader.concat(NO_IMPORT_validateMidlertidibrukstillatelserapport(state));

    return merknader;
}

export const provekjennemerkeVelgKjoretoyMerknadSelector = createSelector(
    merknaderSelector,
    (merknader): IMerknad[] => {
        return merknader.merknader.filter(m =>
            m.kontekst === RegelmerknadKontekst.PROVEKJENNEMERKE_VELG_KJORETOY.valueOf());
    }
);

export const midlertidigBrukstillatelseOpprettFilter = createSelector(
  merknaderSelector,
    (merknader): IMerknadFilterCollection => {
        const merknaderWithKontekst = merknader.merknader.filter(m =>
            m.kontekst === RegelmerknadKontekst.MIDLERTIDIG_BRUKSTILLATELSE_OPPRETT.valueOf());

        return {
            infomeldinger: merknaderWithKontekst.filter(m => m.type === RegelmerknaderType.INFORMASJON),
            varselmeldinger: merknaderWithKontekst.filter(m => m.type === RegelmerknaderType.KRITISK)
        };
    }
);

export const midlertidigBrukstillatelseRapportFilter = createSelector(
    merknaderSelector,
    (merknader): IMerknadFilterCollection => {
        const merknaderWithKontekst = merknader.merknader.filter(m =>
            m.kontekst === RegelmerknadKontekst.MIDLERTIDIGBRUKSTILLATELSE_RAPPORT_LAST_NED.valueOf());

        return {
            infomeldinger: [],
            varselmeldinger: merknaderWithKontekst.filter(m => m.type === RegelmerknaderType.KRITISK)
        };
    }
);

// eslint-disable-next-line
export const NO_IMPORT_validateKjoretoylisteCompatibleWithProvekjennemerke = createSelector(
    arsprovekjennemerkeSelector,
    kjoretoylisteSelector,
    (arsprovekjennemerke, kjoretoyliste) => {
        let merknader = [];

        kjoretoyliste.kjoretoyliste.forEach(kjoretoy => {
            merknader = merknader.concat(utledKjoretoyGruppeForArsprovekjennemerkeType(arsprovekjennemerke.arsprovekjennemerke, kjoretoy));
            merknader = merknader.concat(validateKjoretoyParegistrert(kjoretoy));
            merknader = merknader.concat(validateKjoretoyVraket(kjoretoy));
            merknader = merknader.concat(validateKjoretoyKontaktVegvesenet(kjoretoy));
        });

        const KONTEKST = RegelmerknadKontekst.PROVEKJENNEMERKE_VELG_KJORETOY;

        return merknader.filter(m => !!m).map(m => ({...m, kontekst: KONTEKST}));
    });

// eslint-disable-next-line
export const NO_IMPORT_validateMidlertidibrukstillatelserapport = createSelector(
    midlertidigebrukstillatelserRapportSelector,
    (mbtRapport) => {
        const merknader = [];

        if(!!mbtRapport.error) {
            merknader.push({
                kode: RegelmerknadKoder.MIDLERTIDIGBRUKSTILLATELSERAPPORT_NEDLASTNING_FEILET,
                kontekst: RegelmerknadKontekst.MIDLERTIDIGBRUKSTILLATELSE_RAPPORT_LAST_NED,
                type: RegelmerknaderType.KRITISK
            } as IMerknad);
        }

        return merknader;
    });

// eslint-disable-next-line
export const NO_IMPORT_validateNewMidlertidigBrukstillatelse = createSelector(
    midlertidigebrukstillatelserSelector,
    arsprovekjennemerkeSelector,
    singleKjoretoyAsKjoretoySelector,
    (midlertidigeBrukstillatelser, arsprovekjennemerke, singleKjoretoy) => {
        const infomeldinger = [
            utledAlleredeAktivMidlertidigbrukstillatelseForKjoretoy(midlertidigeBrukstillatelser, singleKjoretoy, arsprovekjennemerke),
            utledKjoretoyHarTekniskUnntakRally(singleKjoretoy),
            utledKjoretoyHarTekniskUnntakUtenforOffentligVeg(singleKjoretoy)
        ];


        const varselmeldinger = [
            validateKjoretoyParegistrert(singleKjoretoy),
            validateKjoretoyVraket(singleKjoretoy),
            validateKjoretoyKontaktVegvesenet(singleKjoretoy),
            utledArsprovekjennemerkeStatus(arsprovekjennemerke.arsprovekjennemerke),
            utledBruksforbudPaArsprovekjennemerke(arsprovekjennemerke.arsprovekjennemerke)
        ];

        const KONTEKST = RegelmerknadKontekst.MIDLERTIDIG_BRUKSTILLATELSE_OPPRETT;

        return [
            ...infomeldinger.filter(m => !!m).map(m => {
                return {...m, kontekst: KONTEKST, type: RegelmerknaderType.INFORMASJON};
            }),
            ...varselmeldinger.filter(m => !!m).map(m => {
                return {...m, kontekst: KONTEKST, type: RegelmerknaderType.KRITISK};
            })
        ];
    }
);

//AKRMOD-584
function utledKjoretoyGruppeForArsprovekjennemerkeType(provekjennemerke: IArsprovekjennemerke, kjoretoy: IKjoretoy): Partial<IMerknad> {
    if (!provekjennemerke) {
        return {
            kode: RegelmerknadKoder.IKKE_GYLDIG_TEKNISK_KODE_FOR_PROVEKJENNEMERKE,
            type: RegelmerknaderType.IKKE_UTLEDET
        };
    }

    switch(provekjennemerke.arsprovekjennemerketype) {
        case ArsprovekjennemerkeType.MOTORVOGN_2_SKILT:
            break;
        case ArsprovekjennemerkeType.MOTORVOGN_1_SKILT:
            if (Object.values(Motorvogn1SkiltTekniskeKoder).includes(kjoretoy.tekniskKode as Motorvogn1SkiltTekniskeKoder) || kjoretoy.tekniskUnderkode === NorskTekniskUnderkode) {
                break;
            } else {
                return {
                    kode: RegelmerknadKoder.IKKE_GYLDIG_TEKNISK_KODE_FOR_PROVEKJENNEMERKE,
                    id: kjoretoy.kuid
                };
            }
        case ArsprovekjennemerkeType.TILHENGER:
            if (Object.values(TilhengerSkilTekniskeKoder).includes(kjoretoy.tekniskKode as TilhengerSkilTekniskeKoder) || kjoretoy.tekniskUnderkode === NorskTekniskUnderkode) {
                break;
            } else {
                return {
                    kode: RegelmerknadKoder.IKKE_GYLDIG_TEKNISK_KODE_FOR_PROVEKJENNEMERKE,
                    id: kjoretoy.kuid
                };
            }
    }

    return null;
}

function validateKjoretoyParegistrert(kjoretoy: IKjoretoy): Partial<IMerknad> {
    if (kjoretoy.registreringsstatusKode === 'REGISTRERT') {
        return {
            kode: RegelmerknadKoder.KJORETOY_ER_PAREGISTRERT,
            id: kjoretoy.kuid
        };
    }
    return null;
}

function validateKjoretoyVraket(kjoretoy: IKjoretoy): Partial<IMerknad> {
    if (kjoretoy.registreringsstatusKode === 'VRAKET') {
        return {
            kode: RegelmerknadKoder.KJORETOY_ER_VRAKET,
            id: kjoretoy.kuid
        };
    }
    return null;
}

function validateKjoretoyKontaktVegvesenet(kjoretoy: IKjoretoy): Partial<IMerknad> {
    if (kjoretoy.kontaktVegvesenet) {
        return {
            kode: RegelmerknadKoder.KONTAKT_STATENS_VEGVESEN,
            id: kjoretoy.kuid
        };
    }
    return null;
}

function utledKjoretoyHarTekniskUnntakRally(kjoretoy: IKjoretoy): Partial<IMerknad> {
    if (kjoretoy?.kjennemerkeType === KjennemerkeTypeEnum.RALLY_TRIAL_ENDURO) {
        return {
            kode: RegelmerknadKoder.KJORETOY_RALLY
        };
    }
    return null;
}

function utledKjoretoyHarTekniskUnntakUtenforOffentligVeg(kjoretoy: IKjoretoy): Partial<IMerknad> {
    if (kjoretoy?.kjennemerkeType === KjennemerkeTypeEnum.SVALBARD ||
        kjoretoy?.kjennemerkeType === KjennemerkeTypeEnum.UTENFOR_OFFENTLIG_VEG) {
        return {
            kode: RegelmerknadKoder.KJORETOY_UTENFOR_OFFENTLIG_VEI
        };
    }
    return null;
}

function utledAlleredeAktivMidlertidigbrukstillatelseForKjoretoy(
    midlertidigeBrukstillatelser: IMidlertidigBrukstillatelseMap,
    kjoretoy: IKjoretoy,
    arsprovekjennemerke: ISingleArsprovekjennemerke): Partial<IMerknad> {
    if (!!arsprovekjennemerke.arsprovekjennemerke && !!midlertidigeBrukstillatelser[arsprovekjennemerke.arsprovekjennemerke.prkid]?.innvarendeMbt?.id) {
        return {
            kode: RegelmerknadKoder.MIDLERTIDIG_BRUKSTILLATELSE_AKTIV,
            id: `${midlertidigeBrukstillatelser[arsprovekjennemerke.arsprovekjennemerke.prkid]?.innvarendeMbt?.id}`
        };
    }
    return null;
}

//AKRMOD-581
function utledArsprovekjennemerkeStatus(arsprovekjennemerke: IArsprovekjennemerke): Partial<IMerknad> {
    const innevarendeApkjRegistrering = arsprovekjennemerke?.arsprovekjennemerkeRegistreringer.filter(apkj => {
        return apkj.status === Registreringsstatus.REGISTRERT && apkj.periode?.fomTidspunkt !== null && !apkj.periode?.tilTidspunkt;
    });

    if (innevarendeApkjRegistrering?.length === 0) {
        return {
            kode: RegelmerknadKoder.ARSPROVEKJENNEMERKE_AVREGISTRERT
        };
    }
    return null;
}

//AKRMOD-583
function utledBruksforbudPaArsprovekjennemerke(arsprovekjennemerke: IArsprovekjennemerke): Partial<IMerknad> {
    // TODO: Sjekke bruksforbud fra tjener
    if (!!arsprovekjennemerke && arsprovekjennemerke.harBruksforbud) {
        return {
            kode: RegelmerknadKoder.ARSPROVEKJENNEMERKE_BRUKSFORBUD
        };
    }
    return null;
}
