import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { AkHeader, AkHeaderLogo, AkNavigationMenu, AkNavItemExternal, AkNavItemInternal, useNavigationController } from 'svv-tk-akr-common-frontend';

import type { Brukerprofil, RootStateType } from '../../models/types';
import { useAppSelector } from '../../state/store';
import { UserSelect } from './user-select';

function getValgtEnhetId(brukerprofil: Brukerprofil) {
    return brukerprofil?.valgtEnhet?.underenhetOrgNummer !== '000000000' ? brukerprofil?.valgtEnhet?.underenhetOrgNummer : brukerprofil?.valgtEnhet?.hovedenhetOrgNummer;
}

export const Header: React.FC = () => {
    const isIntlLoaded = useAppSelector((state: RootStateType) => state.intl.isLoadedFromResource);
    const showBransjeLink = useAppSelector((state: RootStateType) => state.brukerprofil.bruker?.harTilgangTilBransje);
    const brukerprofil = useAppSelector((state: RootStateType) => state.brukerprofil);
    const valgtEnhetId = getValgtEnhetId(brukerprofil);
    const bransjeLink = useAppSelector((state: RootStateType) => `${state.klientkonfigurasjon?.linker?.forhandler}?enhetId=${valgtEnhetId}`);

    const isWorkplacesOpen = useAppSelector((state: RootStateType) => state.hovedmenyEkspandert);
    const navigationController = useNavigationController(brukerprofil);

    const calculateNavigationMenuBreakpoint = (): number => {
        if (showBransjeLink) {
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            return 884;
        }
        return undefined;
    };

    return (
        <AkHeader>
            <AkHeaderLogo logoLocation="/" logoLocationIntlDescriptor={{ id: 'hovedmeny.startside' }} />
            <AkNavigationMenu softClose={isWorkplacesOpen} orderLastAtBreakpoint={calculateNavigationMenuBreakpoint()} {...navigationController}>
                {isIntlLoaded && navigationController.showNavItems &&
                    <>
                        <AkNavItemInternal to="/arsprovekjennemerker" tooltip="hovedmeny.oversikt">
                            <FormattedMessage id="hovedmeny.oversikt" />
                        </AkNavItemInternal>
                        <AkNavItemInternal to="/arkiv" tooltip="hovedmeny.arkiv">
                            <FormattedMessage id="hovedmeny.arkiv" />
                        </AkNavItemInternal>
                        <AkNavItemInternal to="/etterregistrer" tooltip="hovedmeny.etterregistrer">
                            <FormattedMessage id="hovedmeny.etterregistrer" />
                        </AkNavItemInternal>
                    </>
                }
                {isIntlLoaded && showBransjeLink &&
                    <AkNavItemExternal to={bransjeLink} tooltip="hovedmeny.akr-bransje" newTabOrWindow={true}>
                        <FormattedMessage id="hovedmeny.akr-bransje" />
                    </AkNavItemExternal>
                }
                <UserSelect />
            </AkNavigationMenu>
            <UserSelect />
        </AkHeader>
    );
};