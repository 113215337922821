import { ISO_8601_DATO_TID_FORMAT } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import { sortBy } from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import { createSelector } from 'reselect';

import { Registreringsstatus } from '../../models/kodeverk';
import type { IArsprovekjennemerke, IProvekjennemerkeRegistrering, RootStateType } from '../../models/types';

export const arsprovekjennemerkerSelector = (state: RootStateType) => state.arsprovekjennemerker;
export const midlertidigebrukstillatelserSelector = (state: RootStateType) => state.midlertidigebrukstillatelser;
export const midlertidigebrukstillatelserRapportSelector = (state: RootStateType) => state.midlertidigebrukstillatelserapport;
export const arsprovekjennemerkeSelector = (state: RootStateType) => state.arsprovekjennemerke;

export const finnesFremtidigeBrukstillatelserSelector = (prkid: number) => createSelector(
    midlertidigebrukstillatelserSelector,
    (midlertidigbrukstillatelser) => {
        return !!midlertidigbrukstillatelser[prkid]?.liste
            && midlertidigbrukstillatelser[prkid].liste.some(mbt => moment(mbt.periode.fomTidspunkt).isAfter(moment()));
    }
);

export const fremtidigebrukstillatelserKronologiskSelector = (prkid: number) => createSelector(
    midlertidigebrukstillatelserSelector,
    (midlertidigbrukstillatelser) => {
        const fremtidigeBrukstillatelser = midlertidigbrukstillatelser[prkid]?.liste
            .filter(mbt => moment(mbt.periode.fomTidspunkt).isAfter(moment()));

        return sortBy(fremtidigeBrukstillatelser,
            [function(mbt) {
                return mbt.periode.fomTidspunkt;
            }]);
    });

export const arsprovekjennemerkerSortedByMidlertidigbrukstillatelseSelector = createSelector(
    arsprovekjennemerkerSelector,
    midlertidigebrukstillatelserSelector,
    (arsprovekjennemerker, midlertidigbrukstillatelser) => {
        const now = moment();
        return _.sortBy([...arsprovekjennemerker.arsprovekjennemerker].filter((arsprovekjennemerke: IArsprovekjennemerke) =>
            arsprovekjennemerke.arsprovekjennemerkeRegistreringer.some((arsprovekjennemerkeRegistrering: IProvekjennemerkeRegistrering) =>
                arsprovekjennemerkeRegistrering.status === Registreringsstatus.REGISTRERT
                && !!arsprovekjennemerkeRegistrering.periode?.fomTidspunkt
                && (!arsprovekjennemerkeRegistrering.periode?.tilTidspunkt || harTiltidspunktEtterGittTidspunkt(arsprovekjennemerkeRegistrering, now))
            )), (arsprovekjennemerke) =>
            !!midlertidigbrukstillatelser[arsprovekjennemerke.prkid]?.innvarendeMbt && !!midlertidigbrukstillatelser[arsprovekjennemerke.prkid].innvarendeMbt.id ? 1 : -1
        );
    }
);

export const arsprovekjennemerkerRegistrertDetSisteAretSelector = createSelector(
    arsprovekjennemerkerSelector,
    arsprovekjennemerker => {
        const etArSiden = moment().subtract(1, 'year');
        return [...arsprovekjennemerker.arsprovekjennemerker].filter((arsprovekjennemerke: IArsprovekjennemerke) =>
            arsprovekjennemerke.arsprovekjennemerkeRegistreringer.some((arsprovekjennemerkeRegistrering: IProvekjennemerkeRegistrering) =>
                arsprovekjennemerkeRegistrering.status === Registreringsstatus.REGISTRERT
                && !!arsprovekjennemerkeRegistrering.periode?.fomTidspunkt
                && (!arsprovekjennemerkeRegistrering.periode?.tilTidspunkt || harTiltidspunktEtterGittTidspunkt(arsprovekjennemerkeRegistrering, etArSiden))
            )
        );
    }
);

function harTiltidspunktEtterGittTidspunkt(arsprovekjennemerkeRegistrering: IProvekjennemerkeRegistrering, grensetidspunkt: Moment) {
    return moment(arsprovekjennemerkeRegistrering.periode?.tilTidspunkt, ISO_8601_DATO_TID_FORMAT) > grensetidspunkt;
}
