import type { Action } from 'redux';
import { ActionKey } from '../../../constants';
import type { IError } from '../../../models/types';

export interface IHovedmenyToggleEkspandertAction extends Action {
    seksjonEkspandert: boolean;
}

export function globalErrors(type: string, error: IError): object {
    return {
        type,
        error
    };
}

export function hovedmenySeksjonEkspandert(ekspandert: boolean): IHovedmenyToggleEkspandertAction {
    return {
        type: ActionKey.HOVEDMENY_SEKSJON_EKSPANDERT,
        seksjonEkspandert: ekspandert
    };
}

export function addGlobalErrors(error: IError): any {
    return dispatch => {
        dispatch(globalErrors(ActionKey.ADD_ERROR, error));
    };
}