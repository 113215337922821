import { compose } from 'redux';
import { AkButton, AkCard, AkCardHeader, AkNumberplate, ButtonType } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { LoadingWithErrorHandler } from '../../components';
import type { IArsprovekjennemerke, IArsprovekjennemerker, RootStateType } from '../../models/types';
import { getArsprovekjennemerker } from '../../state/actions';
import { arsprovekjennemerkerRegistrertDetSisteAretSelector } from '../../state/selectors';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';
import { ArsprovekjennemerkeTypeClassNames } from '../arsprovekjennemerker';

interface IArsprovekjennemerkeEtterregistreringStateProps {
    arsprovekjennemerker: IArsprovekjennemerker;
    filteredArsprovekjennemerker: IArsprovekjennemerke[];
}

interface IArsprovekjennemerkeEtterregistreringDispatchProps {
    getArsprovekjennemerker: () => Promise<any>;
}

export type ArsprovekjennemerkeEtterregistrerPropsType = IArsprovekjennemerkeEtterregistreringStateProps & IArsprovekjennemerkeEtterregistreringDispatchProps & WithRouterProps;

class ArsprovekjennemerkeEtterregistrer extends React.Component<ArsprovekjennemerkeEtterregistrerPropsType, null> {

    public componentDidMount() {
        this.props.getArsprovekjennemerker();
    }

    public render() {
        return (
            <>
                <header className="ak-heading">
                    <FormattedMessage id="etterregistrer.oversikt.title" tagName="h1" />
                </header>
                <section className="ak-innhold">
                    <LoadingWithErrorHandler customClassNames="col-12 text-center" {...this.props.arsprovekjennemerker}
                        customMessageExpression={this.props.arsprovekjennemerker.arsprovekjennemerker.length === 0}
                        customMessageIntlKey="arsprovekjennemerker.merknader.ingen-apkj"
                        isLoading={this.props.arsprovekjennemerker.isLoading}>
                        {this.props.filteredArsprovekjennemerker.map(this.renderArsprovekjennemerke)}
                    </LoadingWithErrorHandler>
                </section>
            </>
        );
    }

    private renderArsprovekjennemerke = (arsprovekjennemerke: IArsprovekjennemerke): React.ReactNode => {
        return (
            <AkCard key={String(arsprovekjennemerke.prkid)} customClassNames="col-12 col-md-4 mb-3 text-center arsprovekjennemerke-card etterregistrering-card">
                <div className="ak-kjoreseddel-content">
                    <AkCardHeader customClassNames="row justify-content-between align-items-center">
                        <p>
                            <i className={`ak-icon ${ArsprovekjennemerkeTypeClassNames[arsprovekjennemerke.arsprovekjennemerketype]}`} />
                            <FormattedMessage id={`arsprovekjennemerker.arsprovekjennemerketype.${arsprovekjennemerke.arsprovekjennemerketype}`} />
                        </p>
                    </AkCardHeader>
                    <AkNumberplate arsprovekjennemerketype={arsprovekjennemerke.arsprovekjennemerketype}
                        kjennemerketype={arsprovekjennemerke.provekjennemerketype}
                        tegnkombinasjon={arsprovekjennemerke.kjennemerker[0].tegnkombinasjon.visning || ''} />
                    <div className="ak-button-row">
                        <AkButton buttonIntlKey="etterregistrer.knapp.go-to-etterregistrering"
                            buttonType={ButtonType.MAIN}
                            value={arsprovekjennemerke.prkid}
                            action={this.goToEtterregistrering} />
                    </div>
                </div>
            </AkCard>
        );
    };

    private goToEtterregistrering = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        this.props.navigate(`/arsprovekjennemerker/${event.currentTarget.value}/etterregistrer/kjoretoysok/`);
    };
}

const mapStateToProps = (state: RootStateType): IArsprovekjennemerkeEtterregistreringStateProps => ({
    arsprovekjennemerker: state.arsprovekjennemerker,
    filteredArsprovekjennemerker: arsprovekjennemerkerRegistrertDetSisteAretSelector(state)

});

const mapDispatchToProps = (dispatch): IArsprovekjennemerkeEtterregistreringDispatchProps => ({
    getArsprovekjennemerker: () => dispatch(getArsprovekjennemerker(true))
});

const ArsprovekjennemerkerEtterregistrerConnected = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps))
(ArsprovekjennemerkeEtterregistrer);
export { ArsprovekjennemerkerEtterregistrerConnected, ArsprovekjennemerkeEtterregistrer };