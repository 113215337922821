
export enum AkrConfig {
    ARSPROVEKJENNEMERKE_RESOURCE_URL = '/provekjennemerker',
    BRUKERPROFIL_RESOURCE_URL = '/sikkerhet/brukerprofil',
    BRUKERPROFIL_AVDELING_RESOURCE_URL = '/sikkerhet/brukerprofil/akr-kjoreseddel',
    FEILMELDINGSLOGGER_RESOURCE_URL = '/feilmeldingslogger',
    INTL_RESOURCE_URL = '/intl',
    KJORETOY_RESOURCE_URL = '/kjoretoy',
    KODEVERK_RESOURCE_URL = '/kodeverk/kjoretoygrupper',
    KLIENTKONFIGURASJON_USIKRET_RESOURCE_URL = '/klientkonfigurasjon/usikret',
    KLIENTKONFIGURASJON_RESOURCE_URL = '/klientkonfigurasjon',
    MERKER_RESOURCE_URL = '/merker',
    MIDLERTIDIG_BRUKSTILLATELSER_RESOURCE_URL = '/midlertidigbrukstillatelser',
    MIDLERTIDIG_BRUKSTILLATELSE_RAPPORTER_RESOURCE_URL = '/midlertidigbrukstillatelserapporter',
    FUNKSJONSBRYTERE_RESOURCE_URL = '/funksjonsbrytere'
}

export const PREFIX= '/resource';
