import { AkNumberplate } from 'svv-tk-akr-common-frontend/dist/mobile/index';

import * as React from 'react';
import type { IFremmedKjoretoy, IKjoretoy } from '../../models/types';
import { isRegistrertUregistrertKjoretoy } from '../../type-guards';

import './kjoretoy-info-style';

export interface IKjoretoyInfoProps {
    kjoretoy: IKjoretoy | IFremmedKjoretoy;
}

export const KjoretoyInfo: React.FC<IKjoretoyInfoProps> = props => {
    if (isRegistrertUregistrertKjoretoy(props.kjoretoy)) {
        if(!!props.kjoretoy.kjennemerke) {
            return (
                <>
                    <AkNumberplate
                        kjennemerketype={props.kjoretoy.kjennemerkeType}
                        tegnkombinasjon={props.kjoretoy.kjennemerke} />
                    <ul>
                        <li>{props.kjoretoy.understellsnummer}</li>
                        <li>{props.kjoretoy.kjoretoyklassifiseringBeskrivelse}</li>
                        <li className="ak-handelsbetegnelse">
                            {`${props.kjoretoy.merke} ${props.kjoretoy.handelsbetegnelse}`}<br />
                            {`(${props.kjoretoy.registreringsar}) ${props.kjoretoy.karosserifarge || ''}`}
                        </li>
                    </ul>
                </>
            );
        }

        return (
            <>
                <AkNumberplate
                    kjennemerketype={null}
                    tegnkombinasjon={props.kjoretoy.understellsnummer} />
                <ul>
                    <li>{props.kjoretoy.kjoretoyklassifisering}</li>
                    <li className="ak-handelsbetegnelse">
                        {`${props.kjoretoy.merke} ${props.kjoretoy.handelsbetegnelse}`}<br />
                        {!!props.kjoretoy.registreringsar && `(${props.kjoretoy.registreringsar})`} {`${props.kjoretoy.karosserifarge || ''}`}
                    </li>
                </ul>
            </>
        );
    }

    return (
        <ul className="ak-fremmed-kjoretoy-info">
            <li>{props.kjoretoy.fremmedKjoretoyUnderstellsnr}</li>
            <li>{props.kjoretoy.fremmedKjoretoyTekniskKodeBeskrivelse}</li>
            <li className="ak-handelsbetegnelse">
                {`${props.kjoretoy.fremmedKjoretoyMerke} ${props.kjoretoy.fremmedKjoretoyHandelsbetegnelse || ''}`}<br />
                {!!props.kjoretoy.fremmedKjoretoyRegistreringsar && `(${props.kjoretoy.fremmedKjoretoyRegistreringsar})`}
            </li>
        </ul>
    );
};