import { ArsprovekjennemerkeActionKey } from '../../../constants';
import type { ISingleArsprovekjennemerke } from '../../../models/types';
import type { IArsprovekjennemerkeAction } from '../../actions/arsprovekjennemerke/arsprovekjennemerke-actions';

const initialState: ISingleArsprovekjennemerke = {
    arsprovekjennemerke: null,
    isLoading: false
};

export const arsprovekjennemerke = (state = initialState, action: IArsprovekjennemerkeAction): ISingleArsprovekjennemerke => {
    switch (action.type) {
        case (ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_IS_LOADING):
            return {...state, isLoading: true};
        case (ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_GET_SUCCESS):
            return {...state, arsprovekjennemerke: action.provekjennemerke, isLoading: false};
        case (ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_GET_ERROR):
            return {...state, error: action.error, isLoading: false};
        default:
            return state;
    }
};
