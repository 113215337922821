import { ISO_8601_DATO_TID_FORMAT } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import moment from 'moment';
import { RegelmerknadKoder } from '../../models/kodeverk';

import type { IMidlertidigBrukstillatelse } from '../../models/types';

export const MERKNADER_SOM_IKKE_SKAL_HINDRE_ETTERREGISTRERING = [
    RegelmerknadKoder.KJORETOY_ER_PAREGISTRERT,
    RegelmerknadKoder.ARSPROVEKJENNEMERKE_AVREGISTRERT,
    RegelmerknadKoder.KONTAKT_STATENS_VEGVESEN,
    RegelmerknadKoder.KJORETOY_ER_VRAKET
];

export const MERKNADER_SOM_IKKE_SKAL_VISES = [
    RegelmerknadKoder.MIDLERTIDIG_BRUKSTILLATELSE_AKTIV
];

class EtterregistreringRegler {

    // AUTKJOR 4052 AK 26 - Slutt tidspunkt for valgte endres til start tidspunkt for historisk
    public static isSluttDateTimeInnenfor24TimerFraStartDateTimeEksisterendeKjoreseddel(starttid: string, historiskeMbter: IMidlertidigBrukstillatelse[]) {
        return _.some(historiskeMbter, (historiskMbt: IMidlertidigBrukstillatelse) => {
            const valgtStartDateTime = moment(starttid, ISO_8601_DATO_TID_FORMAT);
            const valgtSluttDateTime = valgtStartDateTime.clone().add(1, 'd');
            const mbtStartDateTime = moment(historiskMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT);
            const mbtSluttDateTime = moment(historiskMbt.periode.tilTidspunkt ? historiskMbt.periode.tilTidspunkt : mbtStartDateTime.clone().add(1, 'd'), ISO_8601_DATO_TID_FORMAT);

            const valgtSluttIsBetweenMbt = valgtSluttDateTime.isBetween(mbtStartDateTime, mbtSluttDateTime, 'seconds', '()');
            const mbtPeriodeInniValgtPeriode = (valgtStartDateTime.isBefore(mbtStartDateTime) && valgtSluttDateTime.isAfter(mbtSluttDateTime));

            return valgtSluttIsBetweenMbt || mbtPeriodeInniValgtPeriode;
        });
    }
    // AUTKJOR 4052 AK 29B - Slutt tidspunkt for historisk endres til start tidspunkt for valgte
    public static isStartDateTimeInnenfor24TimerFraSluttDateTimeEksisterendeKjoreseddel(etterregMbt: IMidlertidigBrukstillatelse, historiskeMbter: IMidlertidigBrukstillatelse[]) {
        return _.some(historiskeMbter, (historiskMbt: IMidlertidigBrukstillatelse) => {
            const valgtStartDateTime = moment(etterregMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT);
            const mbtStartDateTime = moment(historiskMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT);
            const mbtSluttDateTime = moment(historiskMbt.periode.tilTidspunkt, ISO_8601_DATO_TID_FORMAT);

            return valgtStartDateTime.isBetween(mbtStartDateTime, mbtSluttDateTime, 'minutes', '()');
        });
    }

    public static finnHistoriskMtbSomFarEndretSluttDateTime(etterregMbtFomDateTime: string, historiskeMbter: IMidlertidigBrukstillatelse[]) {
        return _.find(historiskeMbter, (historiskMbt: IMidlertidigBrukstillatelse) =>
            moment(etterregMbtFomDateTime, ISO_8601_DATO_TID_FORMAT)
                .isBetween(moment(historiskMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT), moment(historiskMbt.periode.tilTidspunkt, ISO_8601_DATO_TID_FORMAT), 'minutes', '()'));
    }

    // AUTKJOR 4052 AK 28 - Sperre for etterregistrering
    public static isStartDateTimeIdentiskMedStartDateTimeEksisterendeKjoreseddel(etterregMbt: IMidlertidigBrukstillatelse, historiskeMbter: IMidlertidigBrukstillatelse[]) {
        return _.some(historiskeMbter, (historiskMbt: IMidlertidigBrukstillatelse) => {
            const valgtStartDateTime = moment(etterregMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT);
            const mbtStartDateTime = moment(historiskMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT);

            return mbtStartDateTime.isSame(valgtStartDateTime, 'minutes');
        });
    }

    public static utledSluttidspunktForKjoreseddel(fomTidspunkt: string, historiskeMbter: IMidlertidigBrukstillatelse[]): string {
        if (!fomTidspunkt) {
            return '';
        }

        const fomDateTimeNyMbt = moment(fomTidspunkt, ISO_8601_DATO_TID_FORMAT);
        const tilDateTimeNyMbt = fomDateTimeNyMbt.parseZone().clone().add(1, 'days').subtract(1, 'minutes').format(ISO_8601_DATO_TID_FORMAT);

        if (!!historiskeMbter
            && this.isSluttDateTimeInnenfor24TimerFraStartDateTimeEksisterendeKjoreseddel(fomDateTimeNyMbt.format(ISO_8601_DATO_TID_FORMAT), historiskeMbter)) {
            const historiskMbtMedStartIPeriode = _.find(historiskeMbter, (historiskMbt: IMidlertidigBrukstillatelse) =>
                moment(historiskMbt.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT).isBetween(fomDateTimeNyMbt, tilDateTimeNyMbt, 'seconds', '()')
            );
            return historiskMbtMedStartIPeriode?.periode.fomTidspunkt ? historiskMbtMedStartIPeriode.periode.fomTidspunkt : fomDateTimeNyMbt.format(ISO_8601_DATO_TID_FORMAT);
        }
        return tilDateTimeNyMbt;
    }
}

export { EtterregistreringRegler };
