import { useNavigate } from 'react-router-dom';
import { AkButton, ButtonType } from 'svv-tk-akr-common-frontend/dist/mobile/index';

import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import type { IPeriode } from '../../models/types';
import { fremtidigebrukstillatelserKronologiskSelector } from '../../state/selectors';
import { useAppSelector } from '../../state/store';

export const MANED_VISNING_DATO_TID_FORMAT = 'DD.MMM HH.mm';

interface IKjoreseddeltabellProps {
    prkid: number;
}

export const formaterPeriode = (periode: IPeriode) => {
    return (
        <p className="kjoreseddel-periode-format">
            <span className="kjoreseddel-tidspunkt-format">
                {moment(periode.fomTidspunkt).format(MANED_VISNING_DATO_TID_FORMAT)} -
            </span>
            <span>
                {moment(periode.tilTidspunkt).format(MANED_VISNING_DATO_TID_FORMAT)}
            </span>
        </p>
    );
};

const Kjoreseddeltabell: React.FC<IKjoreseddeltabellProps> = props => {

    const visAlleGrense = 2;
    const navigate = useNavigate();
    const [visAlle, setVisAlle] = React.useState<boolean>(false);

    const fremtidigeBrukstillatelserKronologisk = useAppSelector(fremtidigebrukstillatelserKronologiskSelector(props.prkid));

    const toggleAlleKjoresedler = () => {
        setVisAlle(!visAlle);
    };

    const view = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        navigate(`/kjoreseddel/${event.currentTarget.value}`);
    };

    const visKjoresedlerKronologisk = () => {
        return visAlle ? fremtidigeBrukstillatelserKronologisk : fremtidigeBrukstillatelserKronologisk.slice(0, visAlleGrense);
    };

    return (
        <div className="kjoreseddel-oversikt-card">
            <div className="kjoreseddel-oversikt-overskrift">
                <FormattedMessage id="arsprovekjennemerker.planlagte-kjoresedler" />
            </div>
            <ul>
                {visKjoresedlerKronologisk().map((mbt) =>
                    <li key={mbt.id}>
                        <div className="row">
                            <div className="col-8 kjoreseddel-oversikt-dato">
                                {formaterPeriode(mbt.periode)}
                            </div>
                            <div className="col-4 kjoreseddel-oversikt-apne-knapp">
                                <AkButton buttonIntlKey="arsprovekjennemerker.button.view" buttonType={ButtonType.MAIN} value={mbt.id} action={view} />
                            </div>
                        </div>
                    </li>
                )}
            </ul>
            {fremtidigeBrukstillatelserKronologisk?.length > visAlleGrense &&
            <AkButton
                buttonType={ButtonType.LINK}
                buttonIntlKey={visAlle ? 'arsprovekjennemerker.button.vis-farre' : 'arsprovekjennemerker.button.vis-alle'}
                buttonIntlValues={{antall: fremtidigeBrukstillatelserKronologisk.length}}
                action={toggleAlleKjoresedler}
                icon={visAlle ? 'fa-angle-up' : 'fa-angle-down'}
                iconPlacementEnd={true} />
            }
        </div>
    );
};

export { Kjoreseddeltabell };