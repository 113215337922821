import { KjoretoylisteActionKey } from '../../../constants';
import type { IKjoretoylisteAction } from '../../actions';

const initialState = {
    kjoretoyliste: [],
    error: null,
    isLoading: false
};

export const kjoretoyliste = (state = initialState, action: IKjoretoylisteAction) => {
    switch (action.type) {
        case (KjoretoylisteActionKey.KJORETOY_FIND_IS_LOADING):
            return {
                ...initialState,
                isLoading: action.isLoading
            };
        case (KjoretoylisteActionKey.KJORETOY_FIND_ERROR):
            return {
                ...state,
                isLoading: false,
                kjoretoyliste: [],
                error: action.error
            };
        case (KjoretoylisteActionKey.KJORETOY_FIND_SUCCESS):
            return {
                ...state,
                isLoading: false,
                kjoretoyliste: action.kjoretoyliste
            };
        case (KjoretoylisteActionKey.KJORETOYLISTE_CLEAR):
            return {
                ...state,
                isLoading: false,
                kjoretoyliste: []
            };
        default:
            return state;
    }
};