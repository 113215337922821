/* eslint-disable max-len */

const FEILHANDTERING_MELDING_PREFIX = 'En intern feil har oppstått og er logget med id:';

const INITIAL_STATE_MESSAGES = {
    'generell.laster': 'Laster...',

    'toppmeny.link.akrBransje': 'AKR BRANSJE',

    'hovedmeny.startside': 'Startside',

    'toppmeny.responsive-menu.toggler.hidden': 'Meny',
    'toppmeny.responsive-menu.toggler.visible': 'Lukk',
    'toppmeny.seksjon.brukerprofil.logg-ut': 'Logg ut',
    'toppmeny.seksjon.brukerprofil.overskrift': 'Velg avdeling',

    'sokefelt.kjoretoy.label': 'Kjøretøy',
    'sokefelt.kjoretoy.placeholder': 'Søk på kjennemerke/understellsnummer',

    'footer.vegvesen.epost': 'brukerstotte@vegvesen.no',
    'footer.vegvesen.telefon.melding': 'Telefon: ',
    'footer.vegvesen.telefon.nummer': '74 12 20 13',
    'footer.melding': 'For brukerstøtte - ta kontakt med Statens vegvesen: ',
    'footer.vegvesen.epost.melding': 'Epost: ',

    'feilhandtering.generell.ikke.kontakt': 'Oops.. fikk ikke kontakt med server',
    'feilhandtering.generell.overskrift': 'Teknisk feil',
    'feilhandtering.generell.feil': 'Her gikk noe galt... Oppgi feilkode {errorId}',
    'feilhandtering.generell.link.gatil': 'Gå til oversikten',
    'feilhandtering.generell.link.redirectToLogin': 'Gå til innlogging',
    'feilhandtering.generell.brukerprofil.ingen-avdelinger': 'Du er ikke tilknyttet en organisasjon i Altinn.',
    'feilhandtering.kode.ingen.kode': 'Au da, dette gikk ikke så bra. En ukjent feil har oppstått. Sjekk internettforbindelsen og prøv igjen!',
    'feilhandtering.kode.ukjent.kode': 'En intern feil har oppstått. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.common.app.00000': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00001': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00002': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00003': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00004': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00005': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00006': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00007': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00008': `${FEILHANDTERING_MELDING_PREFIX} {errorId}`,
    'feilhandtering.kode.akr.common.app.00012': 'Du har mistet tilgang til organisasjonen som du var innlogget på. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.common.sys.00001': 'Integrasjon med en eller flere systemer feilet og din registrerte bruker kan ikke hentes. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.integration.sys.00001': 'Integrasjon med en eller flere systemer feilet og din registrerte bruker kan ikke hentes. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.integration.sys.00014': 'Integrasjon med en eller flere systemer feilet og din registrerte bruker kan ikke hentes. Prøv igjen. Feilen er logget med id: {errorId}',
    'feilhandtering.kode.akr.register.app.00017': 'Kommunikasjon mot Personlig kjennemerke feilet. Prøv igjen senere. Feilen er logget med id: {errorId}.'
};

export default INITIAL_STATE_MESSAGES;