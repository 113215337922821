import { BrukerprofilActionKey } from '../../../constants';
import { AkrConfig } from '../../../constants/akr-config';
import type { Brukerprofil, IError, IKlientkonfigurasjonState } from '../../../models/types';
import { createRestResource } from '../../store';

export type BrukerprofilActionType =
    | IBrukerprofilHasServerErrorAction
    | IBrukerprofilHasErrorAction
    | IBrukerprofilArbeidsstedOppdatertAction
    | IBrukerprofilIsLoadingAction
    | IBrukerprofilIsUnauthorized
    | IBrukerprofilFetchDataSuccess
    | IBrukerprofilOtherAction;

export interface IBrukerprofilHasErrorAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_HAS_ERROR;
    hasError: boolean;
}

export interface IBrukerprofilIsUnauthorized {
    type: BrukerprofilActionKey.BRUKERPROFIL_IS_UNAUTHORIZED;
    isUnauthorized: boolean;
    error: IError;
}

export interface IBrukerprofilHasServerErrorAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_HAS_SERVER_ERROR;
    error: IError;
}

export interface IBrukerprofilArbeidsstedOppdatertAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_ARBEIDSSTED_OPPDATERT;
    arbeidssted: string;
}

export interface IBrukerprofilIsLoadingAction {
    type: BrukerprofilActionKey.BRUKERPROFIL_IS_LOADING;
    isLoading: boolean;
}

export interface IBrukerprofilFetchDataSuccess {
    type: BrukerprofilActionKey.BRUKERPROFIL_FETCH_DATA_SUCCESS;
    brukerprofil: Brukerprofil;
}

export interface IBrukerprofilOtherAction {
    type: BrukerprofilActionKey.OTHER_ACTION;
}

const api = createRestResource(AkrConfig.BRUKERPROFIL_RESOURCE_URL);

export const brukerprofilHasServerError = (error: IError): IBrukerprofilHasServerErrorAction => ({type: BrukerprofilActionKey.BRUKERPROFIL_HAS_SERVER_ERROR, error});
export const brukerprofilIsUnauthorized = (isUnauthorized: boolean, error: IError): IBrukerprofilIsUnauthorized => ({type: BrukerprofilActionKey.BRUKERPROFIL_IS_UNAUTHORIZED, isUnauthorized, error});
export const brukerprofilIsLoading = (isLoading: boolean): IBrukerprofilIsLoadingAction => ({type: BrukerprofilActionKey.BRUKERPROFIL_IS_LOADING, isLoading});
export const brukerprofilFetchDataSuccess = (brukerprofil: Brukerprofil): IBrukerprofilFetchDataSuccess => ({
    type: BrukerprofilActionKey.BRUKERPROFIL_FETCH_DATA_SUCCESS,
    brukerprofil
});

function handleBrukerprofilUpdate(promise, dispatch) {
    return promise
        .then(response => {
            if (response.unauthorized) {
                return dispatch(brukerprofilIsUnauthorized(true, response));
            }
            if (response.errorId) {
                return dispatch(brukerprofilHasServerError(response));
            }

            return dispatch(brukerprofilFetchDataSuccess(response));
        });
}

export function brukerprofilGetData() {
    return (dispatch) => {
        dispatch(brukerprofilIsLoading(true));
        return handleBrukerprofilUpdate(api.get(), dispatch);
    };
}

export function redirectToLogin(klientkonfigurasjon: IKlientkonfigurasjonState) {
    const {origin, pathname, href} = window.location;

    const redirect = origin.concat(pathname, 'api/sikkerhet/login?origin=', href);
    const openAmWithRedirect = klientkonfigurasjon.linker.openamLogin.replace('{redirect}', redirect);

    window.location.replace(encodeURI(openAmWithRedirect));
}
