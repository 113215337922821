import { KlientkonfigurasjonActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type {
    IKlientkonfigurasjonFetchDataSuccessAction, IKlientkonfigurasjonHasErrorAction, IKlientkonfigurasjonLoadingAction, IKlientkonfigurasjonUsikretFetchDataSuccessAction
} from '../../../models/types';

import { createRestResource } from '../../store';

const klientkonfigurasjonIsLoading = (isLoading: boolean): IKlientkonfigurasjonLoadingAction => ({
    type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_IS_LOADING,
    isLoading
});

const klientkonfigurasjonHasError = (): IKlientkonfigurasjonHasErrorAction => ({type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_HAS_ERROR});

const klientkonfigurasjonFetchDataSuccess = (konfigurasjon: object): IKlientkonfigurasjonFetchDataSuccessAction => ({
    type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS, konfigurasjon
});

const klientkonfigurasjonUsikretFetchDataSuccess = (konfigurasjon: object): IKlientkonfigurasjonUsikretFetchDataSuccessAction => ({
    type: KlientkonfigurasjonActionKey.KLIENTKONFIGURASJON_USIKRET_FETCH_DATA_SUCCESS, konfigurasjon
});

const usikretApi = createRestResource(AkrConfig.KLIENTKONFIGURASJON_USIKRET_RESOURCE_URL, PREFIX);
const api = createRestResource(AkrConfig.KLIENTKONFIGURASJON_RESOURCE_URL, PREFIX);

export function klientkonfigurasjonUsikretGetData() {
    return (dispatch) => {
        dispatch(klientkonfigurasjonIsLoading(true));
        return usikretApi.get().then(response => {
            return dispatch(klientkonfigurasjonUsikretFetchDataSuccess(response));
        });
    };
}

export function klientkonfigurasjonGetData() {
    return (dispatch) => {
        dispatch(klientkonfigurasjonIsLoading(true));
        return api.get()
            .then(response => {
                if (response.errorId) {
                    dispatch(klientkonfigurasjonHasError());
                    return dispatch(() => (response));
                }

                return dispatch(klientkonfigurasjonFetchDataSuccess(response));
            });
    };
}
