import { ProvekjennemerkerActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError } from '../../../models/types';
import type { IArsprovekjennemerke } from '../../../models/types/arsprovekjennemerke';
import { createRestResource } from '../../store';

export type IProvekjennemerkerAction =
    IGetProvekjennemerkerSuccessAction | IGetProvekjennemerkerErrorAction | IGetProvekjennemerkerEmptyAction | IGetProvekjennemerkerIsLoadingAction;

interface IGetProvekjennemerkerSuccessAction {
    type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_SUCCESS;
    arsprovekjennemerker: IArsprovekjennemerke[];
}

interface IGetProvekjennemerkerIsLoadingAction {
    type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_IS_LOADING;
    isLoading: boolean;
}

interface IGetProvekjennemerkerEmptyAction {
    type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_EMPTY;
}

interface IGetProvekjennemerkerErrorAction {
    type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_ERROR;
    error: IError;
}

const getProvekjennemerkerSuccessAction = (arsprovekjennemerker: IArsprovekjennemerke[]): IGetProvekjennemerkerSuccessAction => ({
    type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_SUCCESS,
    arsprovekjennemerker
});
const getProvekjennemerkerIsLoadingAction = (isLoading: boolean): IGetProvekjennemerkerIsLoadingAction => ({type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_IS_LOADING, isLoading});
const getProvekjennemerkerEmptyAction = (): IGetProvekjennemerkerEmptyAction => ({type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_EMPTY});
const getProvekjennemerkerErrorAction = (error: IError): IGetProvekjennemerkerErrorAction => ({type: ProvekjennemerkerActionKey.PROVEKJENNEMERKER_GET_ERROR, error});

const arsprovekjennemerkeApi = createRestResource(AkrConfig.ARSPROVEKJENNEMERKE_RESOURCE_URL, PREFIX);

export function getArsprovekjennemerker(historikk?: boolean) {
    return dispatch => {
        dispatch(getProvekjennemerkerIsLoadingAction(true));
        return arsprovekjennemerkeApi.get(null, {historikk}).then(response => {
            if (response.errorId) {
                return dispatch(getProvekjennemerkerErrorAction(response));
            }

            if (response.totalCount === 0) {
                return dispatch(getProvekjennemerkerEmptyAction());
            }

            dispatch(getProvekjennemerkerSuccessAction(response.content));
        });
    };
}