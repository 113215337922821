import { createSelector } from 'reselect';
import type { RootStateType } from '../../models/types';

const merkerSelector = (state: RootStateType) => state.merker?.merker;

export const merkerAsSelectOptionsSelector = createSelector(
    merkerSelector,
    merker => (
        merker.map(merke =>
            ({
                description: merke.navn,
                value: merke.kode
            }))
    )
);