import classNames from 'classnames';
import * as React from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import type { MeldingType } from '../../models/kodeverk/melding-bar-enums';
import type { IMelding } from '../../models/types/melding-bar';
import { focusOnMount } from '../../utils/focus-on-mount';

interface IMeldingProps {
    melding: IMelding;
    fjern: (melding: IMelding) => any;
    fjernAlleMeldingerAvType: (meldingType: MeldingType) => any;
}

const ANIMATION_TIMEOUT_MS = 50;

class MeldingBarMelding extends React.Component<IMeldingProps & WrappedComponentProps, {animateCSS: string}> {

    public state = {
        animateCSS: ''
    };

    public componentDidMount() {
        setTimeout(() => {
            this.setState({animateCSS: 'ak-melding-bar-melding-aktiv'});
        }, ANIMATION_TIMEOUT_MS);
    }

    public render() {
        const {melding} = this.props;

        const buttonLabel = this.props.intl.formatMessage({id: 'melding.lukk'});
        const meldingCss = classNames('ak-melding-bar-melding', `ak-melding-bar-${melding.meldingType}`, this.state.animateCSS);

        return (
            <li className={meldingCss}>
                <i className="ak-melding-ikon fa mr-3"/>
                {melding.meldingIntlId &&
                <p className="ak-melding-bar-melding-innhold">
                    <FormattedMessage id={melding.meldingIntlId} values={{...melding.meldingArgs}} />
                    {melding.errorId && <FormattedMessage id={'feilhandtering.errorId'} values={{errorId: melding.errorId}} />}
                </p>
                }
                {melding.meldingText}
                {melding.link &&
                    <Link className="ml-auto" to={melding.link} onClick={this.fjernAlleMeldingerAvType}>Les mer</Link>
                }
                <button aria-label={buttonLabel} onClick={this.slett} className="ak-melding-bar-melding-fjern ak-knapp fa fa-lg fa-times" />
            </li>
        );
    }

    private fjernAlleMeldingerAvType = () => {
        this.props.fjernAlleMeldingerAvType(this.props.melding.meldingType);
    };

    private slett = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        event.preventDefault();
        this.props.fjern(this.props.melding);
    };
}

const MeldingBarMeldingConnected = compose<React.ComponentType<IMeldingProps>>(
    injectIntl,
    focusOnMount
)(MeldingBarMelding);

export { MeldingBarMeldingConnected };
