import { createLogger } from 'redux-logger';
import type { Middleware, Store } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import type { RootStateType as IRootState } from '../../models/types';
import * as defaultReducers from '../reducers';
import { initialState } from './initial-state';

const rootReducer = (state, action) => ({
    ...combineReducers({ ...defaultReducers })(state, action)
});

const devmode: boolean = process.env.NODE_ENV === 'development' && !!( module as any ).hot
    || window && new URLSearchParams(window.location.search).has('devmode');

const logger = createLogger({
    predicate: () => devmode,
    collapsed: true,
    duration: false,
    logErrors: true
});

const setupStore = (myLogger: Middleware = logger, preloadedState?: Partial<IRootState>) => configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
                immutableCheck: { warnAfter: 50 },
                serializableCheck: { warnAfter: 50 }
            }
        ).concat(myLogger),
    preloadedState
});

const appStore = (preloadedState?: Partial<IRootState>) => setupStore(logger, preloadedState);

const store = setupStore(logger, initialState);
const preloadState = (preloadedStore: Partial<IRootState>): Store<IRootState> => setupStore(logger, preloadedStore);

export type { IRootState };
export { store, preloadState, setupStore, appStore };

if (process.env.NODE_ENV === 'development' && ( module as any ).hot) {
    ( module as any ).hot.accept('../reducers', () => {
        store.replaceReducer(rootReducer);
    });
}