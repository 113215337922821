import { MerknadActionKey } from '../../../constants';
import type { IMerknadAction } from '../../actions/merknad/merknad-actions';

const initialState = {
    merknader: []
};

export const merknader = (state = initialState, action: IMerknadAction) => {

    if (action.type === MerknadActionKey.UPDATE_MERKNADER_ACTION) {
        return {...state, merknader: action.merknader};
    }

    return state;
};
