import { AkLoading, AkMelding, AkMeldingboksType } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import type { IMessageDescriptor } from 'svv-tk-akr-common-frontend';

import * as React from 'react';
import { connect } from 'react-redux';
import { KjoretoyInfo } from '../';

import type { IKjoretoyMap, IMidlertidigBrukstillatelse, IMidlertidigBrukstillatelseMap, IMidlertidigBrukstillatelseMapEntry } from '../../models/types';
import { getKjoretoy, getMidlertidigeBrukstillatelser, resetKjoretoyForMidlertidigBrukstillatelse } from '../../state/actions';

import './midlertidig-brukstillatelse-info-style';
import moment from 'moment';

interface IMidlertidigBrukstillatelseInfoStateProps {
    midlertidigbrukstillatelser: IMidlertidigBrukstillatelseMap;
    kjoretoy: IKjoretoyMap;
}

interface IMidlertidigBrukstillatelseInfoDispatchProps {
    getMidlertidigeBrukstillatelser: (prkid: number) => Promise<unknown>;
    getKjoretoy: (kuid: string, midlertidigbrukstillatelse: IMidlertidigBrukstillatelse) => Promise<unknown>;
    resetKjoretoyForMidlertidigBrukstillatelse: (mbtId: number) => Promise<unknown>;
}

interface IMidlertidigBrukstillatelseInfoProps {
    prkid: number;
}

export type MidlertidigbrukstillatelseinfoPropsType = IMidlertidigBrukstillatelseInfoStateProps & IMidlertidigBrukstillatelseInfoDispatchProps & IMidlertidigBrukstillatelseInfoProps;

class MidlertidigBrukstillatelseInfo extends React.Component<MidlertidigbrukstillatelseinfoPropsType> {

    public componentDidMount() {
        this.props.getMidlertidigeBrukstillatelser(this.props.prkid);
    }

    public componentDidUpdate() {
        const midlertidigbrukstillatelseEntry: IMidlertidigBrukstillatelseMapEntry = this.props.midlertidigbrukstillatelser[this.props.prkid];

        if (!!midlertidigbrukstillatelseEntry && !midlertidigbrukstillatelseEntry.isLoading) {
            const mbt = midlertidigbrukstillatelseEntry.innvarendeMbt;

            if (mbt && !this.props.kjoretoy[mbt.id]) {
                this.props.getKjoretoy(mbt.kuid, mbt);
            }
        }
    }

    public componentWillUnmount() {
        this.props.resetKjoretoyForMidlertidigBrukstillatelse(this.props.midlertidigbrukstillatelser[this.props.prkid]?.innvarendeMbt?.id);
    }

    public render() {
        const midlertidigbrukstillatelseEntry: IMidlertidigBrukstillatelseMapEntry = this.props.midlertidigbrukstillatelser[this.props.prkid];
        const mbt = midlertidigbrukstillatelseEntry?.innvarendeMbt;

        if (!midlertidigbrukstillatelseEntry || !mbt) {
            return null;
        }

        if (midlertidigbrukstillatelseEntry.isLoading) {
            return <AkLoading extraClassName="ak-spinner-small"/>;
        }

        const kjoretoy = this.props.kjoretoy[mbt.id];

        if (!kjoretoy || kjoretoy.isLoading) {
            return <AkLoading extraClassName="ak-spinner-small"/>;
        }

        if (!!kjoretoy.error) {
            const melding: IMessageDescriptor = {id: 'common.error', values: {errorId: kjoretoy.error.errorId}};
            return <AkMelding messageKey={melding} meldingType={AkMeldingboksType.ERROR}/>;
        }

        return (
            <div key={`mdl-info-${this.props.prkid}`} className="ak-midlertidig-brukstillatelse-info">
                <i className="fa fa-link" />
                <KjoretoyInfo kjoretoy={kjoretoy}/>
                <section className="ak-midlertidig-brukstillatelse-info-periode">
                    <p>Gyldig til:
                        <span>
                            {moment(mbt.periode.tilTidspunkt).calendar()}
                        </span>
                    </p>
                </section>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    midlertidigbrukstillatelser: state.midlertidigebrukstillatelser,
    kjoretoy: state.kjoretoy
});

const mapDispatchToProps = (dispatch): IMidlertidigBrukstillatelseInfoDispatchProps => ({
    getMidlertidigeBrukstillatelser: (prkid: number) => dispatch(getMidlertidigeBrukstillatelser(prkid, true)),
    getKjoretoy: (kuid: string, midlertidigbrukstillatelse: IMidlertidigBrukstillatelse) => dispatch(getKjoretoy(kuid, midlertidigbrukstillatelse)),
    resetKjoretoyForMidlertidigBrukstillatelse: (mbtId: number) => dispatch(resetKjoretoyForMidlertidigBrukstillatelse(mbtId))
});

const MidlertidigBrukstillatelseInfoConnected = connect(mapStateToProps, mapDispatchToProps)(MidlertidigBrukstillatelseInfo);

export { MidlertidigBrukstillatelseInfo, MidlertidigBrukstillatelseInfoConnected };
