import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { IkkeFunnetSideConnected } from '.';
import { ArkivConnected } from '../arkiv/arkiv';
import { ArsprovekjennemerkerConnected } from '../arsprovekjennemerker';
import { ArsprovekjennemerkerEtterregistrerConnected } from '../etterregistrer';
import { EtterregistrerKjoreseddelConnected } from '../kjoreseddel/etterregistrer-kjoreseddel';
import { EtterregistrerKjoreseddelKvitteringConnected } from '../kjoreseddel/etterregistrer-kjoreseddel-read-only';
import { KjoreseddelConnected } from '../kjoreseddel/kjoreseddel';
import { KjoreseddelKvitteringConnected } from '../kjoreseddel/kjoreseddel-read-only';
import { EtterregistrerKjoretoySok, KjoretoySokConnected } from '../sok';

export const AkRouter: React.FC = () => {
    return (
        <Routes>
            <Route path="/kjoreseddel/:midlertidigbrukstillatelseId" element={<KjoreseddelKvitteringConnected />} />
            <Route path="/etterregistrer/kjoreseddel/:midlertidigbrukstillatelseId" element={<EtterregistrerKjoreseddelKvitteringConnected />} />

            <Route path="/arsprovekjennemerker/:prkid/kjoreseddel/:kuid" element={<KjoreseddelConnected />} />
            <Route path="/arsprovekjennemerker/:prkid/kjoreseddel" element={<KjoreseddelConnected />} />
            <Route path="/arsprovekjennemerker/:prkid/etterregistrer-kjoreseddel/:kuid" element={<EtterregistrerKjoreseddelConnected />} />
            <Route path="/arsprovekjennemerker/:prkid/etterregistrer-kjoreseddel" element={<EtterregistrerKjoreseddelConnected />} />
            <Route path="/arsprovekjennemerker/:prkid/kjoretoysok" element={<KjoretoySokConnected />}>
                <Route path="kjennemerke" element={<KjoretoySokConnected />} />
                <Route path="understellsnummer" element={<KjoretoySokConnected />} />
                <Route path="fremmed" element={<KjoretoySokConnected />} />
            </Route>
            <Route path="/arsprovekjennemerker/:prkid/etterregistrer/kjoretoysok" element={<EtterregistrerKjoretoySok />}>
                <Route path="kjennemerke" element={<EtterregistrerKjoretoySok />} />
                <Route path="understellsnummer" element={<EtterregistrerKjoretoySok />} />
                <Route path="fremmed" element={<EtterregistrerKjoretoySok />} />
            </Route>
            <Route path="/arsprovekjennemerker" element={<ArsprovekjennemerkerConnected />} />

            <Route path="/etterregistrer" element={<ArsprovekjennemerkerEtterregistrerConnected />} />

            <Route path="/ikke-funnet" element={<IkkeFunnetSideConnected />} />

            <Route path="/arkiv" element={<ArkivConnected />} />
            <Route path="/" element={<Navigate to="/arsprovekjennemerker" />} />
            <Route element={<IkkeFunnetSideConnected />} />
        </Routes>
    );
};