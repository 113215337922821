import { MidlertidigBrukstillatelseActionKey, StoppMidlertidigBrukstillatelseActionKey } from '../../../constants';
import type { IMidlertidigBrukstillatelse } from '../../../models/types';
import { MidlertidigBrukstillatelseRegler } from '../../../regler';
import type { IMidlertidigBrukstillatelseAction, IStoppMidlertidigBrukstillatelseAction } from '../../actions/';

const initialState = {};

export const midlertidigebrukstillatelser = (state = initialState, action: IMidlertidigBrukstillatelseAction) => {
    switch (action.type) {
        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING):
            return {
                ...state,
                [action.key]: { isLoading: action.isLoading }
            };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_ERROR):
            return {
                ...state,
                [action.key]: { isLoading: false, error: action.error }
            };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_SUCCESS):
            return {
                ...state,
                [action.key]: {
                    isLoading: false,
                    innvarendeMbt: MidlertidigBrukstillatelseRegler.hentInneverendeMbt(action.midlertidigebrukstillatelser),
                    liste: [...action.midlertidigebrukstillatelser]
                }
            };

        default:
            return state;
    }
};

const stoppMdlInitialState: Partial<IMidlertidigBrukstillatelse> = {
    id: null,
    error: null,
    isLoading: false
};

export const avsluttMidlertidigBrukstillatelse = (state = stoppMdlInitialState, action: IStoppMidlertidigBrukstillatelseAction) => {
    switch (action.type) {
        case (StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING):
            return { ...state, isLoading: action.isLoading };

        case (StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS):
            return { ...state, ...action.midlertidigbrukstillatelse };

        case (StoppMidlertidigBrukstillatelseActionKey.STOPP_MIDLERTIDIG_BRUKSTILLATELSE_ERROR):
            return { ...state, error: action.error };

        default:
            return state;
    }
};

const initialMidlState: IMidlertidigBrukstillatelse = {
    isEmpty: false,
    error: null,
    isLoading: false,
    isKansellert: false,
    periode: {
        fomTidspunkt: null,
        tilTidspunkt: null
    },
    utstedtAvKundeId: null,
    forersNavn: null,
    forersFodselsdato: null,
    formal: null,
    fremmedKjoretoy: null,
    id: null,
    imid: null,
    prkid: null,
    kuid: null,
    etterregistrering: false,
    print: {
        isLoading: false,
        error: null
    },
    sms: {
        isLoading: false,
        error: null
    }
};

export const midlertidigbrukstillatelse = (state = initialMidlState, action: IMidlertidigBrukstillatelseAction): IMidlertidigBrukstillatelse => {
    switch (action.type) {

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_IS_LOADING):
            return { ...state, isLoading: true };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_ERROR):
            return { ...state, isLoading: false, error: action.error };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_GET_SUCCESS):
            return { ...state, isLoading: false };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_FETCH_SUCCESS):
            return { ...initialMidlState, isLoading: false, ...action.midlertidigbrukstillatelse, redirectTrigger: false };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_IS_LOADING):
            return { ...state, isLoading: true };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_ERROR):
            return { ...state, error: action.response.error, merknader: action.response.merknader, isLoading: false };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_POST_SUCCESS):
            return { ...state, ...action.midlertidigBrukstillatelse, redirectTrigger: true, error: null };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_IS_LOADING):
            return { ...state, sms: { isLoading: action.isLoading } };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_ERROR):
            return { ...state, sms: { isLoading: false, error: action.error } };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_SEND_SUCCESS):
            return { ...state, sms: initialMidlState.sms };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_PRINT_IS_LOADING):
            return { ...state, print: { isLoading: action.isLoading } };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_PRINT_ERROR):
            return { ...state, print: { isLoading: false, error: action.error } };

        case (MidlertidigBrukstillatelseActionKey.MIDLERTIDIG_BRUKSTILLATELSE_PRINT_SUCCESS):
            return { ...state, print: initialMidlState.print };

        case (MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING):
            return { ...state, isLoading: true };

        case (MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_ERROR):
            return { ...state, error: action.error, isLoading: false };

        case (MidlertidigBrukstillatelseActionKey.KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS):
            return { ...state, isKansellert: true, isLoading: false };


        default:
            return state;
    }
};
