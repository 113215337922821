import { AkMelding, AkMeldingboksType } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import { ISO_8601_DATO_TID_FORMAT } from 'svv-tk-akr-common-frontend';

import moment from 'moment';

import * as React from 'react';

import type { IMidlertidigBrukstillatelse } from '../../models/types';
import { EtterregistreringRegler } from '../../regler';

interface IEtterregistreringMeldingerProps {
    etterregMbt: IMidlertidigBrukstillatelse;
    historiskeMbter: IMidlertidigBrukstillatelse[];
}

const EtterregistreringPeriodeMeldinger: React.FC<IEtterregistreringMeldingerProps> = (props: IEtterregistreringMeldingerProps) => {
    const {etterregMbt, historiskeMbter} = props;

    if (!etterregMbt.periode || !historiskeMbter.length) {
        return null;
    }

    const kritiskeMeldinger: string[] = utledKritiskeMeldinger(props);
    const infomeldinger: string[] = utledInfomeldinger(props);

    return (
        <>
            {!!kritiskeMeldinger.length && <AkMelding meldingType={AkMeldingboksType.ERROR} messageKey={kritiskeMeldinger} customClassName="mb-2 mr-md-3" />}
            {!!infomeldinger.length && <AkMelding meldingType={AkMeldingboksType.INFO} messageKey={infomeldinger} customClassName="mb-2 mr-md-3" />}
        </>
    );
};

const utledInfomeldinger = (props: IEtterregistreringMeldingerProps) => {
    const {etterregMbt, historiskeMbter} = props;
    const meldinger = [];

    if (EtterregistreringRegler.isStartDateTimeInnenfor24TimerFraSluttDateTimeEksisterendeKjoreseddel(etterregMbt, historiskeMbter)) {
        meldinger.push('etterregistrer.meldinger.historiskSluttTidspunktOverskrives');
    }
    if (EtterregistreringRegler.isSluttDateTimeInnenfor24TimerFraStartDateTimeEksisterendeKjoreseddel(
        moment(etterregMbt.periode.fomTidspunkt).format(ISO_8601_DATO_TID_FORMAT), historiskeMbter)) {
        meldinger.push('etterregistrer.meldinger.valgtSluttTidspunktOverskrives');
    }

    return meldinger;
};

const utledKritiskeMeldinger = (props: IEtterregistreringMeldingerProps) => {
    const {etterregMbt, historiskeMbter} = props;
    const meldinger = [];

    if (EtterregistreringRegler.isStartDateTimeIdentiskMedStartDateTimeEksisterendeKjoreseddel(etterregMbt, historiskeMbter)) {
        meldinger.push('etterregistrer.meldinger.valgtStartTidspunktIdentisk');
    }

    return meldinger;
};

export { EtterregistreringPeriodeMeldinger };
