import * as _ from 'lodash';
import type { RedirectedResponse} from 'svv-tk-akr-common-frontend';
import { template } from 'svv-tk-akr-common-frontend';
import { AkrConfig } from '../constants/akr-config';

import type { IError } from '../models/types';
import { isErrorFromServer, isJsStackTrace } from '../type-guards';

const API_BASE_URL = '';
const API_CONTENT_TYPE = {'Content-Type': 'application/json'};
const API_CREDENTIALS_ALLOW = 'include';
const CONTENT_TYPE_HEADER_NAME = 'content-type';
const CSRF_TOKEN_HEADER_NAME = 'X-XSRF-TOKEN';
const CSRF_TOKEN_COOKIE_NAME = 'CLIENT-XSRF-TOKEN';

type RequestMethodType = 'GET' | 'POST' | 'PUT' | 'DELETE';

enum HttpResponseCode {
    OK = 200,
    NO_CONTENT = 204,
    OK_MAX_RANGE = 299,
    UNAUTHORIZED = 401,
    NOT_FOUND = 404
}

export interface IAkRestApiMap {
    [index: string]: string | number | boolean | any[];
}

export type IAkRestApiResourceUrlParams = IAkRestApiMap;
type IAkRestApiQueryParams = IAkRestApiMap;

export interface IAkRestApiConfig {
    resourceUrl?: string;
    baseUrl: string;
    redirectHandler?: (response: RedirectedResponse) => Promise<RedirectedResponse>;
}

export type IdParamType = string | number | IAkRestApiResourceUrlParams;

export interface IAkRestApi {
    get: (id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object) => Promise<any>;
    fetch: (queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object) => Promise<any>;
    fetchAll: () => Promise<any>;
    post: (body: any, id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object) => Promise<any>;
    put: (body: any, id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object, action?: string) => Promise<any>;
    remove: (id: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object) => Promise<any>;
    upload: (body: FormData, id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object) => Promise<any>;
    getFile: (options?: object, queryParams?: IAkRestApiQueryParams) => Promise<Response>;
}

class AkRestApi implements IAkRestApi {

    private config: IAkRestApiConfig;
    private defaultConfig: Partial<IAkRestApiConfig> = {
        baseUrl: null,
        resourceUrl: null,
        redirectHandler: undefined
    };

    public constructor(resourceConfig: IAkRestApiConfig) {
        this.config = {...this.defaultConfig, ...resourceConfig};
    }

    public get = (id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object): Promise<any> =>
        fetch(this.buildRequestUrl(resourceUrl, id, queryParams), this.apiConfig('GET', null, options))
            .then(this.handleResponse).catch(this.transformAndReturnError);

    public fetch = (queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object): Promise<any> =>
        fetch(this.buildRequestUrl(resourceUrl, null, queryParams), this.apiConfig('GET', null, options))
            .then(this.handleResponse).catch(this.transformAndReturnError);

    public fetchAll = (): Promise<any> =>
        fetch(this.buildRequestUrl(), this.apiConfig('GET', null, null))
            .then(this.handleResponse).catch(this.transformAndReturnError);

    public post = (body: any, id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object): Promise<any> =>
        fetch(this.buildRequestUrl(resourceUrl, id, queryParams), this.apiConfig('POST', body, options))
            .then(this.handleResponse).catch(this.transformAndReturnError);

    public put = (body: any, id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, options?: object, action?: string): Promise<any> =>
        fetch(this.buildRequestUrl(resourceUrl, id, queryParams, action), this.apiConfig('PUT', body, options))
            .then(this.handleResponse).catch(this.transformAndReturnError);

    public remove = (id: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string, body?: any, options?: object): Promise<any> =>
        fetch(this.buildRequestUrl(resourceUrl, id, queryParams), this.apiConfig('DELETE', body, options))
            .then(this.handleResponse).catch(this.transformAndReturnError);

    public upload = (body: FormData, id?: IdParamType, queryParams?: IAkRestApiQueryParams, resourceUrl?: string): Promise<any> => {
        const cookie = this.handleResponseCookies().get(CSRF_TOKEN_COOKIE_NAME);
        const headers = !!cookie
            ? {[CSRF_TOKEN_HEADER_NAME]: cookie}
            : {};
        return fetch(this.buildRequestUrl(resourceUrl, id, queryParams), { credentials: API_CREDENTIALS_ALLOW, headers, method: 'POST', body })
            .then(this.handleResponse).catch(this.transformAndReturnError);
    };

    public getFile = (options?: object, queryParams?: IAkRestApiQueryParams) => {
        return fetch(this.buildRequestUrl(null, null, queryParams), this.apiConfig('GET', null, options)).then((res) => this.handleResponse(res, true)).catch(this.transformAndReturnError);
    };

    private handleResponse = (response: Response, isFileReq?: boolean): Promise<any> => {
        try {
            if (response.redirected && typeof this.config.redirectHandler === 'function') {
                return this.config.redirectHandler({
                    url: response.url,
                    redirected: response.redirected
                });
            }

            const headers = this.handleResponseHeaders(response);
            if (response.status === HttpResponseCode.NO_CONTENT.valueOf()) {
                return Promise.resolve(null);
            }

            if (response.status === HttpResponseCode.UNAUTHORIZED.valueOf()) {
                return response.json().then(responseBody => {
                    return Promise.resolve({...responseBody, unauthorized: true});
                });
            }

            if (response.status === HttpResponseCode.NOT_FOUND.valueOf()) {
                return Promise.resolve({errorId: 'ikke.funnet', errorCode: 'ikke.funnet'});
            }

            if (!isFileReq && (!headers.get(CONTENT_TYPE_HEADER_NAME) || !headers.get(CONTENT_TYPE_HEADER_NAME).match(/json/i))) {
                return response.json().then(responseBody => {
                    return this.handleUnknownError(responseBody);
                });
            }

            if (response.status > HttpResponseCode.OK_MAX_RANGE.valueOf() && !headers.get(CONTENT_TYPE_HEADER_NAME).match(/json/i)) {
                return Promise.resolve({errorCode: 'generell.feil'});
            }

            if (isFileReq) {
                return Promise.resolve(response);
            }

            return response.json().then((responseBody) => {
                return Promise.resolve(
                    responseBody.entity
                    ? {...responseBody.entity, merknader: responseBody.merknader || []}
                    : responseBody
                );
            }, this.handleUnknownError);
        } catch (err) {
            return this.handleUnknownError(err);
        }
    };

    private handleUnknownError = (err: any) => {
        return this.logErrorAndReturnTransformedError(err);
    };

    private transformAndReturnError = (err: any): Promise<IError> => {
        if (isErrorFromServer(err)) {
            return Promise.resolve({
                errorId: err.errorId,
                errorCode: err.errorCode
            } as IError);
        } else if (isJsStackTrace(err)) {
            return Promise.resolve({
                errorId: 'akr.kjoreseddel.app.00001',
                errorCode: 'akr.kjoreseddel.app.00001',
                errorMessage: `${err.name}: ${err.message} ${err.stack}`
            } as IError);
        }
    };

    private logErrorAndReturnTransformedError = (err: any) => {
        return this.transformAndReturnError(err).then(transformedError => {
            // Logger "blindt" i tilfelle det ikke er kontakt med tjener
            fetch(this.buildRequestUrl(AkrConfig.FEILMELDINGSLOGGER_RESOURCE_URL), this.apiConfig('POST', transformedError));
            return transformedError;
        });
    };

    private handleResponseHeaders = (response: Response): Map<string, string> => {
        const {headers: responseHeaders} = response;
        const headers: Map<string, string> = new Map<string, string>();
        responseHeaders.forEach((value, key) => {
            headers.set(key, value);
        });
        return headers;
    };

    private handleResponseCookies = (): Map<string, string> => {
        const cookies: Map<string, string> = new Map<string, string>();
        if (document.cookie) {
            const responseCookies = document.cookie.split(/\s*;\s*/g);
            responseCookies.forEach((cookie) => {
                const nameValue = cookie.split(/\s*=\s*/g);
                const name = nameValue[0];
                const value = nameValue[1];
                cookies.set(name, value);
            });
        }
        return cookies;
    };

    private apiConfig = (method: RequestMethodType, body?: any, options?: object) => {
        const cookie = this.handleResponseCookies().get(CSRF_TOKEN_COOKIE_NAME);
        const headers = !!cookie && method !== 'GET'
                        ? {... API_CONTENT_TYPE, [CSRF_TOKEN_HEADER_NAME]: cookie}
                        : {... API_CONTENT_TYPE};

        const defaultConf: RequestInit = {
            method,
            credentials: API_CREDENTIALS_ALLOW,
            headers: headers
        };

        if (body) {
            defaultConf.body = JSON.stringify(body);
        }

        return _.merge(defaultConf, options);
    };

    private buildRequestUrl = (url?: string, queryId?: string | number | IAkRestApiResourceUrlParams, queryParams?: IAkRestApiQueryParams, action?: string) => {
        let resourceSegment;

        if (!queryId || typeof queryId === 'string' || typeof queryId === 'number') {
            resourceSegment = API_BASE_URL + (url || this.config.resourceUrl);
        } else {
            if (queryId.id) {
                resourceSegment = `${API_BASE_URL + template(url || this.config.resourceUrl, _.omit(queryId, 'id'))}/${queryId.id}`;
            } else {
                resourceSegment = API_BASE_URL + template(url || this.config.resourceUrl, queryId);
            }
        }

        if (queryId && typeof queryId !== 'object') {
            resourceSegment = `${resourceSegment}/${queryId}`;
        }

        if (!_.isEmpty(queryParams)) {
            resourceSegment = `${resourceSegment}?${this.formatQueryParams(queryParams)}`;
        }

        if (action) {
            resourceSegment = `${resourceSegment}${action}`;
        }

        return encodeURI(`${this.config.baseUrl}${resourceSegment}`);
    };

    private formatQueryParams(obj: {[index: string]: any}): string {
        return _.keys(obj).map((key: string) => !_.isUndefined(obj[key]) ? `${key}=${obj[key]}` : '').join('&');
    }
}

export { API_BASE_URL, API_CONTENT_TYPE, CSRF_TOKEN_COOKIE_NAME, AkRestApi };