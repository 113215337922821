import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

export const Footer: React.FC = () => {

    const intl = useIntl();

    return (
        <footer className="ak-footer">
            <p>
                <FormattedMessage id="footer.melding"/>
            </p>
            <p>
                {intl.formatMessage({id: 'footer.vegvesen.telefon.melding'})}
                <a href={`tel:${intl.formatMessage({id: 'footer.vegvesen.telefon.nummer'})}`}>
                    {intl.formatMessage({id: 'footer.vegvesen.telefon.nummer'})}
                </a>
            </p>
            <p>
                {intl.formatMessage({id: 'footer.vegvesen.epost.melding'})}
                <a href={`mailto:${intl.formatMessage({id: 'footer.vegvesen.epost'})}`}>
                    {intl.formatMessage({id: 'footer.vegvesen.epost'})}
                </a>
            </p>
        </footer>
    );
};