import * as _ from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import type { MeldingType } from '../../models/kodeverk/melding-bar-enums';
import type { RootStateType } from '../../models/types';
import type { IMelding, IMeldingBar } from '../../models/types/melding-bar';
import { fjernMelding } from '../../state/actions/globals/melding-bar';

import '../melding-bar/melding-bar-style';
import { MeldingBarMeldingConnected } from './melding-bar-melding';

interface IMeldingBarStateProps {
    meldingBar: IMeldingBar;
}

interface IMeldingBarDispatchProps {
    fjernMelding: (melding: IMelding) => any;
}

class MeldingBar extends React.Component<IMeldingBarStateProps & IMeldingBarDispatchProps> {

    public render() {
        return (
            <ul className="ak-melding-bar row">
                {this.lagMeldinger(this.props.meldingBar.meldinger)}
            </ul>
        );
    }

    private lagMeldinger = (meldinger: IMelding[]) => {
        return _.map(meldinger, melding => {
            return (
                <MeldingBarMeldingConnected
                    key={melding.meldingId}
                    melding={melding}
                    fjern={this.props.fjernMelding}
                    fjernAlleMeldingerAvType={this.fjernAlleMeldingerAvType}
                />
            );

        });
    };

    private fjernAlleMeldingerAvType = (meldingType: MeldingType) => {
        _.chain(this.props.meldingBar.meldinger)
            .filter((melding) =>
                melding.meldingType === meldingType
            ).map((melding) =>
            this.props.fjernMelding(melding)
        ).value();
    };
}

const mapDispatchToProps = dispatch => ({
    fjernMelding: (melding: IMelding) => dispatch(fjernMelding(melding))
});

const mapStateToProps = (state: RootStateType) => ({
    meldingBar: state.meldingBar
});

const MeldingBarConnected = connect(mapStateToProps, mapDispatchToProps)(MeldingBar);

export { MeldingBarConnected };
