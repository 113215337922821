import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FULLT_DATO_TID_FORMAT, ISO_8601_DATO_FORMAT, ISO_8601_DATO_TID_FORMAT, STANDARD_DATO_FORMAT } from 'svv-tk-akr-common-frontend';
import type { AkTextInputResult} from 'svv-tk-akr-common-frontend/dist/mobile';
import { AkButton, AkCard, AkMelding, AkMeldingboksType, AkTextInput, ButtonType } from 'svv-tk-akr-common-frontend/dist/mobile';

import { ArsprovekjennemerkeKjoretoyKnytningConnected, LoadingWithErrorHandler } from '../../components';
import { AkConfirmationBox } from '../../components/ak-confirmation-box/ak-confirmation-box';
import { VALID_TLF_REGEX } from '../../constants';
import { AkrConfig, PREFIX } from '../../constants/akr-config';
import type { Brukerprofil, IMidlertidigBrukstillatelse, RootStateType } from '../../models/types';
import {
    fetchMidlertidigBrukstillatelse, kansellerMidlertidigBrukstillatelse, sendMidlertidigbrukstillatelse, stoppMidlertidigBrukstillatelseOgHentOppdatertMidlertidigBrukstillatelse
} from '../../state/actions';

import './kjoreseddel-style.scss';
import classNames from 'classnames';
import type { WithRouterProps } from '../../utils';
import { withRouter } from '../../utils';

interface IKjoreseddelKvitteringDispatchProps {
    fetchMidlertidigbrukstillatelse: (midlertidigbrukstillatelseId: string) => Promise<unknown>;
    sendMidlertidigBrukstillatelse: (midlertidigbrukstillatelseId: string, telefonnummer: string) => Promise<unknown>;
    stoppMidlertidigbrukstillatelse: (midlertidigbrukstillatelseId: number) => Promise<any>;
    kansellerMidlertidigbrukstillatelse: (midlertidigbrukstillatelseId: number) => Promise<any>;
}

interface IKjoreseddelKvitteringStateProps {
    etterregistrerContext?: boolean;
    params?: {
        midlertidigbrukstillatelseId: string;
    };
    midlertidigbrukstillatelse: IMidlertidigBrukstillatelse;
    brukerprofil: Brukerprofil;
}

interface IKjoreseddelKvitteringState {
    submitted: boolean;
    telefonnummer: string;
    formValidation: {
        telefonnummer: boolean;
    };
    showConfirmationBox: boolean;
}

type PropTypes = IKjoreseddelKvitteringStateProps & IKjoreseddelKvitteringDispatchProps & WithRouterProps;

class KjoreseddelKvittering extends React.Component<PropTypes, IKjoreseddelKvitteringState> {

    public state = {
        submitted: false,
        telefonnummer: '',
        formValidation: {
            telefonnummer: false
        },
        showConfirmationBox: false
    };

    public componentDidMount() {
        this.props.fetchMidlertidigbrukstillatelse(this.props.params.midlertidigbrukstillatelseId);
    }

    public render() {
        const { brukerprofil, midlertidigbrukstillatelse } = this.props;
        const { navn, hovedenhetOrgNummer } = brukerprofil.valgtEnhet;
        const isFremtidigKjoreseddel = moment(this.props.midlertidigbrukstillatelse.periode.fomTidspunkt).isAfter(moment().add(10, 'minutes'));

        if (!midlertidigbrukstillatelse.prkid && !midlertidigbrukstillatelse.isLoading) {
            return null;
        }

        return (
            <div className="ak-kjoreseddel kjoreseddel-read-only">
                <header className="ak-heading">
                    <AkButton customClassNames="col-auto" buttonType={ButtonType.LINK} buttonIntlKey="common.tilbakeTilOversikt" action={this.gaTilOversikt} icon="fa-angle-left" />
                </header>
                <section className="ak-innhold">
                    <LoadingWithErrorHandler {...midlertidigbrukstillatelse} customClassNames="col-12 text-center">
                        <AkCard customClassNames="col-12">
                            {!midlertidigbrukstillatelse.isKansellert &&
                            <section className="ak-kjoreseddel-apkj-kjoretoy-info col order-md-2 col-md-4">
                                <ArsprovekjennemerkeKjoretoyKnytningConnected />
                            </section>}
                            <section className="ak-kjoreseddel-info col">

                                <div className="row">
                                    <h2 className="col">
                                        <FormattedMessage
                                            id={this.props.etterregistrerContext ? 'kjoreseddel.readonly.underoverskriftEtterregistrering' : 'kjoreseddel.readonly.underoverskrift'} />
                                    </h2>
                                </div>
                                {midlertidigbrukstillatelse.isKansellert ?
                                 <div className="ak-kansellert-infoboks no-gutters">
                                     <AkMelding meldingType={AkMeldingboksType.INFO} messageKey={'kjoreseddel.merknader.KJORESEDDEL_ER_KANSELLER'} />
                                 </div>
                                                                         :
                                 <>
                                     <div className="ak-kjoreseddel-meldinger row no-gutters">
                                         {(isFremtidigKjoreseddel && !midlertidigbrukstillatelse.isKansellert) &&
                                         <AkMelding meldingType={AkMeldingboksType.INFO} messageKey={'kjoreseddel.merknader.KJORESEDDEL_AKTIVERES_FREMTIDIG_TIDSPUNKT'} />
                                         }
                                     </div>
                                     <dl className="row">
                                         <dt><FormattedMessage id="kjoreseddel.readonly.label.formal" /></dt>
                                         <dd><FormattedMessage id={`kjoreseddel.input.formal.${midlertidigbrukstillatelse.formal}`} /></dd>

                                         <dt><FormattedMessage id="kjoreseddel.label.periode.start" /></dt>
                                         <dd>{this.formatDateTime(midlertidigbrukstillatelse.periode.fomTidspunkt)}</dd>

                                         <dt><FormattedMessage id="kjoreseddel.label.periode.slutt" /></dt>
                                         <dd>{this.formatDateTime(midlertidigbrukstillatelse.periode.tilTidspunkt)}</dd>

                                         <dt><FormattedMessage id="kjoreseddel.label.forers-navn" /></dt>
                                         <dd>{midlertidigbrukstillatelse.forersNavn}</dd>

                                         <dt><FormattedMessage id="kjoreseddel.label.forers-fodselsdato" /></dt>
                                         <dd>{this.formatDate(midlertidigbrukstillatelse.forersFodselsdato)}</dd>

                                         <dt><FormattedMessage id="kjoreseddel.readonly.label.ansvarlig" /></dt>
                                         <dd><FormattedMessage id={'kjoreseddel.readonly.ansvarlig'} values={{ navn: navn, enhetId: hovedenhetOrgNummer }} /></dd>

                                         <dt><FormattedMessage id="kjoreseddel.readonly.label.utstedt-av" /></dt>
                                         <dd>{midlertidigbrukstillatelse.utstedtAvNavn}</dd>
                                         {this.midlertidigBrukstillatelseIsActive() &&
                                         <>
                                             <dt><FormattedMessage id="kjoreseddel.readonly.label.sms" /></dt>
                                             <dd className="ak-input-sms row">
                                                 <AkTextInput
                                                     customInputClassName="w-100"
                                                     customClassName="col-8 pr-0"
                                                     initialValue={this.state.telefonnummer}
                                                     placeholderIntlKey="kjoreseddel.readonly.label.placeholder.sms"
                                                     onBlur={this.onTelefonnummerBlur}
                                                     validRegExp={VALID_TLF_REGEX}
                                                     invalidRegExpMessageKey="kjoreseddel.readonly.sms.ugyldig-format"
                                                     required={true}
                                                     submitted={this.state.submitted}
                                                 />
                                                 <div className="col-2">
                                                     <AkButton buttonIntlKey="kjoreseddel.readonly.buttons.send-sms-varsel" buttonType={ButtonType.MAIN}
                                                         action={this.sendSmsVarsel} disabled={midlertidigbrukstillatelse.sms?.isLoading} />
                                                 </div>
                                             </dd>
                                         </>}
                                     </dl>
                                     {this.midlertidigBrukstillatelseIsActive() && !this.props.etterregistrerContext &&
                                     <>
                                         <div className="ak-button-row">
                                             <AkDownloadButton buttonClassName={ButtonType.SECONDARY} midlertidigbrukstillatelseId={midlertidigbrukstillatelse.id}>
                                                 <FormattedMessage id="kjoreseddel.readonly.buttons.skriv-ut" tagName={undefined} />
                                             </AkDownloadButton>

                                             <AkButton
                                                 buttonType={ButtonType.CANCEL}
                                                 buttonIntlKey={this.brukstillatelseErFremtidig(midlertidigbrukstillatelse.periode.fomTidspunkt)
                                                                ? 'kjoreseddel.readonly.buttons.kanseller'
                                                                : 'kjoreseddel.readonly.buttons.stopp'}
                                                 action={this.visBekreftelsesboks}
                                                 disabled={this.state.showConfirmationBox}
                                                 customClassNames="mr-0" />
                                         </div>
                                         {this.state.showConfirmationBox &&
                                         <div className="row mt-3 pr-md-2">
                                             <AkConfirmationBox message="arsprovekjennemerker.confirmation-box.meldingstekst"
                                                 confirmButtonAction={
                                                     this.stoppEllerKansellerMidlertidigBrukstillatelse}
                                                 confirmButtonIntlKey={
                                                     this.brukstillatelseErFremtidig(midlertidigbrukstillatelse.periode.fomTidspunkt)
                                                     ? 'arsprovekjennemerker.confirmation-box.kanseller'
                                                     : 'arsprovekjennemerker.confirmation-box.stopp'}
                                                 cancelButtonAction={this.closeConfirmationBox} cancelButtonIntlKey="arsprovekjennemerker.confirmation-box.avbryt" />
                                         </div>}
                                     </>
                                     }
                                     {this.props.etterregistrerContext &&
                                     <>
                                         <div className="ak-button-row">
                                             <AkDownloadButton className="mr-2" buttonClassName={ButtonType.MAIN} midlertidigbrukstillatelseId={midlertidigbrukstillatelse.id}>
                                                 <FormattedMessage id="kjoreseddel.readonly.buttons.skriv-ut" tagName={undefined} />
                                                 <i className="fa fa-print ml-3"/>
                                             </AkDownloadButton>
                                             <AkButton buttonType={ButtonType.SECONDARY} buttonIntlKey="kjoreseddel.readonly.buttons.lukk" action={this.gaTilOversikt} />
                                         </div>
                                     </>
                                     }
                                 </>
                                }
                            </section>
                        </AkCard>
                    </LoadingWithErrorHandler>
                </section>
            </div>
        );
    }

    private brukstillatelseErFremtidig(tidspunkt: string) {
        return moment(tidspunkt).isAfter(moment());
    }

    private formatDate = (dato: string) => {
        return moment(dato, ISO_8601_DATO_FORMAT).isValid() ? moment(dato, ISO_8601_DATO_FORMAT).format(STANDARD_DATO_FORMAT) : null;
    };

    private formatDateTime = (dato: string) => {
        return moment(dato, ISO_8601_DATO_TID_FORMAT).isValid() ? moment(dato, ISO_8601_DATO_TID_FORMAT).format(FULLT_DATO_TID_FORMAT) : null;
    };

    private midlertidigBrukstillatelseIsActive = () => moment().isBefore(this.props.midlertidigbrukstillatelse.periode.tilTidspunkt);

    private onTelefonnummerBlur = (result: AkTextInputResult) => {
        this.setState({
            telefonnummer: result.value,
            submitted: true,
            formValidation: {
                telefonnummer: _.isEmpty(result.errors)
            }
        });
    };

    private sendSmsVarsel = () => {
        this.setState({
            submitted: true
        });

        if (!this.state.formValidation.telefonnummer) {
            return;
        }

        this.props.sendMidlertidigBrukstillatelse(this.props.params.midlertidigbrukstillatelseId, this.state.telefonnummer);
    };

    private visBekreftelsesboks = () => {
        this.setState({ showConfirmationBox: true });
    };

    private gaTilOversikt = () => {
        if (this.props.etterregistrerContext) {
            return this.props.navigate('/etterregistrer');
        }
        return this.props.navigate('/arsprovekjennemerker');
    };

    private closeConfirmationBox = () => {
        this.setState({ showConfirmationBox: false });
    };

    private stoppEllerKansellerMidlertidigBrukstillatelse = () => {
        this.handterStoppEllerKanseller();
        this.setState({ showConfirmationBox: false });
    };

    private handterStoppEllerKanseller = () => {
        if (this.brukstillatelseErFremtidig(this.props.midlertidigbrukstillatelse.periode.fomTidspunkt)) {
            this.props.kansellerMidlertidigbrukstillatelse(this.props.midlertidigbrukstillatelse.id);
        } else {
            this.props.stoppMidlertidigbrukstillatelse(this.props.midlertidigbrukstillatelse.id);
        }
    };
}


interface AkDownloadButtonProps {
    buttonClassName: ButtonType.MAIN | ButtonType.SECONDARY;
    midlertidigbrukstillatelseId: number;
    className?: string;
}
const AkDownloadButton: React.FC<AkDownloadButtonProps & React.PropsWithChildren> = (props) => {

    const MIDLERTIDIG_BRUKSTILLATELSER_URL = `${window.env.serviceUrl}${PREFIX}${AkrConfig.MIDLERTIDIG_BRUKSTILLATELSER_RESOURCE_URL}`;

    return (
        <a className={classNames(`ak-button btn ${props.buttonClassName}`, props.className)}
            href={`${MIDLERTIDIG_BRUKSTILLATELSER_URL}/${_.toString(props.midlertidigbrukstillatelseId)}/pdf`}
            download="kjoreseddel.pdf">
            <span className="ak-button-content">
                 {props.children}
             </span>
        </a>
    );
};

const mapStateToProps = (state: RootStateType, ownProps): IKjoreseddelKvitteringStateProps => ({
    etterregistrerContext: ownProps.etterregistrerContext,
    midlertidigbrukstillatelse: state.midlertidigbrukstillatelse,
    brukerprofil: state.brukerprofil
});

const mapDispatchToProps = (dispatch): IKjoreseddelKvitteringDispatchProps => ({
    fetchMidlertidigbrukstillatelse: (midlertidigbrukstillatelseId: string) => dispatch(fetchMidlertidigBrukstillatelse(midlertidigbrukstillatelseId)),
    sendMidlertidigBrukstillatelse: (midlertidigbrukstillatelseId: string, telefonnummer: string) =>
        dispatch(sendMidlertidigbrukstillatelse(Number(midlertidigbrukstillatelseId), telefonnummer)),
    stoppMidlertidigbrukstillatelse: (midlertidigbrukstillatelseId: number) => dispatch(stoppMidlertidigBrukstillatelseOgHentOppdatertMidlertidigBrukstillatelse(midlertidigbrukstillatelseId)),
    kansellerMidlertidigbrukstillatelse: (midlertidigbrukstillatelseId: number) => dispatch(kansellerMidlertidigBrukstillatelse(midlertidigbrukstillatelseId))
});

const KjoreseddelKvitteringConnected = compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(KjoreseddelKvittering);
export { KjoreseddelKvittering, KjoreseddelKvitteringConnected };