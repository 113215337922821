import { AkLoading } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { IError } from '../../models/types';

interface ILoadingErrorProps {
    isLoading?: boolean;
    error?: IError;
    returnNullExpression?: boolean;
    renderFn?: () => React.ReactNode;
    renderCustomErrorFn?: () => React.ReactNode;
    customClassNames?: string;
    customLoadingClassNames?: string;
    customMessageExpression?: boolean;
    customMessageIntlKey?: string;
}

export const LoadingWithErrorHandler: React.FC<ILoadingErrorProps & React.PropsWithChildren> = props => {
    if (!props || (typeof props.returnNullExpression === 'boolean' && props.returnNullExpression)) {
        return null;
    }

    if (props.isLoading) {
        return <AkLoading extraClassName={props.customLoadingClassNames}/>;
    }

    if (!!props.error) {
        let errorParagraph;
        let errorParagraphHidden = false;

        if (props.error.httpStatusCode === '401') {
            errorParagraphHidden = true;
        }

        if (props.error.errorCode === 'akr.integration.sys.00014') {
            errorParagraph = <FormattedMessage id="feilhandtering.kode.akr.integration.sys.00014" values={{errorId: props.error.errorId}}/>;
        }

        if (props.error.httpStatusCode === '403' && props.error.errorCode === 'akr.common.app.00012') {
            errorParagraph = <FormattedMessage id="feilhandtering.kode.akr.common.app.00012" values={{errorId: props.error.errorId}}/>;
        }

        if (props.error.errorCode === 'ingen.avdelinger') {
            errorParagraph = <FormattedMessage id="feilhandtering.generell.brukerprofil.ingen-avdelinger"/>;
        }

        return (
            <section className="ak-innhold">
                <div className="col-12 text-center">
                    <p className={props.customClassNames}>
                        {errorParagraphHidden || !!errorParagraph ? errorParagraph : <FormattedMessage id="feilhandtering.generell.feil" values={{errorId: props.error.errorId}}/>}
                    </p>
                    {props.renderCustomErrorFn && props.renderCustomErrorFn()}
                </div>
            </section>
        );
    }

    if (props.customMessageExpression) {
        return (
            <p className={props.customClassNames}>
                <FormattedMessage id={props.customMessageIntlKey}/>
            </p>
        );
    }

    if (typeof props.renderFn === 'function') {
        return <>
            {props.renderFn()}
            </>;
    }

    return (
        <>
            {props.children}
        </>
    );
};