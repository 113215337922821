import { MidlertidigBrukstillatelseRapportActionKey } from '../../../constants';
import type { IMidlertidigBrukstillatelseRapportRequest } from '../../../models/types/midlertidig-brukstillatelse-rapport';
import type { IMidlertidigBrukstillatelseRapportAction } from '../../actions';

const initialMidlRapState: IMidlertidigBrukstillatelseRapportRequest = {
    prkid: null,
    fomTidspunkt: null,
    tomTidspunkt: null,
    isLoading: false,
    error: null
};

export const midlertidigebrukstillatelserapport = (state = initialMidlRapState, action: IMidlertidigBrukstillatelseRapportAction) => {
    switch (action.type) {
        case (MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_IS_LOADING):
            return {
                ...state,
                isLoading: action.isLoading,
                error: null
            };

        case (MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_ERROR):
            return {
                ...state,
                isLoading: false,
                error: action.error
            };

        case (MidlertidigBrukstillatelseRapportActionKey.MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_SUCCESS):
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
};
