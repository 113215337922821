import moment from 'moment';
import 'moment/locale/nb';
import type { IAkRestApi } from '../../utils/ak-rest-api';
import { AkRestApi } from '../../utils/ak-rest-api';
import { appStore } from './store-config';

require('@formatjs/intl-pluralrules/polyfill');
require('@formatjs/intl-relativetimeformat/polyfill');
require('@formatjs/intl-pluralrules/locale-data/nb');
require('@formatjs/intl-pluralrules/locale-data/nn');

export function createRestResource(resourceUrl: string, prefix?: string): IAkRestApi {
    return new AkRestApi({
        baseUrl: `${window.env.serviceUrl}`,
        resourceUrl: `${!!prefix ? prefix : ''}${resourceUrl}`,
        redirectHandler: (response) => {
            const redirectUrl = new URL(response.url);
            window.location.replace(redirectUrl.pathname);
            return Promise.resolve(response);
        }
    });
}

const store = appStore();

export * from './store-config';
export * from './hooks';
export * from './initial-state';
export default store;

moment.locale('nb');


