import * as _ from 'lodash';
import { combineReducers } from 'redux';
import { ActionKey } from '../../constants';

import type { IError } from '../../models/types';

export interface IGlobalErrorsState {
    readonly errors: IError[];
}

export function hovedmenyEkspandert(state = false, action) {
    if (action.type === ActionKey.HOVEDMENY_SEKSJON_EKSPANDERT) {
        return action.seksjonEkspandert;
    }
    return state;
}

export function globalsReducer(state: IGlobalErrorsState = {errors: []}, action) {
    switch (action.type) {
        case 'ADD_ERROR':
            return _.concat(state, [action.error]);
        case 'RESET_ERRORS':
            return [];
        default:
            return state;
    }
}

export const globals = combineReducers({
    globalErrors: globalsReducer
});
