import { IntlActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import { updateIntl } from '../../intl';
import type { IError } from '../../../models/types';
import { createRestResource } from '../../store';

export interface IntlHasErrorAction {
    type: IntlActionKey.INTL_HAS_ERROR;
    hasError: boolean;
}

export interface IntlHasServerErrorAction {
    type: IntlActionKey.INTL_HAS_SERVER_ERROR;
    error: IError;
}

export interface IntlFetchDataSuccessAction {
    type: IntlActionKey.INTL_FETCH_DATA_SUCCESS;
    lang: string;
}

export const intlHasError = (hasError: boolean): IntlHasErrorAction =>
    ({ type: IntlActionKey.INTL_HAS_ERROR, hasError });
export const intlHasServerError = (error: IError): IntlHasServerErrorAction =>
    ({ type: IntlActionKey.INTL_HAS_SERVER_ERROR, error });
export const intlFetchDataSuccess = (lang: string): IntlFetchDataSuccessAction =>
    ({ type: IntlActionKey.INTL_FETCH_DATA_SUCCESS, lang });

const api = createRestResource(AkrConfig.INTL_RESOURCE_URL, PREFIX);

export function intlGetData(lang: string) {
    const malform = lang || 'nb';

    return dispatch => {
        return api.get(malform)
            .then(response => {

                if (response.errorId) {
                    return dispatch(intlHasServerError(response));
                }

                dispatch(updateIntl({ locale: malform, messages: response[malform] }));
                return dispatch(intlFetchDataSuccess(malform));
            });
    };
}
