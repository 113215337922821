import { KjoretoylisteActionKey } from '../constants';
import type { IFremmedKjoretoy, IKjoretoy } from '../models/types';
import type { IKjoretoylisteAction } from '../state/actions';

export const isFremmedKjoretoy = (kjoretoy: any): kjoretoy is IFremmedKjoretoy =>
    !kjoretoy?.understellsnummer;

export const isRegistrertUregistrertKjoretoy = (kjoretoy: any): kjoretoy is IKjoretoy =>
    !!kjoretoy.understellsnummer;

export const isActionKjoretoyListeAction = (action: any): action is IKjoretoylisteAction => {
    const kjoretoyListeActionsKeys = [
        KjoretoylisteActionKey.KJORETOY_FIND_SUCCESS,
        KjoretoylisteActionKey.KJORETOY_FIND_ERROR,
        KjoretoylisteActionKey.KJORETOY_FIND_IS_LOADING,
        KjoretoylisteActionKey.KJORETOYLISTE_CLEAR
    ];

    return kjoretoyListeActionsKeys.some(k => k === action.type);
};
