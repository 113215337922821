import * as React from 'react';

const FOCUS_TIMEOUT = 500;

export function focusOnMount<T>(Component: React.ComponentType<T>) {
    return class FocusOnMount extends React.Component<T> {
        private focusRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

        public componentDidMount() {
            // TODO: finne en bedre metode for denne
            // forwardRef? Native ref?
            setTimeout(() => this.focusRef.current.focus(), FOCUS_TIMEOUT);
        }

        public render() {
            return (
                // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
                <div className="ak-focus-on-mount" tabIndex={0} ref={this.focusRef}>
                    <Component {...this.props}/>
                </div>
            );
        }
    };
}