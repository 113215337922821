import { ISO_8601_DATO_TID_FORMAT } from 'svv-tk-akr-common-frontend';

import * as _ from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';

import type { IMidlertidigBrukstillatelse, TidspunktAdvarsler } from '../../models/types';

class MidlertidigBrukstillatelseRegler {

    public static utledSluttidspunktForKjoreseddel(fomTidspunkt: string, midlertidigBrukstillatelser: IMidlertidigBrukstillatelse[]): string{
        const nesteMbtInnen24Timer = this.hentNesteMbtInnen24Timer(fomTidspunkt, midlertidigBrukstillatelser);

        if (!!nesteMbtInnen24Timer) {
            return nesteMbtInnen24Timer.periode.fomTidspunkt;
        } else {
            return this.utledEnDagEtterFomTidspunkt(fomTidspunkt);
        }
    }

    public static hentInneverendeMbt(midlertidigBrukstillatelser: IMidlertidigBrukstillatelse[]): IMidlertidigBrukstillatelse {
        return _.find(midlertidigBrukstillatelser, (mbt: IMidlertidigBrukstillatelse) => {
            const { tilTidspunkt, fomTidspunkt } = mbt.periode;
            const now = moment();

            return now.isBetween(moment(fomTidspunkt, ISO_8601_DATO_TID_FORMAT), moment(tilTidspunkt, ISO_8601_DATO_TID_FORMAT));
        });
    }

    public static  kontrollerKjoreseddelTidspunkt(mbtliste: IMidlertidigBrukstillatelse[], nyFomTidspunkt: Moment, nyTilTidspunkt: Moment) {
        const tidspunktAdvarsler = {
            eksisterendeKortereEnnTiMin: false,
            nyKortereEnnTiMin: false,
            aktivSeddelNedkortet: false,
            fremtidigSeddelNedkortet: false,
            varighetKortereEnn24Timer: false,
            seddelEksistererAllerede: false
        } as TidspunktAdvarsler;
        mbtliste.forEach((mbt) => {

            const eksFomTidspunkt = moment(mbt.periode.fomTidspunkt);
            const eksTilTidspunkt = moment(mbt.periode.tilTidspunkt);
            const now = moment();

            if (eksTilTidspunkt.isAfter(now)) {

                const nyOgEksisterendeSeddelDiff = Math.abs(nyFomTidspunkt.diff(eksFomTidspunkt, 'minutes'));

                if (nyOgEksisterendeSeddelDiff == 0){
                    tidspunktAdvarsler.seddelEksistererAllerede = true;
                } else if (nyOgEksisterendeSeddelDiff <= 10 && (eksFomTidspunkt.isAfter(now))) {
                    if (nyFomTidspunkt.isAfter(eksFomTidspunkt)){
                        tidspunktAdvarsler.eksisterendeKortereEnnTiMin = true;
                    } else {
                        tidspunktAdvarsler.nyKortereEnnTiMin = true;
                    }
                } else {
                    if (nyFomTidspunkt.isAfter(eksFomTidspunkt) && nyFomTidspunkt.isBefore(eksTilTidspunkt)) {

                        if (now.isBetween(eksFomTidspunkt, eksTilTidspunkt)) {
                            tidspunktAdvarsler.aktivSeddelNedkortet = true;
                        } else {
                            tidspunktAdvarsler.fremtidigSeddelNedkortet = true;
                        }
                    }
                    if (eksFomTidspunkt.isAfter(nyFomTidspunkt) && eksFomTidspunkt.isBefore(nyTilTidspunkt)) {
                        tidspunktAdvarsler.varighetKortereEnn24Timer = true;
                    }
                }
            }
        });
        return tidspunktAdvarsler;
    }

    private static hentNesteMbtInnen24Timer(starttid: string, historiskeMbter: IMidlertidigBrukstillatelse[]): IMidlertidigBrukstillatelse {
        const startTidMoment = moment(starttid, ISO_8601_DATO_TID_FORMAT);
        const startTidMomentPlus24Timer = moment(starttid, ISO_8601_DATO_TID_FORMAT).add(1, 'd');
        const fremtidigeMbterInnen24Timer = _.filter(historiskeMbter, mb => {
            return moment(mb.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT).isAfter(startTidMoment)
            && moment(mb.periode.fomTidspunkt, ISO_8601_DATO_TID_FORMAT).isBefore(startTidMomentPlus24Timer);
        });
        const fremtidigeMbterSortertPaFromTidspunkt = _.sortBy(fremtidigeMbterInnen24Timer, mb => mb.periode.fomTidspunkt);

        return _.first(fremtidigeMbterSortertPaFromTidspunkt);
    }

    private static utledEnDagEtterFomTidspunkt(fomTidspunkt: string) {
        const fomDateTimeNyMbt = moment(fomTidspunkt, ISO_8601_DATO_TID_FORMAT);
        return fomDateTimeNyMbt.parseZone().clone().add(1, 'days').subtract(1, 'minutes').format(ISO_8601_DATO_TID_FORMAT);
    }
}

export { MidlertidigBrukstillatelseRegler };
