export enum ActionKey {
    HOVEDMENY_SEKSJON_EKSPANDERT = 'HOVEDMENY_SEKSJON_EKSPANDERT',
    ADD_ERROR = 'ADD_ERROR',
    OTHER_ACTION = '__any_other_action__'
}

export enum IntlActionKey {
    INTL_HAS_ERROR = 'INTL_HAS_ERROR',
    INTL_HAS_SERVER_ERROR = 'INTL_HAS_SERVER_ERROR',
    INTL_FETCH_DATA_SUCCESS = 'INTL_FETCH_DATA_SUCCESS'
}

export enum BrukerprofilActionKey {
    BRUKERPROFIL_HAS_SERVER_ERROR = 'BRUKERPROFIL_HAS_SERVER_ERROR',
    BRUKERPROFIL_IS_UNAUTHORIZED = 'BRUKERPROFIL_IS_UNAUTHORIZED',
    BRUKERPROFIL_IS_LOADING = 'BRUKERPROFIL_IS_LOADING',
    BRUKERPROFIL_FETCH_DATA_SUCCESS = 'BRUKERPROFIL_FETCH_DATA_SUCCESS',
    BRUKERPROFIL_HAS_ERROR = 'BRUKERPROFIL_HAS_ERROR',
    BRUKERPROFIL_ARBEIDSSTED_OPPDATERT = 'BRUKERPROFIL_ARBEIDSSTED_OPPDATERT',
    OTHER_ACTION = '__any_other_action__'
}

export enum KlientkonfigurasjonActionKey {
    KLIENTKONFIGURASJON_IS_LOADING = 'KLIENTKONFIGURASJON_IS_LOADING',
    KLIENTKONFIGURASJON_HAS_ERROR = 'KLIENTKONFIGURASJON_HAS_ERROR',
    KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS = 'KLIENTKONFIGURASJON_FETCH_DATA_SUCCESS',
    KLIENTKONFIGURASJON_USIKRET_FETCH_DATA_SUCCESS = 'KLIENTKONFIGURASJON_USIKRET_FETCH_DATA_SUCCESS',
    OTHER_ACTION = '__any_other_action__'
}

export enum ProvekjennemerkerActionKey {
    PROVEKJENNEMERKER_GET_SUCCESS = 'PROVEKJENNEMERKER_GET_SUCCESS',
    PROVEKJENNEMERKER_GET_EMPTY = 'PROVEKJENNEMERKER_GET_EMPTY',
    PROVEKJENNEMERKER_GET_ERROR = 'PROVEKJENNEMERKER_GET_ERROR',
    PROVEKJENNEMERKER_IS_LOADING = 'PROVEKJENNEMERKER_IS_LOADING'
}

export enum ArsprovekjennemerkeActionKey {
    ARSPROVEKJENNEMERKE_GET_SUCCESS = 'ARSPROVEKJENNEMERKE_GET_SUCCESS',
    ARSPROVEKJENNEMERKE_GET_ERROR = 'ARSPROVEKJENNEMERKE_GET_ERROR',
    ARSPROVEKJENNEMERKE_IS_LOADING = 'ARSPROVEKJENNEMERKE_IS_LOADING'
}

export enum MidlertidigBrukstillatelseActionKey {
    MIDLERTIDIG_BRUKSTILLATELSE_GET_SUCCESS = 'MIDLERTIDIG_BRUKSTILLATELSE_GET_SUCCESS',
    MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING = 'MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING',
    MIDLERTIDIG_BRUKSTILLATELSE_GET_ERROR = 'MIDLERTIDIG_BRUKSTILLATELSE_GET_ERROR',

    MIDLERTIDIG_BRUKSTILLATELSE_FETCH_IS_LOADING = 'MIDLERTIDIG_BRUKSTILLATELSE_FETCH_IS_LOADING',
    MIDLERTIDIG_BRUKSTILLATELSE_FETCH_SUCCESS = 'MIDLERTIDIG_BRUKSTILLATELSE_FETCH_SUCCESS',
    MIDLERTIDIG_BRUKSTILLATELSE_FETCH_ERROR = 'MIDLERTIDIG_BRUKSTILLATELSE_FETCH_ERROR',

    MIDLERTIDIG_BRUKSTILLATELSE_POST_IS_LOADING = 'MIDLERTIDIG_BRUKSTILLATELSE_POST_IS_LOADING',
    MIDLERTIDIG_BRUKSTILLATELSE_POST_ERROR = 'MIDLERTIDIG_BRUKSTILLATELSE_POST_ERROR',
    MIDLERTIDIG_BRUKSTILLATELSE_POST_SUCCESS = 'MIDLERTIDIG_BRUKSTILLATELSE_POST_SUCCESS',

    MIDLERTIDIG_BRUKSTILLATELSE_SEND_IS_LOADING = 'MIDLERTIDIG_BRUKSTILLATELSE_SEND_IS_LOADING',
    MIDLERTIDIG_BRUKSTILLATELSE_SEND_ERROR = 'MIDLERTIDIG_BRUKSTILLATELSE_SEND_ERROR',
    MIDLERTIDIG_BRUKSTILLATELSE_SEND_SUCCESS = 'MIDLERTIDIG_BRUKSTILLATELSE_SEND_SUCCESS',

    MIDLERTIDIG_BRUKSTILLATELSE_PRINT_IS_LOADING = 'MIDLERTIDIG_BRUKSTILLATELSE_PRINT_IS_LOADING',
    MIDLERTIDIG_BRUKSTILLATELSE_PRINT_ERROR = 'MIDLERTIDIG_BRUKSTILLATELSE_PRINT_ERROR',
    MIDLERTIDIG_BRUKSTILLATELSE_PRINT_SUCCESS = 'MIDLERTIDIG_BRUKSTILLATELSE_PRINT_SUCCESS',

    KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_ERROR = 'KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_ERROR',
    KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING = 'KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING',
    KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS = 'KANSELLER_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS'
}

export enum MidlertidigBrukstillatelseRapportActionKey {
    MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_SUCCESS = 'MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_SUCCESS',
    MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_IS_LOADING = 'MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_IS_LOADING',
    MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_ERROR = 'MIDLERTIDIG_BRUKSTILLATELSE_RAPPORT_GET_ERROR',
}

export enum StoppMidlertidigBrukstillatelseActionKey {
    STOPP_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS = 'STOPP_MIDLERTIDIG_BRUKSTILLATELSE_SUCCESS',
    STOPP_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING = 'STOPP_MIDLERTIDIG_BRUKSTILLATELSE_IS_LOADING',
    STOPP_MIDLERTIDIG_BRUKSTILLATELSE_ERROR = 'STOPP_MIDLERTIDIG_BRUKSTILLATELSE_ERROR'
}

export enum KjoretoyActionKey {
    KJORETOY_GET_SUCCESS = 'KJORETOY_GET_SUCCESS',
    KJORETOY_IS_LOADING = 'KJORETOY_IS_LOADING',
    KJORETOY_GET_ERROR = 'KJORETOY_GET_ERROR',
    KJORETOY_IS_FREMMED = 'KJORETOY_IS_FREMMED',
    KJORETOY_RESET = 'KJORETOY_RESET'
}

export enum KjoretoylisteActionKey {
    KJORETOY_FIND_SUCCESS = 'KJORETOY_FIND_SUCCESS',
    KJORETOY_FIND_IS_LOADING = 'KJORETOY_FIND_IS_LOADING',
    KJORETOY_FIND_ERROR = 'KJORETOY_FIND_ERROR',
    KJORETOYLISTE_CLEAR = 'KJORETOYLISTE_CLEAR'
}

export enum MerknadActionKey {
    UPDATE_MERKNADER_ACTION = 'UPDATE_MERKNADER_ACTION'
}

export enum KjoretoygruppeActionKey {
    HENT_KJORETOYGRUPPE_SUCCESS = 'HENT_KJORETOYGRUPPE_SUCCESS',
    HENT_KJORETOYGRUPPE_ERROR = 'HENT_KJORETOYGRUPPE_ERROR',
    HENT_KJORETOYGRUPPE_LOADING = 'HENT_KJORETOYGRUPPE_LOADING'
}

export enum MeldingBarActionKey {
    MELDING_BAR_NY_MELDING = 'MELDING_BAR_NY_MELDING',
    MELDING_BAR_FJERN_MELDING = 'MELDING_BAR_FJERN_MELDING'
}

export enum MerkerActionKey {
    HENT_MERKER_SUCCESS = 'HENT_MERKER_SUCCESS',
    HENT_MERKER_ERROR = 'HENT_MERKER_ERROR'
}
