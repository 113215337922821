import { ArsprovekjennemerkeActionKey } from '../../../constants';
import { AkrConfig, PREFIX } from '../../../constants/akr-config';
import type { IError } from '../../../models/types';
import type { IArsprovekjennemerke } from '../../../models/types/arsprovekjennemerke';
import { createRestResource } from '../../store';

export type IArsprovekjennemerkeAction =
    IGetArsprovekjennemerkeSuccessAction | IGetArsprovekjennemerkeErrorAction | IGetArsprovekjennemerkeIsLoadingAction;

interface IGetArsprovekjennemerkeSuccessAction {
    type: ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_GET_SUCCESS;
    provekjennemerke: IArsprovekjennemerke;
}

interface IGetArsprovekjennemerkeIsLoadingAction {
    type: ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_IS_LOADING;
    isLoading: boolean;
}

interface IGetArsprovekjennemerkeErrorAction {
    type: ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_GET_ERROR;
    error: IError;
}

const getArsprovekjennemerkeSuccessAction = (provekjennemerke: IArsprovekjennemerke): IGetArsprovekjennemerkeSuccessAction => ({
    type: ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_GET_SUCCESS,
    provekjennemerke
});
const getArsprovekjennemerkeIsLoadingAction = (isLoading: boolean): IGetArsprovekjennemerkeIsLoadingAction =>
    ({type: ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_IS_LOADING, isLoading});
const getArsprovekjennemerkeErrorAction = (error: IError): IGetArsprovekjennemerkeErrorAction =>
    ({type: ArsprovekjennemerkeActionKey.ARSPROVEKJENNEMERKE_GET_ERROR, error});

const arsprovekjennemerkeApi = createRestResource(AkrConfig.ARSPROVEKJENNEMERKE_RESOURCE_URL, PREFIX);

export function getArsprovekjennemerke(prkid: number) {
    return dispatch => {
        dispatch(getArsprovekjennemerkeIsLoadingAction(true));
        return arsprovekjennemerkeApi.get(prkid).then(response => {
            if (!!response.errorId) {
                return dispatch(getArsprovekjennemerkeErrorAction(response.error));
            }

            dispatch(getArsprovekjennemerkeSuccessAction(response));
        });
    };
}