import { DEFAULT_MELDING_VISNINGSTID_MS, MeldingBarActionKey } from '../../../constants';
import { MeldingType } from '../../../models/kodeverk/melding-bar-enums';
import type { IMelding } from '../../../models/types/melding-bar';

export type MeldingBarActionType = IMeldingBarNyMeldingAction | IMeldingBarFjernMeldingAction;

export interface IMeldingBarNyMeldingAction {
    type: MeldingBarActionKey.MELDING_BAR_NY_MELDING;
    melding: IMelding;
}

export interface IMeldingBarFjernMeldingAction {
    type: MeldingBarActionKey.MELDING_BAR_FJERN_MELDING;
    meldingId: string;
}

let meldingIdSeq = 0;

export function nyMelding(melding: IMelding) {

    return dispatch => {
        melding.meldingId = melding.meldingId || `${meldingIdSeq++}`;
        melding.varighetMs = !melding.varighetMs && melding.varighetMs !== 0 ? DEFAULT_MELDING_VISNINGSTID_MS : melding.varighetMs;

        if (melding.varighetMs > 0 && MeldingType.ERROR !== melding.meldingType) {
            setTimeout(() => dispatch(fjernMelding(melding)), melding.varighetMs);
        }

        return dispatch({type: MeldingBarActionKey.MELDING_BAR_NY_MELDING, melding});
    };
}

export function fjernMelding(melding: IMelding) {
    const meldingId = melding.meldingId;

    return dispatch => dispatch({type: MeldingBarActionKey.MELDING_BAR_FJERN_MELDING, meldingId});
}


