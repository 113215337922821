import * as React from 'react';
import { connect } from 'react-redux';

class IkkeFunnetSide extends React.Component<unknown, unknown> {
    public render() {
        return (
            <section className="ak-innhold">
                <h1 className="col-12">Oi!</h1>
                <p className="col-12">Vi har problemer med å finne siden du leter etter.</p>
            </section>
        );
    }
}

const IkkeFunnetSideConnected = connect()(IkkeFunnetSide);
export { IkkeFunnetSide, IkkeFunnetSideConnected };