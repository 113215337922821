import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

function defaultSelector(state) {
    const { intl } = state;
    return {
        key: intl.locale,
        ...intl
    };
}

const mapStateToProps = (state, { intlSelector = defaultSelector }) =>
    intlSelector(state);

export default connect(mapStateToProps)(IntlProvider);

export interface IntlState {
    locale: string;
    messages: any;
    formats?: any;
}

export const UPDATE = '@@intl/UPDATE';

export const updateIntl = (intl: IntlState) => ({
    type: UPDATE,
    payload: intl
});

export const initialState = {
    locale: 'en',
    messages: {}
};

export function intlReducer(state: IntlState = initialState, action) {
    if (action.type !== UPDATE) {
        return state;
    }

    return { ...state, ...action.payload };
}