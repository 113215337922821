import * as _ from 'lodash';

import { BrukerprofilActionKey } from '../../constants';
import { Brukerroller } from '../../models/kodeverk/brukerroller';
import type { Brukerprofil, IError } from '../../models/types';
import type { BrukerprofilActionType } from '../actions';

const initialState: Brukerprofil = {
    isLoading: true,
    isUnauthorized: true,
    hasError: false
};

export function brukerprofil(state: Brukerprofil = initialState, action: BrukerprofilActionType) {
    if ("error" in action && action?.error?.httpStatusCode === '401') {
        return {...state, isLoading: false, error: {...action.error} as IError, isUnauthorized: true};
    }

    switch (action.type) {
        case BrukerprofilActionKey.BRUKERPROFIL_IS_LOADING:
            return {...state, isLoading: action.isLoading};
        case BrukerprofilActionKey.BRUKERPROFIL_HAS_ERROR:
            return {...state, isLoading: false, hasError: action.hasError};
        case BrukerprofilActionKey.BRUKERPROFIL_IS_UNAUTHORIZED:
            return {...state, isLoading: false, isUnauthorized: action.isUnauthorized};
        case BrukerprofilActionKey.BRUKERPROFIL_HAS_SERVER_ERROR:
            return {...state, isLoading: false, hasError: true, error: {...action.error}};
        case BrukerprofilActionKey.BRUKERPROFIL_FETCH_DATA_SUCCESS:
            return action?.brukerprofil?.bruker?.avdelinger?.length === 0 ?
                   {...state, isLoading: false, error: {errorCode: 'ingen.avdelinger'}} :
                   {...state, isLoading: false, ...action.brukerprofil, bruker: {...action.brukerprofil.bruker, harTilgangTilBransje: harTilgangTilBransjelosning(action.brukerprofil)}};
        default:
            return state;
    }
}

function harTilgangTilBransjelosning(innloggetBrukerprofil: Brukerprofil): boolean {
    return _.some(innloggetBrukerprofil.bruker.roller, (rolle: string) => rolle === Brukerroller.FREG.toString()
        || rolle === Brukerroller.EIERSKIFTE.toString()
        || rolle === Brukerroller.KJSCM.toString()
        || rolle === Brukerroller.OMREG.toString());
}