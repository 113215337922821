import * as React from 'react';
import { useEffect } from 'react';
import { AkUserSelect } from 'svv-tk-akr-common-frontend';
import { AkrConfig } from '../../constants/akr-config';

import type { RootStateType } from '../../models/types';
import { hovedmenySeksjonEkspandert } from '../../state/actions/globals/globals';

import './user-select-style';
import { useAppDispatch, useAppSelector } from '../../state/store';

export const UserSelect: React.FC = () => {
    const brukerprofil = useAppSelector((state: RootStateType) => state.brukerprofil);
    const isWorkplacesOpen = useAppSelector((state: RootStateType) => state.hovedmenyEkspandert);
    const logoutUrl = `${window.env.serviceUrl}/sikkerhet/brukerprofil/logout`;
    const dispatch = useAppDispatch();

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
        if (brukerprofil.isLoading === false && !brukerprofil?.valgtEnhet) {
            dispatch(hovedmenySeksjonEkspandert(true));
            return;
        }
        dispatch(hovedmenySeksjonEkspandert(false));
    }, [brukerprofil?.valgtEnhet, brukerprofil?.bruker]);

    const openWorkplaces = () => {
        if (brukerprofil?.bruker?.avdelinger) {
            dispatch(hovedmenySeksjonEkspandert(!isWorkplacesOpen));
        }
    };

    return (
        <AkUserSelect
            user={brukerprofil}
            workplaces={brukerprofil?.bruker?.avdelinger}
            isOpen={isWorkplacesOpen}
            selectWorkplaceBaseUrl={`${window.env.serviceUrl}${AkrConfig.BRUKERPROFIL_AVDELING_RESOURCE_URL}/`}
            openWorkplaces={openWorkplaces}
            logoutUrl={logoutUrl} />
    );
};