import * as _ from 'lodash';
import { MeldingBarActionKey } from '../../constants';
import type { IMeldingBar } from '../../models/types/melding-bar';
import type { MeldingBarActionType } from '../actions/globals/melding-bar';

const initialState: IMeldingBar = {
    meldinger: []
};

let meldingIdSeq = 0;

export function meldingBar(state: IMeldingBar = initialState, action: MeldingBarActionType): IMeldingBar {
    switch (action.type) {
        case MeldingBarActionKey.MELDING_BAR_NY_MELDING: {
            const nyMelding = action.melding;
            const stateInneholderMelding = _.some(state.meldinger, value => value.meldingIntlId === nyMelding.meldingIntlId);

            if (stateInneholderMelding) {
                return state;
            }

            return {...state, meldinger: [...state.meldinger, {...nyMelding, meldingId: nyMelding.meldingId || `${meldingIdSeq++}`}]};
        }
        case MeldingBarActionKey.MELDING_BAR_FJERN_MELDING:
            return {...state, meldinger: [..._.filter(state.meldinger, (melding) => melding.meldingId !== action.meldingId)]};
        default:
            return state;
    }
}
