import { AkNumberplate } from 'svv-tk-akr-common-frontend/dist/mobile/index';
import * as _ from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { KjoretoyInfo, LoadingWithErrorHandler } from '../';

import type { IFremmedKjoretoy, IKjoretoy, IMidlertidigBrukstillatelse, ISingleArsprovekjennemerke, RootStateType } from '../../models/types';
import { getArsprovekjennemerke, getKjoretoy } from '../../state/actions';
import { isFremmedKjoretoy } from '../../type-guards';

interface IApkjKjoretoyKnytningStateProps {
    kjoretoy: IKjoretoy | IFremmedKjoretoy;
    arsprovekjennemerke: ISingleArsprovekjennemerke;
    midlertidigbrukstillatelse: IMidlertidigBrukstillatelse;
}

interface IApkjKjoretoyKnytningDispatchProps {
    getArsprovekjennemerke: (prkid: number) => Promise<unknown>;
    getKjoretoy: (kuid: string, mdl: IMidlertidigBrukstillatelse) => Promise<unknown>;
}

type PropsType = IApkjKjoretoyKnytningStateProps & IApkjKjoretoyKnytningDispatchProps;

class ArsprovekjennemerkeKjoretoyKnytning extends React.Component<PropsType> {
    public componentDidMount() {
        this.props.getKjoretoy(this.props.midlertidigbrukstillatelse.kuid, this.props.midlertidigbrukstillatelse);
        this.props.getArsprovekjennemerke(this.props.midlertidigbrukstillatelse.prkid);
    }

    public render() {
        return (
            <>
                <LoadingWithErrorHandler {...this.props.arsprovekjennemerke} returnNullExpression={!this.props.arsprovekjennemerke.arsprovekjennemerke}
                    customLoadingClassNames="ak-spinner-small" renderFn={this.renderArsprovekjennemerke}/>
                <i className="fa fa-link" />
                <LoadingWithErrorHandler {...this.props.kjoretoy}
                    returnNullExpression={!isFremmedKjoretoy(this.props.kjoretoy) ? !this.props.kjoretoy.understellsnummer : !this.props.kjoretoy.fremmedKjoretoyUnderstellsnr}>
                    <KjoretoyInfo kjoretoy={this.props.kjoretoy} />
                </LoadingWithErrorHandler>
            </>
        );
    }

    private renderArsprovekjennemerke = () => {
        return <AkNumberplate tegnkombinasjon={this.props.arsprovekjennemerke.arsprovekjennemerke.kjennemerker[0].tegnkombinasjon.visning}
            arsprovekjennemerketype={this.props.arsprovekjennemerke.arsprovekjennemerke.arsprovekjennemerketype}
            kjennemerketype={this.props.arsprovekjennemerke.arsprovekjennemerke.provekjennemerketype}/>;
    };
}

const mapStateToProps = (state: RootStateType, ownProps): IApkjKjoretoyKnytningStateProps => ({
    midlertidigbrukstillatelse: _.isEmpty(ownProps) ? state.midlertidigbrukstillatelse : ownProps,
    kjoretoy: state.singleKjoretoy,
    arsprovekjennemerke: state.arsprovekjennemerke
});

const mapDispatchToProps = (dispatch): IApkjKjoretoyKnytningDispatchProps => ({
    getArsprovekjennemerke: (prkid: number) => dispatch(getArsprovekjennemerke(prkid)),
    getKjoretoy: (kuid: string, mdl: IMidlertidigBrukstillatelse) => dispatch(getKjoretoy(kuid, mdl))
});

const ArsprovekjennemerkeKjoretoyKnytningConnected = connect(mapStateToProps, mapDispatchToProps)(ArsprovekjennemerkeKjoretoyKnytning);
export {ArsprovekjennemerkeKjoretoyKnytning, ArsprovekjennemerkeKjoretoyKnytningConnected};