import * as React from 'react';

interface IAkrErrorBoundaryProps {
    logError: (error: string) => Promise<any>;
    location: Pick<Location, 'pathname'>;
}

interface IAkrErrorBoundaryState {
    hasError: boolean;
    errorId: string;
}

const INITIAL_STATE: IAkrErrorBoundaryState = {
    hasError: false,
    errorId: null
};

class ErrorHandler extends React.Component<IAkrErrorBoundaryProps & React.PropsWithChildren, IAkrErrorBoundaryState> {

    public static getDerivedStateFromError(error: Error) {
        return error ? { hasError: true } : null;
    }

    public state = INITIAL_STATE;

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        const errorMessage = `Errorinfo: ${error.stack}, ErrorComponentStack: ${errorInfo.componentStack}`;
        this.props.logError(errorMessage).then((response: any) => {
            this.setState({ errorId: response.errorId });
        });
    }

    public componentDidUpdate(prevProps: Readonly<IAkrErrorBoundaryProps>) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState(INITIAL_STATE);
        }
    }

    public render() {
        if (this.state.hasError) {
            return (
                <section className="ak-innhold">
                    <h1 className="col-12">Oi!</h1>
                    <p className="col-12">{`Vi har problemer med å finne siden du leter etter. Feilen er logget med id: ${this.state.errorId}`}</p>
                </section>
            );
        }

        return this.props.children;
    }
}

export { ErrorHandler };
