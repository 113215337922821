/* eslint max-lines: "warn" */
import * as _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { IMessageDescriptor } from 'svv-tk-akr-common-frontend';
import type { AkSelectedInputState, AkSelectInputOption, AkTextInputResult, AkTextInputState} from 'svv-tk-akr-common-frontend/dist/mobile/index';
import {
    AkButton, AkCard, AkLoading, AkMelding, AkMeldingboksType, AkNumberplate, AkSelectInput, AkSelectSearchInput, AkSjekkboks, AkTab, AkTabsContainer,
    AkTextInput, ButtonType
} from 'svv-tk-akr-common-frontend/dist/mobile/index';

import {
    MAX_KJENNEMERKE_LENGDE, MAX_UNDERSTELLSNUMMER_LENGDE, MIN_KJENNEMERKE_LENGDE, MIN_UNDERSTELLSNUMMER_LENGDE, VALID_KJENNEMERKE_REGEX, VALID_UNDERSTELLSNR_REGEX, VALID_YEAR_REGEX
} from '../../constants';
import { RegelmerknadKoder } from '../../models/kodeverk';
import type { IArsprovekjennemerke, IArsprovekjennemerker, IFremmedKjoretoy, IKjoretoy, IKjoretoygruppe, IKjoretoyliste, IMerknad, IMidlertidigBrukstillatelseMap } from '../../models/types';
import { clearKjoretoyliste, findKjoretoy, getArsprovekjennemerke, getKjoretoygrupper, hentAlleMerker } from '../../state/actions';
import { provekjennemerkeVelgKjoretoyMerknadSelector } from '../../state/selectors';
import { validKjoretoyGrupperAsSelectOptionsSelector } from '../../state/selectors/kjoretoygrupper-selector';
import { merkerAsSelectOptionsSelector } from '../../state/selectors/merker-selector';
import type { WithRouterProps } from '../../utils/router-util';
import { withRouter } from '../../utils/router-util';
import './kjoretoy-sok-style';

interface IKjoretoySokFremmedValidation {
    fremmedKjoretoyUnderstellsnr?: boolean;
    fremmedKjoretoyTekniskKode?: boolean;
    fremmedKjoretoyMerke?: boolean;
    fremmedKjoretoyRegistreringsar?: boolean;
}

interface IKjoretoySokRouteProps {
    params: {
        prkid: string;
    }
}

interface IKjoretoyEtterregistrering {
    etterregistrerContext?: boolean;
}

interface IKjoretoySokStateProps extends IKjoretoyEtterregistrering {
    arsprovekjennemerker: IArsprovekjennemerker;
    kjoretoyliste?: IKjoretoyliste;
    midlertidigebrukstillatelser: IMidlertidigBrukstillatelseMap;
    varselmeldinger: IMerknad[];
    kjoretoygrupper: IKjoretoygruppe[];
    provekjennemerke: IArsprovekjennemerke;
    merkerSelectOptions: AkSelectInputOption[];
    kjoretoygrupperSelectOptions: AkSelectInputOption[];
}

interface IKjoretoySokDispatchProps {
    getProvekjennemerke: (prkid: number) => Promise<any>;
    findKjoretoy: (kjennemerke: string, understellsnummer: string) => Promise<any>;
    clearKjoretoyliste: () => Promise<any>;
    hentAlleMerker: () => Promise<any>;
    hentKjoretoygrupper: () => Promise<any>;
}

export interface IKjoretoySokState {
    kjennemerkeSok: {
        selected?: boolean;
        tegnkombinasjon?: string;
        submitted?: boolean;
        validated?: boolean;
    };
    understellsnrSok: {
        selected?: boolean;
        understellsnummer?: string;
        submitted?: boolean;
        validated?: boolean;
    };
    fremmedSok: {
        fremmedKjoretoy: IFremmedKjoretoy;
        selected?: boolean;
        submitted?: boolean;
        ukjentRegistreringsar?: boolean;
        validatedFields: IKjoretoySokFremmedValidation;
    };
}

export type KjoretoySokPropsType = IKjoretoySokStateProps & IKjoretoySokDispatchProps & IKjoretoySokRouteProps & WithRouterProps;

class KjoretoySok extends React.Component<KjoretoySokPropsType, IKjoretoySokState> {

    public state: IKjoretoySokState = {
        kjennemerkeSok: {
            selected: false,
            tegnkombinasjon: '',
            submitted: false,
            validated: false
        },
        understellsnrSok: {
            selected: false,
            understellsnummer: '',
            submitted: false,
            validated: false
        },
        fremmedSok: {
            selected: false,
            submitted: false,
            fremmedKjoretoy: {
                fremmedKjoretoyUnderstellsnr: '',
                fremmedKjoretoyTekniskKodeBeskrivelse: '',
                fremmedKjoretoyTekniskKode: '',
                fremmedKjoretoyHandelsbetegnelse: '',
                fremmedKjoretoyMerke: '',
                fremmedKjoretoyRegistreringsar: ''
            },
            ukjentRegistreringsar: false,
            validatedFields: {}
        }
    };

    public componentDidMount() {
        if (!!this.props.kjoretoyliste?.kjoretoyliste || this.state.kjennemerkeSok.tegnkombinasjon !== this.props.kjoretoyliste?.kjoretoyliste[0].kjennemerke) {
            this.props.clearKjoretoyliste();
        }
        this.props.getProvekjennemerke(Number(this.props.params.prkid));
        this.props.hentKjoretoygrupper();
        this.props.hentAlleMerker();
    }

    public render() {
        const currentTab = this.props.location.pathname.split('/').pop();

        return (
            <>
                <header className="ak-heading justify-content-center">
                    {!this.props.etterregistrerContext ?
                     <FormattedMessage id="kjoreseddel.sok.overskrift" tagName="h1" /> : <FormattedMessage id="kjoreseddel.sok.etterregistrer.overskrift" tagName="h1" />}
                </header>
                <section className="ak-innhold">
                    <div className="w-100 text-center text-md-left col-md-auto">
                        <FormattedMessage id="kjoreseddel.sok.underoverskrift" tagName="h3" />
                    </div>
                    <AkTabsContainer customClassName="col" customHeaderClassName="row no-gutters flex-nowrap justify-content-center justify-content-md-start">
                        <AkTab name="Kjennemerke" faneLabelId="kjoreseddel.sok.kjennemerke" key="kjoreseddel.sok.kjennemerker" redirectFunction={this.redirectTabKjennemerke}
                            render={this.renderKjennemerkeSok} selected={currentTab === 'kjennemerke' || currentTab === 'kjoretoysok' || currentTab === ''} customButtonClassName="col-auto" />
                        <AkTab name="Understellsnr" faneLabelId="kjoreseddel.sok.understellsnr" key="kjoreseddel.sok.understellsnr" redirectFunction={this.redirectTabUnderstellsnummer}
                            render={this.renderUnderstellsnrSok} selected={currentTab === 'understellsnummer'} customButtonClassName="col-auto" />
                        <AkTab name="Fremmed" faneLabelId="kjoreseddel.sok.fremmed" key="kjoreseddel.sok.fremmed" redirectFunction={this.redirectTabFremmed}
                            render={this.renderFremmedSok} selected={currentTab === 'fremmed'} customButtonClassName="col-auto" />
                    </AkTabsContainer>
                </section>
                <div className="ak-content-footer">
                    {currentTab === 'fremmed' &&
                        <AkButton action={this.velgFremmedKjoretoy} buttonIntlKey="kjoreseddel.sok.ga-videre" buttonType={ButtonType.MAIN} />
                    }
                    <AkButton action={this.redirectBackToOversikt} buttonIntlKey="kjoreseddel.sok.avbryt" buttonType={ButtonType.LINK} />
                </div>
            </>
        );
    }

    private hentRelevanteVarselmerknaderForKjoretoy(kjoretoy: IKjoretoy): IMessageDescriptor[] {
        const merknaderForKjoretoy = this.props.varselmeldinger.filter((merknad => merknad.id === kjoretoy.kuid));
        const relevanteVarselmerknader = this.filtrerBortVarselmerknaderSomIkkeErRelevante(merknaderForKjoretoy);
        return relevanteVarselmerknader.map(merknad => {
            return {
                id: `kjoreseddel.sok.${merknad.kode}`
            };
        });
    }

    private renderKjennemerkeSok = () => {
        // TODO LH: Lage et gjenbrukbart komponent av dette som kan brukes som kjennemerke og understellsnummersøkeresultat
        return (<>
            <div className="row no-gutters align-items-start my-2">
                <form className="col col-md-auto" onSubmit={this.findKjoretoyKjennemerke}>
                    <AkTextInput initialValue={this.state.kjennemerkeSok.tegnkombinasjon}
                        onChange={this.onChangeKjennemerkeInput}
                        validRegExp={VALID_KJENNEMERKE_REGEX}
                        maxLength={MAX_KJENNEMERKE_LENGDE}
                        minLength={MIN_KJENNEMERKE_LENGDE}
                        stripWhitespace={true}
                        submitted={!this.state.kjennemerkeSok.validated && this.state.kjennemerkeSok.selected}
                        customInputClassName="col" />
                </form>
                <AkButton customClassNames="col-auto" buttonType={ButtonType.MAIN} buttonIntlKey="kjoreseddel.sok.sok" action={this.findKjoretoyKjennemerke} />
            </div>
            <section className="row pt-3">
                {!this.props.kjoretoyliste?.isLoading ?
                 !!this.props.kjoretoyliste?.kjoretoyliste.length ?
                 this.props.kjoretoyliste?.kjoretoyliste.map(((kjoretoy, weakKey) => {

                     const currentVarselMerknadMessages = this.hentRelevanteVarselmerknaderForKjoretoy(kjoretoy);

                     if (kjoretoy.ikkeTilgjengeligIKanal) {
                         return this.renderIkkeTilgjengeligIKanal(weakKey);
                     }

                     return (
                         <AkCard key={kjoretoy.kuid} customClassNames="mb-3 kjoretoy-sok-card col-12 col-md-auto text-center">
                             <AkNumberplate kjennemerketype={kjoretoy.kjennemerkeType} tegnkombinasjon={kjoretoy.kjennemerke} />
                             <div>{kjoretoy.understellsnummer}</div>
                             <div>{kjoretoy.kjoretoyklassifiseringBeskrivelse}</div>
                             <div className="py-3 ak-fet">
                                 {kjoretoy.merke} {kjoretoy.handelsbetegnelse} ({kjoretoy.registreringsar}) {kjoretoy.karosserifarge}
                             </div>
                             {currentVarselMerknadMessages.length !== 0 ? <AkMelding key={kjoretoy.kuid} meldingType={AkMeldingboksType.ERROR} messageKey={currentVarselMerknadMessages} />
                                                                        : <AkButton buttonType={ButtonType.MAIN} buttonIntlKey="kjoreseddel.sok.velg" action={this.velgKjoretoy}
                                  value={kjoretoy.kuid} />}
                         </AkCard>
                     );
                 })) :
                 this.state.kjennemerkeSok.submitted &&
                     (
                         !!this.props.kjoretoyliste.error?.errorId ?
                         <div className="col-12 col-md-4 mb-3">
                             <FormattedMessage id="feilhandtering.generell.feil" values={{ errorId: this.props.kjoretoyliste.error.errorId }} />
                         </div> :
                         <div className="col-12 col-md-4 mb-3">
                             <FormattedMessage id="kjoreseddel.sok.merknad.ingen-treff" />
                         </div>
                     ) :
                 <AkLoading />
                }
            </section>
        </>)
            ;
    };

    private renderUnderstellsnrSok = (): React.ReactNode => {
        return <>
            <div className="row no-gutters align-items-start my-2" key="div">
                <form className="col col-md-auto" onSubmit={this.findKjoretoyUnderstellsnummer}>
                    <AkTextInput initialValue={this.state.understellsnrSok.understellsnummer}
                        validRegExp={VALID_UNDERSTELLSNR_REGEX}
                        maxLength={MAX_UNDERSTELLSNUMMER_LENGDE}
                        minLength={MIN_UNDERSTELLSNUMMER_LENGDE}
                        submitted={!this.state.understellsnrSok.validated && this.state.understellsnrSok.selected}
                        onChange={this.onChangeUnderstellssnummerInput}
                        customInputClassName="col" />
                </form>
                <AkButton customClassNames="col-auto" buttonType={ButtonType.MAIN} buttonIntlKey="kjoreseddel.sok.sok" action={this.findKjoretoyUnderstellsnummer} />
            </div>
            <section className="row pt-3">
                {!this.props.kjoretoyliste?.isLoading ?
                 !!this.props.kjoretoyliste?.kjoretoyliste.length ? this.props.kjoretoyliste?.kjoretoyliste.map(((kjoretoy, weakKey) => {

                     const currentVarselMerknadMessages = this.hentRelevanteVarselmerknaderForKjoretoy(kjoretoy);

                     if (kjoretoy.ikkeTilgjengeligIKanal) {
                         return this.renderIkkeTilgjengeligIKanal(weakKey);
                     }

                     return (
                         <AkCard key={kjoretoy.kuid} customClassNames="mb-3 kjoretoy-sok-card col-12 col-md-auto text-center">
                             {kjoretoy.registreringsstatusKode ?
                              <>
                                  <AkNumberplate kjennemerketype={kjoretoy.kjennemerkeType} tegnkombinasjon={kjoretoy.kjennemerke} />
                                  <div>{kjoretoy.understellsnummer}</div>
                              </>
                                                               :
                              <AkNumberplate tegnkombinasjon={kjoretoy.understellsnummer} />
                             }
                             <div>{kjoretoy.kjoretoyklassifiseringBeskrivelse}</div>
                             <div className="py-3 ak-fet">
                                 {kjoretoy.merke} {kjoretoy.handelsbetegnelse} {!!kjoretoy.registreringsar && `(${kjoretoy.registreringsar})`}<br />
                                 {kjoretoy.karosserifarge}
                             </div>
                             {!!currentVarselMerknadMessages.length && !this.props.etterregistrerContext ?
                              <AkMelding key={kjoretoy.kuid} meldingType={AkMeldingboksType.ERROR} messageKey={currentVarselMerknadMessages} /> :
                              <AkButton buttonType={ButtonType.MAIN} buttonIntlKey="kjoreseddel.sok.velg" action={this.velgKjoretoy}
                                  value={kjoretoy.kuid} />
                             }
                         </AkCard>
                     );
                 })) : this.state.understellsnrSok.submitted &&
                     (
                         !!this.props.kjoretoyliste.error?.errorId ?
                         <div className="col-12 col-md-4 mb-3">
                             <FormattedMessage id="feilhandtering.generell.feil" values={{ errorId: this.props.kjoretoyliste.error.errorId }} />
                         </div> :
                         <div className="col-12 col-md-4 mb-3">
                             <FormattedMessage id="kjoreseddel.sok.merknad.ingen-treff-understellsnummer" />
                         </div>
                     )
                                                      : <AkLoading />}
            </section>
        </>;
    };

    private renderFremmedSok = () : React.ReactNode => {
        const colClass = 'col-12';
        const { fremmedKjoretoy, ukjentRegistreringsar, submitted } = this.state.fremmedSok;
        return (
            <>
                <AkCard customContentClassNames="row no-gutters flex-column ak-kjoretoy-sok-fremmed">
                    {(!this.isFremmedFieldsValid() && submitted) &&
                        <div className="col">
                            <AkMelding meldingType={AkMeldingboksType.ERROR} messageKey="common.meldinger.skjemafeil" />
                        </div>
                    }
                    <div className="col-auto">
                        <AkMelding meldingType={AkMeldingboksType.INFO} messageKey="kjoreseddel.sok.info.fremmed" customClassName="d-inline-block" />
                    </div>
                    <div className="col justify-content-center justify-content-md-start">
                        <form className="row" onSubmit={this.findKjoretoyKjennemerke}>

                            <b className={colClass}><FormattedMessage id="kjoreseddel.sok.understellsnummer" />:</b>
                            <AkTextInput initialValue={fremmedKjoretoy.fremmedKjoretoyUnderstellsnr}
                                onBlur={this.handleChangeFremmedInput}
                                propertyKey="fremmedKjoretoyUnderstellsnr"
                                customInputClassName="col-md-auto"
                                customClassName={colClass}
                                submitted={submitted}
                                required={true} />

                            <b className={colClass}><FormattedMessage id="kjoreseddel.sok.kjoretoyklassifisering" />:</b>
                            <AkSelectInput options={this.props.kjoretoygrupperSelectOptions}
                                componentClassNames="col-md-auto"
                                handleSelectChange={this.handleChangeFremmedKjoretoyklassifiseringInput}
                                propertyKey="fremmedKjoretoyTekniskKode"
                                placeholderIntlKey="common.velg"
                                submitted={submitted}
                                required={true} />

                            <div className="ak-merke-handelsbetegnelse-wrapper col-12">
                                <div className="row">
                                    <section className="col-12 col-md-auto">
                                        <b><FormattedMessage id="kjoreseddel.sok.merke" />:</b>
                                        <AkSelectSearchInput options={this.props.merkerSelectOptions}
                                            handleSelectChange={this.handleChangeFremmedInput}
                                            propertyKey="fremmedKjoretoyMerke"
                                            placeholderIntlKey="common.velg"
                                            submitted={submitted}
                                            required={true} />
                                    </section>

                                    <section className="col-12 col-md-auto">
                                        <b>
                                            <FormattedMessage id="kjoreseddel.sok.handelsbetegnelse" />
                                            <FormattedMessage id="common.ikke-obligatorisk" tagName="span" />:
                                        </b>
                                        <AkTextInput onBlur={this.handleChangeFremmedInput}
                                            propertyKey="fremmedKjoretoyHandelsbetegnelse"
                                            customInputClassName="col-md-auto"
                                            maxLength={30} />
                                    </section>
                                </div>
                            </div>

                            <b className={colClass}><FormattedMessage id="kjoreseddel.sok.registreringsar" />:</b>
                            <div className={colClass}>
                                <div className="row align-items-baseline">
                                    <AkTextInput onBlur={this.handleChangeFremmedInput}
                                        initialValue={fremmedKjoretoy.fremmedKjoretoyRegistreringsar}
                                        propertyKey="fremmedKjoretoyRegistreringsar"
                                        customInputClassName="col-12 col-md-auto"
                                        customClassName="col-12 col-md-auto"
                                        disabled={ukjentRegistreringsar}
                                        required={!ukjentRegistreringsar}
                                        submitted={submitted}
                                        validRegExp={VALID_YEAR_REGEX} />
                                    <AkSjekkboks label={{ id: 'kjoreseddel.sok.ukjentRegistreringsar' }}
                                        checked={ukjentRegistreringsar}
                                        handleChange={this.toggleUkjentRegistreringsar}
                                        componentClassNames="col-auto" />
                                </div>
                            </div>

                        </form>
                    </div>
                </AkCard>
            </>
        );
    };

    private renderIkkeTilgjengeligIKanal = (id: number) => {
        return (<div key={id} className="col-12 col-md-4 mb-3">
            <FormattedMessage id="kjoreseddel.sok.merknad.ikke-tilgjengelig" />
        </div>);
    };

    private findKjoretoyKjennemerke = (event?: React.SyntheticEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.state.kjennemerkeSok.validated) {
            return;
        }

        this.setState({ kjennemerkeSok: { ...this.state.kjennemerkeSok, submitted: true } });
        this.props.findKjoretoy(this.state.kjennemerkeSok.tegnkombinasjon, null);
    };

    private findKjoretoyUnderstellsnummer = (event?: React.SyntheticEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }

        if (!this.state.understellsnrSok.validated) {
            return;
        }

        this.setState({ understellsnrSok: { ...this.state.understellsnrSok, submitted: true } });
        this.props.findKjoretoy(null, this.state.understellsnrSok.understellsnummer);
    };

    private redirectTabKjennemerke = () => {
        this.setState(prevState => ({ kjennemerkeSok: { ...prevState.kjennemerkeSok, submitted: false } }));
        if (this.props.etterregistrerContext) {
            this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/etterregistrer/kjoretoysok/kjennemerke`);
        } else {
            this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/kjoretoysok/kjennemerke`);
        }
    };

    private redirectTabUnderstellsnummer = () => {
        if (this.props.etterregistrerContext) {
            this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/etterregistrer/kjoretoysok/understellsnummer`);
        } else {
            this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/kjoretoysok/understellsnummer`);
        }
    };

    private redirectTabFremmed = () => {
        this.resetFremmedSokState();
        if (this.props.etterregistrerContext) {
            this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/etterregistrer/kjoretoysok/fremmed`);
        } else {
            this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/kjoretoysok/fremmed`);
        }
    };

    private onChangeKjennemerkeInput = (result: AkTextInputResult) => {
        this.setState({
            kjennemerkeSok: {
                selected: true,
                tegnkombinasjon: result.value,
                validated: !result.errors?.length
            }
        });
    };

    private onChangeUnderstellssnummerInput = (result: AkTextInputResult) => {
        this.setState({
            understellsnrSok: {
                selected: true,
                understellsnummer: result.value.replace(/\s/g, ''),
                validated: !result.errors?.length
            }
        });
    };

    private handleChangeFremmedInput = (input: AkSelectedInputState | AkTextInputState) => {
        this.setState(prevState => (
            {
                fremmedSok: {
                    ...prevState.fremmedSok,
                    submitted: prevState.fremmedSok.fremmedKjoretoy[input.propertyKey] === input.value,
                    fremmedKjoretoy: {
                        ...prevState.fremmedSok.fremmedKjoretoy,
                        [input.propertyKey]: input.value
                    },
                    validatedFields: {
                        ...prevState.fremmedSok.validatedFields,
                        [input.propertyKey]: this.validateField(input)
                    }
                }
            }
        ));
    };

    private handleChangeFremmedKjoretoyklassifiseringInput = (input) => {

        this.setState(prevState => (
            {
                fremmedSok: {
                    ...prevState.fremmedSok,
                    submitted: false,
                    fremmedKjoretoy: {
                        ...prevState.fremmedSok.fremmedKjoretoy,
                        fremmedKjoretoyTekniskKode: input.value,
                        fremmedKjoretoyTekniskKodeBeskrivelse: _.find(input.options, option => option.value === input.value)?.description
                    },
                    validatedFields: {
                        ...prevState.fremmedSok.validatedFields,
                        fremmedKjoretoyTekniskKode: this.validateField(input)
                    }
                }
            }
        ));
    };

    private validateField = (input) => {
        if (input.propertyKey === 'fremmedKjoretoyRegistreringsar') {
            return !!input.value && input.value.match(VALID_YEAR_REGEX);
        }
        return !!input.value;
    };

    private redirectBackToOversikt = () => {
        if (this.props.etterregistrerContext) {
            this.props.navigate('/etterregistrer');
        } else {
            this.props.navigate('/arsprovekjennemerker');
        }
    };

    private velgFremmedKjoretoy = () => {
        this.setState(prevState => (
            {
                fremmedSok: {
                    ...prevState.fremmedSok,
                    submitted: true
                }
            }
        ));

        if (this.isFremmedFieldsValid()) {
            if (this.props.etterregistrerContext) {
                return this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/etterregistrer-kjoreseddel`
                    , { state: { fremmedKjoretoy: { ...this.state.fremmedSok.fremmedKjoretoy } } });
            } else {
                return this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/kjoreseddel`
                    , { state: { fremmedKjoretoy: { ...this.state.fremmedSok.fremmedKjoretoy } } });
            }
        }
    };

    private velgKjoretoy = (event: React.SyntheticEvent<HTMLButtonElement>) => {
        if (this.props.etterregistrerContext) {
            return this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/etterregistrer-kjoreseddel/${event.currentTarget.value}`);
        } else {
            return this.props.navigate(`/arsprovekjennemerker/${this.props.params.prkid}/kjoreseddel/${event.currentTarget.value}`);
        }
    };

    // eslint-disable-next-line
    private toggleUkjentRegistreringsar = (value, isChecked) => {
        const fremmedSok = { ...this.state.fremmedSok };
        fremmedSok.ukjentRegistreringsar = !isChecked;
        fremmedSok.submitted = false;
        if (!isChecked) {
            fremmedSok.validatedFields.fremmedKjoretoyRegistreringsar = true;
            fremmedSok.fremmedKjoretoy.fremmedKjoretoyRegistreringsar = '';
        } else {
            fremmedSok.validatedFields.fremmedKjoretoyRegistreringsar = !!fremmedSok.fremmedKjoretoy.fremmedKjoretoyRegistreringsar;
        }
        this.setState({
            fremmedSok
        });
    };

    private resetFremmedSokState = () => {
        this.setState({
            fremmedSok: {
                selected: false,
                submitted: false,
                fremmedKjoretoy: {
                    fremmedKjoretoyUnderstellsnr: this.state.understellsnrSok.submitted ? this.state.understellsnrSok.understellsnummer : '',
                    fremmedKjoretoyTekniskKodeBeskrivelse: '',
                    fremmedKjoretoyTekniskKode: '',
                    fremmedKjoretoyHandelsbetegnelse: '',
                    fremmedKjoretoyMerke: '',
                    fremmedKjoretoyRegistreringsar: ''
                },
                ukjentRegistreringsar: false,
                validatedFields: this.state.understellsnrSok.submitted ?
                    { 'fremmedKjoretoyUnderstellsnr': this.validateField({ value: this.state.understellsnrSok.understellsnummer, propertyKey: 'fremmedKjoretoyUnderstellsnr' }) } : {}
            }
        });
    };

    private isFremmedFieldsValid() {
        const { fremmedKjoretoyRegistreringsar, fremmedKjoretoyTekniskKode, fremmedKjoretoyMerke, fremmedKjoretoyUnderstellsnr } = this.state.fremmedSok.validatedFields;
        return fremmedKjoretoyRegistreringsar && fremmedKjoretoyTekniskKode && fremmedKjoretoyMerke && fremmedKjoretoyUnderstellsnr;
    }

    private filtrerBortVarselmerknaderSomIkkeErRelevante(merknader: IMerknad[]): IMerknad[] {
        if (!this.props.etterregistrerContext) {
            return merknader;
        }

        // Ved etterregistrering er det kun denne merknaden som skal hindre bruker fra å velge kjøretøyet
        const merknadOmFeilType = _.find(merknader, merknad => merknad.kode === RegelmerknadKoder.IKKE_GYLDIG_TEKNISK_KODE_FOR_PROVEKJENNEMERKE);
        return !!merknadOmFeilType ? [merknadOmFeilType] : [];
    }
}

const mapStateToProps = (state, ownProps): IKjoretoySokStateProps => ({
    arsprovekjennemerker: state.arsprovekjennemerker,
    kjoretoyliste: state.kjoretoyliste,
    midlertidigebrukstillatelser: state.midlertidigebrukstillatelser,
    etterregistrerContext: ownProps.etterregistrerContext,
    kjoretoygrupper: state.kjoretoygrupper?.kjoretoygrupper,
    provekjennemerke: state.arsprovekjennemerke?.arsprovekjennemerke,
    merkerSelectOptions: merkerAsSelectOptionsSelector(state),
    kjoretoygrupperSelectOptions: validKjoretoyGrupperAsSelectOptionsSelector(state),
    varselmeldinger: provekjennemerkeVelgKjoretoyMerknadSelector(state)
});

const mapDispatchToProps = (dispatch): IKjoretoySokDispatchProps => ({
    getProvekjennemerke: (prkid: number) => dispatch(getArsprovekjennemerke(prkid)),
    findKjoretoy: (kjennemerke: string, understellsnummer: string) => dispatch(findKjoretoy(kjennemerke, understellsnummer)),
    clearKjoretoyliste: () => dispatch(clearKjoretoyliste()),
    hentAlleMerker: () => dispatch(hentAlleMerker()),
    hentKjoretoygrupper: () => dispatch(getKjoretoygrupper())
});

const KjoretoySokConnected = withRouter<IKjoretoyEtterregistrering & WithRouterProps>(
    connect(mapStateToProps, mapDispatchToProps)(KjoretoySok));

export { KjoretoySok, KjoretoySokConnected };